import { createSlice } from '@reduxjs/toolkit';

const getUserInformationFromLocalStorage = () => {
  const userInformation = localStorage.getItem('user_information');
  if (userInformation) {
    try {
      return JSON.parse(userInformation);
    } catch (error) {
      console.error('Error parsing user information from localStorage:', error);
    }
  }
  return {};
};

export const userReducer = createSlice({
  name: 'user',
  initialState: {
    isLoggedIns: false,
    isLoggedOut: false,
    user_information: getUserInformationFromLocalStorage(),
  },
  reducers: {
    loggedIn: (state, action) => {
      state.isLoggedIns = Boolean(localStorage.getItem('loggedIn'));
      state.isLoggedOut = false;
      state.user_information = localStorage.getItem('user_information');
    
    //  localStorage.setItem('user_information', JSON.stringify(action.payload));
      //localStorage.setItem('user_information', JSON.stringify(action.payload));
    },
    loginCaptcha: (state: any, action) => {  
      state.captchaImage = (action.payload && action.payload.image) || "";
      state.captchaValue = (action.payload && action.payload.value) || "";
      state.captchaError = (action.payload && action.payload.error) || ""; 

    },
    loggedOut: (state) => {
      state.isLoggedIns = false;
      state.isLoggedOut = true;
      state.user_information = {};
      localStorage.removeItem('user_information');
    },
  },
});

export const checkUser = (state: any) => state.user.isLoggedIn;
export const checkLoggedOut = (state: any) => state.user.isLoggedOut;
export const getUserInformation = (state: any) => state.user.user_information;

export const { loggedIn, loggedOut,loginCaptcha } = userReducer.actions;

export default userReducer.reducer;
