import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function Calendra() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(null);

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Date of Accident"
            />
            {/* <label data-label="Date/Time" class="form-label"></label> */}
        </div>
    );
}

function ClaimsClosingDate({ onDateChange, selectedDate, disabled, type, evalDate, placeholder }) {
    // const [value, onChange] = useState(new Date());
    let min_date =null
    if (type===2) {
        min_date = new Date(evalDate);
        min_date.setDate(min_date.getDate() + 1);
    }
    const [startDate, setStartDate] = useState(selectedDate || null);
    const [minTime, setMinTime] = useState(null)
    const [maxTime, setMaxTime] = useState(null)

    const handleChange = (date) => {
        setStartDate(date);
        onDateChange(date);
    }

    useEffect(() => {
        setStartDate(selectedDate);
    }, [selectedDate]);

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={handleChange}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText=""
                disabled={disabled}
                dateFormat="dd MMM, yyyy h:mm aa"
                minDate={min_date} // when type is tranfer proof, then min date is evalution date
                minTime={minTime}
                maxTime={maxTime}
                showTimeSelect={true}
                
            />
            <label title={''} data-label={placeholder} className={`form-label datepick-heading`}></label>
            {/* <label data-label="Date/Time" class="form-label"></label> */}
        </div>
    );
}


function DateRange({ onDateChange, disabled, reset }) {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const handleDateChange = (update) => {
        setDateRange(update);
        if (onDateChange) {
            onDateChange(update, "dateRange");
        }
    };

    useEffect(() => {
        if (reset) {
            setDateRange([null, null])
        }
    }, [reset])

    return (
        <div className="datepicker">
            <DatePicker
                placeholderText="Date Range"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat={"dd-MM-yyyy"}
                // onChange={(update) => {
                //   setDateRange(update);
                // }}
                onChange={handleDateChange}
                disabled={disabled}

            />
        </div>
    );
};



function DateTimePicker() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/dd/yyyy h:mm aa"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeSelect={true}
                placeholderText="Date/Time"


            />
            {/* <label data-label="Date/Time" class="form-label"></label> */}
        </div>
    );
}
function DateApproval() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/dd/yyyy h:mm aa"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeSelect={true}
                placeholderText="Date of Approval"


            />
            {/* <label data-label="Date/Time" class="form-label"></label> */}
        </div>
    );
}







function CalendraSimple() {
    // const [startDate, setStartDate] = useState(new Date());

    // return (
    //     <div className="datepicker">
    //         <DatePicker
    //             selected={startDate}
    //             onChange={(date) => setStartDate(date)}
    //             peekNextMonth
    //             showMonthDropdown
    //             showYearDropdown
    //             dropdownMode="select"
    //         />
    //     </div>
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showYearPicker
                dateFormat="yyyy"
            />
        </div>
    );


}

function Calendra2(props) {
    // Initialize variables for start date, today's date, maximum date, and minimum value.
    const startDate = null;
    const today = new Date();
    let maxDate = null;
    let minValue = null;

    return (
        <div className="datepicker">
            <DatePicker
                onChange={(date) => props.onChange(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                //  placeholderText={props.placeholder ? props.placeholder :'--/ --/ --'}
                selected={props.selected ? new Date(props.selected) : startDate}
                disabled={props.disabled || false}
                minDate={minValue}
                maxDate={maxDate}
                dateFormat={props.dateFormat ? props.dateFormat : 'mm/dd/yyyy'}
            />
            <label data-label={props.placeholder ? props.placeholder : "Date/Time"} className="form-label"></label>
        </div>
    );
}


function ExpiryEffectivityDate(props) {
    const [startDate, setStartDate] = useState(props.insurancePolicyDate ? props.insurancePolicyDate : null);
    const [minDate, setMinDate] = useState(props.minDate ?? undefined)
    const [maxDate, setMaxDate] = useState(props.maxDate ?? undefined)
    const [minTime, setMinTime] = useState(undefined)
    const [maxTime, setMaxTime] = useState(undefined)

    const currentDate = moment();

    useEffect(() => {
        handleTime()
        setMinDate(props.minDate)
        setMaxDate(props.maxDate)
        setStartDate(props.insurancePolicyDate ? props.insurancePolicyDate : null) //(props.minDate ? props.minDate: null))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleTime = () => {
        let minTime = undefined;
        let maxTime = undefined;

        let isCurrDate = props.minDate ? (moment(props.minDate).isSame(currentDate, 'day') ? true : false) : false

        if ((isCurrDate && (!props.insurancePolicyDate || moment(props.insurancePolicyDate).isSame(currentDate, 'day')))) {
            minTime = moment().add(1, 'minute').toDate();
            maxTime = moment().endOf('day').toDate();
        }

        setMinTime(minTime)
        setMaxTime(maxTime)
    }


    const handleDateChange = (date) => {
        if (!(props.minDate && moment(props.minDate).isSame(new Date(0), 'date'))) {
            let isBefore = moment(date).isBefore(currentDate)
            let finalisedDate = isBefore && props.flag !== "effectivity_date" ? moment(date).startOf('hour').toDate() : date
            setStartDate(finalisedDate)
            props.onChange(finalisedDate);
        }
    }

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => {
                    handleDateChange(date)
                }}
                dateFormat="dd MMM, yyyy h:mm aa"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeSelect={true}
                placeholderText=""
                minTime={minTime}
                maxTime={maxTime}
                maxDate={maxDate}
                minDate={minDate}
                disabled={props.disabled || false}
            />
            <label title={''} data-label={props.text} className={`form-label datepick-heading`}></label>
        </div>
    );
}


function EvaluationDate(props) {
    let min_date = ''
    if (props.minDate) {
        min_date = new Date(props.minDate);
        min_date.setDate(min_date.getDate() + 1);
    }
    const [startDate, setStartDate] = useState((props.startDate) ? new Date(props.startDate) : null);
    const [minDate, setMinDate] = useState(new Date(min_date) ?? null)
    const [maxDate, setMaxDate] = useState((props.maxDate) ? new Date(props.maxDate) : null)
    const [minTime, setMinTime] = useState(null)
    const [maxTime, setMaxTime] = useState(null)

    const handleDateChange = (date) => {
        setStartDate(date)
        props.onChange(date);
    }


    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => {
                    handleDateChange(date)
                }}
                dateFormat={props.dateFormat ? props.dateFormat : 'dd MMM, yyyy h:mm aa'}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeSelect={(props?.showTimeSelect === 1) ? false : true}
                placeholderText=""
                maxDate={maxDate}
                minDate={minDate}
                minTime={minTime}
                maxTime={maxTime}
                disabled={props.disabled || false}
            />
            <label title={''} data-label={props.placeholder} className={`form-label datepick-heading`}></label>
        </div>
    );
}


export { Calendra, DateRange, CalendraSimple, DateTimePicker, DateApproval, ClaimsClosingDate, ExpiryEffectivityDate, Calendra2, EvaluationDate }