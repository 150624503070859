
// import React, { Component } from 'react';
// class ToggleSwitch extends Component {
//     constructor() {
//         super();

//         this.state = {
//         };

//         this.onToggleSwitchChange = this.onToggleSwitchChange.bind(this);
//     }

//     onToggleSwitchChange() {
//         let { status, is_expired } = this.props
//         if(!is_expired){
//             this.props.onChange("status", !status,false)
//         }
//     }

//     render() {
//         let { status,is_expired } = this.props
//         return (
//             <div className='ToggleSwitch ToggleSwitch__rounded'>
//                 <div className='ToggleSwitch__wrapper'>
//                     {
//                       (!is_expired)?
//                       <div className={`Slider ${status && 'isChecked'}`} onClick={this.onToggleSwitchChange}></div>: <div className={`Slider ${status && 'isChecked'}`}></div>
//                     }
                    
//                 </div>
//             </div>
//         );
//     }
// }

// export default ToggleSwitch


export default function ToggleSwitch(props: any): ReturnType<React.FC> {

    let { checked, disabled } = props

    const onToggleSwitchChange = () => {
        if (props.onChange && !disabled) {
            props.onChange()
        }
    }

    return (
        <div className='ToggleSwitch ToggleSwitch__rounded'>
            <div className='ToggleSwitch__wrapper'>
                <div className={`Slider ${checked && 'isChecked'} ${disabled && 'disabled'}`} onClick={onToggleSwitchChange}></div>
            </div>
        </div>
    )
}
