import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchClaimProcessingData, updateClaimDetailsInStore } from "@src/store/slices/claim";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function useClaimProcessingData(props: { type: string, api_type: string, call_from: string }): Function {
    const location = useLocation();
    const claimId = location?.pathname?.split('/')?.[2] || null
    const claimState: any = useSelector((state: any) => state.claim)

    const dispatch: any = useDispatch()

    const getClaimProcessingData = async () => {
        try {
            const result: any = await fetchClaimProcessingData({ type: props.type, variables: { claim_id: claimId, api_type: props.api_type, type: props?.call_from === 'docUpload' ? 1 : 2 /*1->docs-upload, 2->claim closing */ } })
            dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, ...result?.data, loading: false }))
        } catch {
            toast.error("Error in fetching data")
        }
    }

    useEffect(() => {
        getClaimProcessingData()
    }, [])

    return () => { }
}