import React from "react";
import { useEffect } from "react";

const PendingDocumentPopup = (props: any) => {
    let { show } = props;
    useEffect(() => {
        if (show) document.body.classList.add("overflow-hidden");
        return () => {
            document.body.classList.remove("overflow-hidden");
        }
    }, [show])
    return (
        <div className="mark-lost-popup pendingDocumentConfirmation">
            <div className="modal display-block" role="dialog">
                <section className="modal-main" role="document">
                    <div className="modal-header">
                        <h2 className="modal-title">{"Reminder: Pending Document(s)"}</h2>
                        <button type="button" className="close_icn" data-dismiss="modal" aria-label="Close" onClick={() => {
                            props.close();
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4>{"You have requested the following document(s) from the customer, which have not yet been uploaded:"}</h4><br></br>
                        {
                            props?.list?.length
                                ?
                                <ul>
                                    {
                                        props?.list?.map((item: any, idx: number) => {
                                            let lineBreak = (idx === (props?.list?.length - 1) ? true : false)
                                            return (
                                                <React.Fragment key={"item" + "_" + idx}>
                                                    <li>
                                                        {item.name}
                                                    </li>
                                                    {lineBreak ? <br></br> : undefined}
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </ul>
                                : undefined
                        }
                        <h4>{'If you choose to move to the next stage, the customer will no longer be able to upload any documents.'}</h4><br></br>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btn-lg m-sm-r" onClick={() => {
                            props.submit();
                        }}>Proceed</button>

                        <button type="button" className="btn btn-line" onClick={() => {
                            props.close();
                        }}>Wait</button>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PendingDocumentPopup;