import React, { useEffect, useState } from 'react';
import Logo from "../webroot/images/logo-carmudi.svg"
import { getClaimIdListDetail } from "@src/store/slices/claim";
import { useLocation } from 'react-router-dom';
import FullPageLoader from '../common/FullPageLoader';
import Claimstatus from './Claimstatus'
import UploadDocClaim from './UploadDocClaim'
import { useSelector, useDispatch } from "react-redux";
import { useApolloClient } from '@apollo/client';
import { getMasterConfig } from "@store/slices/common";
import { times } from 'lodash';
import { CUSTOMER_UPLOAD_FIELDS } from '@src/config/constant';

const TrackClaim = () => {
    const [trackData, setTrackData] = useState<any>();
    let [loading, setLoading] = useState(false);
    const [showClaimStatus, setShowClaimStatus] = useState(false)
    const [showUploadStatus, setShowUploadStatus] = useState(false)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const claimId = queryParams.get('claim_id');
    const dispatch: any = useDispatch()
    const commonState = useSelector((state: any) => state.common)
    const client: any = useApolloClient();
    const [statusList, setStatusList] = useState();
    const [pendingDocumentList, setPendingDocumentList] = useState([])


    const getClaimListDetail = () => {
        setLoading(true)
        let updatedFilters = {
            claim_id: claimId,
        };

        getClaimIdListDetail(updatedFilters)
            .then((res: any) => {
                if (res?.data?.status === 200) {
                    setTrackData(res?.data?.data?.data[0]);
                    setPendingDocumentList(res?.data?.data?.pendingDocuments || [])
                    setLoading(false)
                } else {
                    //   toast.error("Data not Found");
                    setLoading(false)
                }
            })
            .catch((error: Error) => {
                setLoading(false)
            });
    }

    useEffect(() => {
        getClaimListDetail()
    }, [claimId]);

    const showStausComp = () => {
        setShowClaimStatus(true)
    }

    const showUploadComp = () => {
        setShowUploadStatus(true)
    }

    useEffect(() => {
        if (commonState?.common?.claim_status && commonState?.common?.claim_status?.length > 0) {
            setStatusList(commonState?.common?.claim_status);
        } else {
            dispatch(getMasterConfig()).then((res: any) => {
                if (res?.claim_status) {
                    setStatusList(res?.claim_status);
                }
            });
        }
    }, []);

    return (
        <>
            <FullPageLoader show={loading} />
            {

                (!showClaimStatus && !showUploadStatus) &&
                <div className='track-insurance-outer h-100'>
                    <div className='track-insurance-inner-content'>
                        <div className='top-logo'>
                            <img src={Logo} />
                        </div>
                        <div className='sub-heading'>
                            Hey👋 {trackData?.customer_name}
                        </div>
                        <h1>Track Your Insurance Claim Progress</h1>
                        <div className='insurance-claim-detail'>
                            <ul>
                                {
                                    times(4, (idx: number) => {
                                        const key = CUSTOMER_UPLOAD_FIELDS[idx]?.key
                                        const fieldName = CUSTOMER_UPLOAD_FIELDS[idx]?.name

                                        let value = trackData?.[key]
                                        value = value ? (fieldName + (fieldName ? " " : '') + value) : value
                                        return (
                                            <React.Fragment key={fieldName + "_" + idx}>
                                                {
                                                    value ?
                                                        <li>
                                                            {value}
                                                        </li>
                                                        : undefined
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        <div className='claim-status-bg' onClick={showStausComp}>
                            {/* <span>{trackData?.status_name}</span> */}
                            <span>{"Claim Status"}</span>

                            <p>{trackData?.status_name}</p>
                            {/* <p>Claim Verified</p> */}
                            <div className='next-arrow-bg'>
                                <i className='ic-arrow_forward'></i>
                            </div>
                        </div>

                        <div className='claim-status-bg upload-doc-bg' onClick={showUploadComp}>
                            <span>Upload Documents</span>
                            {
                                pendingDocumentList.length
                                    ?
                                    <p>Pending: {pendingDocumentList?.map((e: any) => e.name).toString()}</p>
                                    : undefined
                            }

                            <div className='next-arrow-bg'>
                                <i className='ic-arrow_forward'></i>
                            </div>
                        </div>
                    </div>

                </div>
            }

            {
                showClaimStatus &&

                <Claimstatus trackData={trackData} setShowClaimStatus={setShowClaimStatus} statusList={statusList} />

            }
            {
                showUploadStatus &&

                <UploadDocClaim setShowUploadStatus={setShowUploadStatus} claim_id={claimId} setLoader={setLoading} claimDetails={trackData} updatePendingDocuments={setPendingDocumentList} pendingDocumentList={pendingDocumentList} />
            }

        </>

    )
}

export default TrackClaim;