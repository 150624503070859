export default function Modal({ handleClose, show, children, resubmission }:any) {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className={`modal-main ${resubmission ? 'modal-resubmission' : ''}`}>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};