import { gql } from '@apollo/client';

export const GET_USER_LIST =  gql`query userList($filter_list: UserInput!) { 
        userList(filter_list:$filter_list) {  
            status
            message            
            data {
                id
                name
                mobile
                email,
                assigned_role_ids
                status
                reportee_name
                reportee_id
                reportee_role_id
                reportee_role_name
                assigned_feature_ids
                assigned_feature_names
                assigned_role_name
            }
            pagination{
                cur_page
                limit
                next_page
                pages
                prev_page
                total
                count_all_user
                count_inactive_user
                count_active_user
            }
        }
    }`;



export const GET_DEALER_HISTORY =(id,month)=> {
    return gql`query{
        user_history(user_id:${id}){
            scheme_type_name 
            total_disbursal
            total_gmv
            amount
            diff_amount
            final_amount
            incentive_month
            status
            status_name
            bank_name
            beneficial_name
            account_number
        }
    }`
}


export const EXPORT_DEALER_INCENTIVE_DATA = () => {
    return gql`query incentive_list($filter_list: FilterInput!) { 
        incentive_list(filter_list:$filter_list) {  
            userinfo
                {
                    user_id,
                    user_name,
                    gcd_code,
                    items
                    {
                        total_disbursal
                        total_gmv
                        amount
                        diff_amount
                        final_amount
                        incentive_month
                        status
                        status_name
                        bank_name
                        beneficial_name
                        account_number
                        scheme_type_name
                        scheme_type_id
                        id
                        modify_status
                        modify_status_name
                    }
            }
        }
    }`
}


export const SAVE_USER =
    gql`mutation saveUserData($requestData: SaveUserInput!){    
        saveUserData(requestData:$requestData){
            status
            message
            data{
                    id
                }
            }
    }`;

export const DELETE_USER =
    gql`mutation user_delete($user_input: DeleteUserInput!) {
     user_delete(user_input: $user_input) {
            message
            status
    }
}`;    