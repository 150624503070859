import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../apolloClient';
// import { gql } from '@apollo/client';
import { GET_USER_LIST, SAVE_USER, DELETE_USER } from "../../services/user.gql";
import { GET_ROLE_LIST } from "../../services/role.gql";
import { toast } from 'react-toastify';
import { API_URL } from '../../config/constant';

// Define a GraphQL query
// const GET_ITEMS_QUERY = gql`
//   query GetItems {
//     items {
//       id
//       name
//       description
//     }
//   }
// `;

// Define an async thunk for fetching data
// export const fetchItems = createAsyncThunk('roleUser/fetchItems', async () => {
//     const response = await client.query({ query: GET_ITEMS_QUERY });
//     return response.data.items;
// });

// export const getUserList = createAsyncThunk('roleUser/fetchUsers', async (variables: any) => {
//     const response = await client.query({ query: GET_USER_LIST(), variables });
//     return response.data.userList;
// });


// export const getRoleList = (variables: any) => createAsyncThunk('graphql/fetchPosts', async () => {
//     let GRAPHQL_API = `${API_URL.GETWAY_API}api/v1`;
//     console.log('data')

//     const response = await fetch(GRAPHQL_API, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ query: GET_ROLE_LIST(), variables }),
//     });
//     const { data } = await response.json();
//     console.log(data)
//     return data;
//   });

// Create a slice
const roleUserSlice = createSlice({
    name: 'roleUser',
    initialState: {
        items: [],
        status: 'idle',
        error: null,
        roles: {},
        // users: {}
    },
    reducers: {
        // roleListing: (state, action) => {
        //     console.log(state)
        //     // state.roles = action.payload
        // },
        // roleListingError: (state, action) => {
        //     console.log(state)
        //     // state.roles = action.payload
        // }
    },
    extraReducers: (builder: any) => {
        // builder
        //     .addCase(getRoleList.pending, (state: any) => {
        //         console.log(state)
        //         state.roles.status = 'loading';
        //     })
        //     .addCase(getRoleList.fulfilled, (state: any, action: any) => {
        //         console.log(state)
        //         state.roles.status = 'succeeded';
        //         state.roles.items = action.payload;
        //     })
        //     .addCase(getRoleList.rejected, (state: any, action: any) => {
        //         state.roles.status = 'failed';
        //         state.roles.error = action.error.message;
        //     });
        // builder
        //     .addCase(getUserList.pending, (state: any) => {
        //         state.users.status = 'loading';
        //     })
        //     .addCase(getUserList.fulfilled, (state: any, action: any) => {
        //         state.users.status = 'succeeded';
        //         state.users.items = action.payload;
        //     })
        //     .addCase(getUserList.rejected, (state: any, action: any) => {
        //         state.users.status = 'failed';
        //         state.users.error = action.error.message;
        //     });
    },
});

export default roleUserSlice.reducer;

// const { roleListing, roleListingError } = roleUserSlice.actions

// export const getRoleListing = { 
// export const getRoleListing = (variables: object) => async (dispatch: any) => {
//     return new Promise((resolve, reject) => {
//         client.query({ query: GET_ROLE_LIST(), variables })
//             .then(
//                 (response: any) => {
//                     console.log(response)
//                     if ((response && response.data && response.data.getAllRoleList)) {
//                         resolve(response.data?.getAllRoleList)
//                         dispatch(roleListing(response.data?.getAllRoleList));
//                     }
//                     else {
//                         let errorMsg = (response && response.data && response.data.message) || ''
//                         if (errorMsg) toast.error(response.data.message);
//                         resolve(response && response.data)
//                         roleListingError(response?.data)
//                     }
//                 },
//                 (error: Error) => {
//                     reject(error);
//                 }
//             )
//     })
// }

export const getUserListing = (variables: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        client.query({ query: GET_USER_LIST, variables })
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.userList)) {
                        resolve(response.data?.userList)
                        // roleListing(response.data?.getAllRoleList);
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const saveUserData = (variables: object) => {
    return new Promise((resolve, reject) => {
        client.query({ query: SAVE_USER, variables })
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.saveUserData)) {
                        if(response.data.saveUserData?.status === 200){
                            toast.success(response.data?.saveUserData?.message);

                        }else{
                            toast.error(response.data?.saveUserData?.message);
                        }
                        resolve(response.data?.saveUserData)
                        // roleListing(response.data?.getAllRoleList);
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const deleteUser = (variables: object) => {
    return new Promise((resolve, reject) => {
        client.query({ query: DELETE_USER, variables })
            .then(
                (response: any) => {
                    resolve(response.data.user_delete);
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

// }
