import { gql } from '@apollo/client';

export const GET_ROLE_LIST = 
    gql`query getAllRoleList($filter_list: RolePayload!){    
        getAllRoleList(filter_list:$filter_list){
            status
            message             
            pagination{
                    cur_page
                    limit
                    next_page
                    pages
                    prev_page
                    total
            }
            data{
                    id
                    roleName
                    description
                    status
                    user_ids
                    assign_features {
                        feature_id
                        access_type
                    }
                }
                status
            }
    }`;

export const SAVE_ROLE =
    gql`mutation saveRoleData($requestData: SaveRoleInput!){    
        saveRoleData(requestData:$requestData){
            status
            message
            data{
                    id
                }
            }
    }`;

export const GET_FEATURE_LIST = 
    gql`query getAllFeatureList($filter_list: FeaturePayload!){    
        getAllFeatureList(filter_list:$filter_list){
            status
            message
            data{
                    id
                    name
                    parent_id
                    status
                }
                status
            }
}`;


export const UPDATE_ROLE_STATUS =
    gql`mutation update_status($requestData: RoleStatusInput!){    
        update_status(requestData:$requestData){
            status
            message
        }
    }`;