import Modal from "../../../common/ModalPopup";
import { useEffect, useState } from "react";
import Select from 'react-select';
import ReactSelect from "../../../common/ReactSelect";
import { useDispatch, useSelector} from 'react-redux';
import { toast } from "react-toastify";
import {saveLeadMarkedLost, updateClaimDetailsInStore, updateClaimHistory, updateClaimRibbonData}  from '@src/store/slices/claim';
import { getMasterConfig } from '../../../store/slices/common';
//import { updateLeadDetailsInStore } from "@src/store/slices/insurance";
//import { STATUS_SUB_STATUS, LOST_REASON_IDS, ROLE_FEATURES} from "@src/config/constant";
import FullPageLoader from "@common/FullPageLoader";
import * as HELPER from "@src/config/helper";


const MarkAsLostPopup = (props: any) => {
    const [errors, setError] = useState<any>({});
    const [formData, setFormData] = useState<any>({status : 1});
	const [loading, setLoading] = useState(false);
    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common);
    const insuranceState = useSelector((state: any) => state.insurance);
    let prevStatusId =  (insuranceState?.leadDetail && insuranceState?.leadDetail.status_id) ? +insuranceState?.leadDetail.status_id : 0;
    let prevSubStatusId = (insuranceState?.leadDetail && insuranceState?.leadDetail.sub_status_id) ? +insuranceState?.leadDetail.sub_status_id : 0;
    let [showRemarkField, setShowRemarkField] = useState<any>(false);
    const claimState: any = useSelector((state: any) => state.claim);

    useEffect(() => {
        if (Object.keys(commonState.common).length === 0) {
            dispatch(getMasterConfig());
        }
    }, [commonState.common, dispatch]);

    let subStatusList = commonState?.common?.sub_status_list || [] //&& commonState.common.sub_status_list ? commonState.common.sub_status_list : [];
    
   
    // let lostReason = commonState.common && commonState.common.lost_reason_list ? commonState.common.lost_reason_list : [];
    let sub_status_list : any = [];

    const handleChange = async (key:string, event:any) => {
		if(key === 'sub_status_id'){
            formData[key] = event.value;
            if(event?.value === 4){
                setShowRemarkField(true)
            }else{
                setShowRemarkField(false) 
            }
        } else if (key === 'remarks') {
            formData[key] = event.target.value;
        }
		errors[key] = '';
		setError({ ...errors });
        setFormData({ ...formData });

	}

	const isFormValid = (formData : any) => {
		let isValid = true;
		if (!formData.remarks && formData.sub_status_id === 4) {
			errors['remarks'] = 'remark is required';
			isValid = false;
		}
		// if(formData && formData.remarks && (formData.remarks.split('').length < 3 || formData.remarks.split('').length > 500)){
		// 	errors['remarks'] = 'Remarks should be between 3 to 500';
		// 	isValid = false;
		// }
		setError({ ...errors });
		return isValid;

	}
  
    const getFormData = async () => {
        let postData:any = {};
        if (formData && formData.sub_status_id) postData['sub_status_id'] = formData.sub_status_id;
        if (formData && formData.remarks) postData['remarks'] = formData.remarks;
        // if (formData && formData.reason) postData['reason'] = formData.reason;
      //  postData['status_id'] = STATUS_SUB_STATUS['status']['lost'];
        postData['lead_id'] = props.leadHashId;
      

        return postData;
    }


    const markLeadLost = async (e: any) => {
        e.preventDefault();
        let postData = await getFormData();
        if (isFormValid(postData)) {
        setLoading(true);
        let params: any = {
            claim_input: {
                claim_id: props.leadHashId,
                status_id: props.statusId,
                sub_status_id: formData.sub_status_id
            }
        };
        if (formData.remarks) {
            params.claim_input.remarks = formData.remarks
        }

        saveLeadMarkedLost(params).then((res: any) => {
            if (res && res.data.status === 200) {
                setFormData({});
                props.showModalMarkAsLost(false);
                if (res.data && Object.keys(res.data).length) {
                }
                props.setClaimData((prevClaimData:any) => ({
                    ...prevClaimData,
                    status_id: props.statusId
                }));
                toast.success(res.data.message);

                //update claim status in ribbon
                let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
                    if (e.key === 'status') {
                        return { ...e, value: res?.data?.data?.status_id }
                    }
                    return e
                })
                console.log(updatedRibbonData, res)
                dispatch(updateClaimRibbonData(updatedRibbonData)) 

                let updatedHistory = claimState?.claimHistory?.includes(res?.data?.data?.status_id) ? [] : [...claimState?.claimHistory, res?.data?.data?.status_id]
                console.log(updatedHistory)

                dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, status_id: res?.data?.data?.status_id }))
                if (updatedHistory.length) dispatch(updateClaimHistory(updatedHistory)) //update claim status history
            }
            setLoading(false);
        });
        }
    }

    return (

        <div className="view-timeline-popup image-timeline-popup mark-lost-popup">
            <FullPageLoader show={loading} />
            <Modal show={props.markLostPopup} handleClose={() => props.showModalMarkAsLost(false)} >
                <div className="modal-header">
                    <h2>Mark as Lost</h2>
                </div>
                <div className="modal-body" >
                    <div className="row">
                        <div className="col-md-12 m-lg-b">
                            <ReactSelect
                                options={subStatusList}
                                value = {subStatusList.filter((e : any) => e.value === formData.sub_status_id)}
                                placeholder="Sub Status *"
                                fieldKey=""
                                fieldItemName="sub_status_id"
                                isDisabled={false}
                                fieldSetClassName={"col-md-12"}
                                errorField={errors.sub_status_id}
                                handleChange={(e: any) => handleChange("sub_status_id", e)}
                            />
                        </div>
                        {
                            showRemarkField &&

                            <div className="col-md-12">
                                <fieldset className="form-filed">
                                    <div className="material">
                                        <label htmlFor="remarks">{"Remarks *"}</label>
                                        <textarea id="remarks" placeholder=" " name="remarks" className="form-input" value={formData.remarks} onChange={(e: any) => handleChange("remarks", e)} />
                                        <span className="error-txt">
                                            {errors.remarks || ""}
                                        </span>
                                    </div>
                                </fieldset>
                            </div>
                        }

                        <div className="col-md-12 t-center">
                            {
                               // HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['lost'], accessType: 'edit', assignUserId: insuranceState?.leadDetail?.assign_user_id }) && +insuranceState?.leadDetail?.sub_status_id !== 3  &&
                                
                                <button className="btn-primary m-md-t " onClick={(e:any) => markLeadLost(e)}>Save</button>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )

}
export default MarkAsLostPopup;