import React, { useEffect, useState } from 'react';
//import Logo from "../webroot/images/logo-carmudi.svg"
import { CLAIM_TYPE_CNC, STATUS_SUB_STATUS } from '@src/config/constant';

const Claimstatus = ({ trackData, setShowClaimStatus, statusList }: any) => {

    const goBack = () => {
        setShowClaimStatus(false)
    }
    const [showCNCReceived, setShowCNCReceived] = useState(false)

    // Function to find claim history based on status ID
    const findClaimHistory = (statusId: any) => {
        return trackData?.claim_history?.find((history: any) => history.status_id === statusId);
    };

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    };

    // useEffect to update CNC received state based on claim type
    useEffect(() => {
        if (trackData?.claim_type === CLAIM_TYPE_CNC) {
            setShowCNCReceived(true)
        } else if (trackData?.claim_type !== CLAIM_TYPE_CNC) {
            setShowCNCReceived(false)
        }
        // eslint-disable-next-line
    }, [trackData?.claim_type])

    return (

        <div className='track-insurance-outer h-100'>
            <div className='track-insurance-inner-content'>
                <div className='claim-status-heading'>
                    <div className='heading-back-arrow'>
                        <i className='ic-arrow_back' onClick={goBack}></i>
                        <h2>Claim Status</h2>
                    </div>
                </div>

                <div className='claim-customer-detail'>
                    <ul>
                        <li>
                            <label>Customer</label>
                            <span>{trackData?.customer_name}</span>
                        </li>
                        <li>
                            <label>Insurance ID</label>
                            <span>{trackData?.insurance_id}</span>
                        </li>
                        <li>
                            <label>Insurance Company</label>
                            <span>{trackData?.partner_name}</span>
                        </li>
                        <li>
                            <label>Claim ID</label>
                            <span>{trackData?.claim_id}</span>
                        </li>
                    </ul>
                </div>

                <div className='customer-claim-status-detail'>
                    <ul>
                        {
                            statusList?.map((status: any, idx: number) => {
                                if (status.value === STATUS_SUB_STATUS['status']['mark-as-lost']) { //skiping mark as lost
                                    return
                                }

                                if (showCNCReceived && status.value > STATUS_SUB_STATUS['status']['cnc-received']) {
                                    return
                                } else if (!showCNCReceived && status.value === STATUS_SUB_STATUS['status']['cnc-received']) {
                                    return
                                }

                                let className = ``
                                let historyObj = trackData?.claim_history?.map((ele: any) => ele.status_id)
                                if (historyObj?.includes(status?.value)) {
                                    className = 'completed'
                                }
                                //if cnc is skiped, and currently on evaluation received, then show blue on evaluation received
                                else if (!showCNCReceived && Math.max(...historyObj) === STATUS_SUB_STATUS['status']['send-to-insurance-broker'] && (idx + 1) === STATUS_SUB_STATUS['status']['evaluation-received']) className = "active"
                                else if (idx === Math.max(...historyObj)) className = 'active'


                                // Find matching claim history for this status
                                const matchingHistory = findClaimHistory(status.value);
                                const formattedDate = matchingHistory ? formatDate(matchingHistory.history_created_date) : '';

                                return (<>
                                    <li className={className} key={status.value}>
                                        <div className='claim-doc-process-txt'>
                                            <label>{status.label}</label>
                                        </div>
                                        <span className='date-txt'>{formattedDate}</span>
                                    </li>
                                </>);
                            })
                        }

                    </ul>
                </div>

            </div>
        </div>


    )
}
export default Claimstatus;