import { executeGraphQLMutation, executeGraphQLQuery } from '@src/common/executeGraphQLQuery';
import client from '../apolloClient';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EXPORT_GET_CLAIM_LIST_DATA, GET_CLAIM_PROCESSING_DATA, GET_CLAIM_RIBBON_DATA, GET_CLAIM_ID_DETAIL, GET_DOCUMENTS_FOR_CUSTOMER_UPLOAD, GET_DASHBOARD_DATA, GET_DOCUMENT_NOTIFICATION, GET_LEAD_HISTORY, SAVE_LEAD_MARK_LOST, GET_CLAIM_CLOSING_DETAILS, SAVE_CNC_TRANSFER_PROOF,MARK_DOC_RESUBMIT} from "@src/services/claimdata.gql";
import { UPDATE_CLAIM } from '@src/services/masterdata.gql';


const claim = createSlice({
    name: 'claim',
    initialState: {
        authUser: null, // Set the initial value for 'authUser' to null or the appropriate initial value
        login: false,
        redirectTo: '/',
        loginUserData: {},
        insuranceData: {},
        insuranceLeads: [],
        claimDetails: {},
        ribbonData: [],
        claimHistory: []

    } as any,
    reducers: {
        setInsuranceData: (state, action: PayloadAction<any>) => {
            state.insuranceData = action.payload
        },

        setAllInsuranceLeads: (state, action: PayloadAction<any>) => {
            state.insuranceLeads = action.payload
        },

        setClaimDetails: (state, action: PayloadAction<any>) => {
            state.claimDetails = action.payload
        },

        setClaimRibbonData: (state, action: PayloadAction<any>) => {
            state.ribbonData = action.payload
        },

        setClaimHistory: (state, action: PayloadAction<any>) => {
            state.claimHistory = action.payload
        }

    },
});

export default claim.reducer;

const { setInsuranceData, setAllInsuranceLeads, setClaimDetails, setClaimRibbonData, setClaimHistory } = claim.actions


export const setClaimInsuranceData = (data: any) => async (dispatch: any) => {
    dispatch(setInsuranceData(data))
}

export const setInsuranceLeads = (data: any) => async (dispatch: any) => {
    dispatch(setAllInsuranceLeads(data))
}

export const exportGetList = (params: any) => {
    return new Promise<any>((resolve, reject) => {
        client.query({ query: EXPORT_GET_CLAIM_LIST_DATA(), variables: params })
            .then(
                resp => {
                    let respData = { data: resp?.data?.exportGetAllClaimList }
                    resolve(respData)
                },
                error => {
                    reject(error)
                }
            )
    })
}

export const getRibbonData = (params: any) => async (dispatch: any) => {
    return new Promise<any>((resolve, reject) => {
        client.query({ query: GET_CLAIM_RIBBON_DATA(), variables: params })
            .then(
                resp => {
                    let respData = { ...resp?.data?.getClaimRibbonData }
                    let { data, history } = respData?.data
                    dispatch(setClaimRibbonData(data))
                    dispatch(setClaimHistory(history))
                    return resolve(respData)
                }, error => {
                    reject(error)
                }
            )
    })
}

export const getClaimIdListDetail = (params: any) => {
    return new Promise<any>((resolve, reject) => {
        client.query({ query: GET_CLAIM_ID_DETAIL(), variables: { claim_id: params.claim_id } })
            .then(
                resp => {
                    let respData = { data: resp?.data?.getClaimIdListDetail }
                    resolve(respData)
                },
                error => {
                    reject(error)
                }
            )
    })
}

export const fetchClaimProcessingData = (params: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            client.query({ query: GET_CLAIM_PROCESSING_DATA(params.type), variables: params.variables })
                .then(
                    resp => {
                        let respData = { ...resp?.data?.fetchClaimProcessingData }
                        resolve(respData)
                    },
                    error => {
                        reject(error)
                    }
                )
        } catch (error) {
            reject(error)
        }
    })
}

export const updateClaim = (client: any, variables: any) => {
    return new Promise((resolve, reject) => {
        try {
            executeGraphQLQuery(UPDATE_CLAIM(), client, variables).then((response: any) => {
                if (response && response.data && response.data.updateClaim) {
                    let result = response.data.updateClaim
                    return resolve(result)
                } else {
                    return resolve(response)
                }
            }).catch((err: any) => {
                return resolve(err)
            })
        } catch (error) {
            return resolve({})
        }
    })
}


export const updateClaimDetailsInStore = (data: any) => async (dispatch: any) => {
    dispatch(setClaimDetails(data))
}


export const updateClaimRibbonData = (data: any) => async (dispatch: any) => {
    dispatch(setClaimRibbonData(data))
}

export const updateClaimHistory = (data: any) => async (dispatch: any) => {
    dispatch(setClaimHistory(data))
}

export const getDocumentForCustomer = (client: any, variables: any) => {
    return new Promise((resolve, reject) => {
        try {
            executeGraphQLQuery(GET_DOCUMENTS_FOR_CUSTOMER_UPLOAD(), client, variables).then((response: any) => {
                if (response && response.data && response.data.getDocumentForCustomer) {
                    let result = response.data.getDocumentForCustomer
                    return resolve(result)
                } else {
                    return resolve(response)
                }
            }).catch((err: any) => {
                return resolve(err)
            })
        } catch (error) {
            return resolve({})
        }
    })
}

export const getDashboardData = (client: any, variables: any) => {
    return new Promise((resolve, reject) => {
        try {
            executeGraphQLQuery(GET_DASHBOARD_DATA(), client, variables).then((response: any) => {
                if (response && response.data && response.data.getDashboardCount) {
                    let result = response.data.getDashboardCount
                    return resolve(result)
                } else {
                    return resolve(response)
                }
            })
        } catch (error) {
            return resolve({})
        }
    })
}

export const getDocumentNotification = (client: any, variables: any) => {
    return new Promise((resolve, reject) => {
        try {
            executeGraphQLQuery(GET_DOCUMENT_NOTIFICATION(), client, variables).then((response: any) => {
                if (response && response.data && response.data.getDocumentNotifications) {
                    let result = response.data.getDocumentNotifications
                    return resolve(result)
                } else {
                    return resolve(response)
                }
            })
        } catch (error) {
            return resolve({})
        }
    })
}

export const getLeadHistory = (params: any) => {
    return new Promise<any>((resolve, reject) => {
        client.query({ query: GET_LEAD_HISTORY(), variables: params })
            .then(
                resp => {
                    let respData = { data: resp?.data?.getLeadHistory }
                    resolve(respData)
                },
                error => {
                    reject(error)
                }
            )
    })
}

export const saveLeadMarkedLost = (params: any) => {
    return new Promise<any>((resolve, reject) => {
        client.query({ query: SAVE_LEAD_MARK_LOST(), variables: params })
            .then(
                resp => {
                    let respData = { data: resp?.data?.saveLeadMarkedLost }
                    resolve(respData)
                },
                error => {
                    reject(error)
                }
            )
    })
}

export const getClaimClosingData = (client: any, variables: any) => {
    return new Promise((resolve, reject) => {
        try {
            executeGraphQLQuery(GET_CLAIM_CLOSING_DETAILS(), client, variables).then((response: any) => {
                if (response && response.data && response.data.getClaimClosingDetails) {
                    let result = response.data.getClaimClosingDetails
                    return resolve(result)
                } else {
                    return resolve(response)
                }
            })
        } catch (error) {
            return resolve({})
        }
    })
}


export const saveTransferCncProof = (params: any) => {
    return new Promise<any>((resolve, reject) => {
        client.query({ query: SAVE_CNC_TRANSFER_PROOF(), variables: params })
            .then(
                resp => {
                    let respData = { data: resp?.data?.saveTransferCncProof }
                    resolve(respData)
                },
                error => {
                    reject(error)
                }
            )
        })
}    

export const requestDocResubmission = (client: any, variables: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            executeGraphQLMutation(MARK_DOC_RESUBMIT(), variables, client).then((response: any) => {
                if (response && response.data && response.data.requestDocResubmission) {
                    let result = response.data.requestDocResubmission
                    return resolve(result)
                } else {
                    return resolve(response)
                }
            })
        } catch (error) {
            return resolve({})
        }
    })
}