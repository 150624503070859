import React, { useState,useEffect } from "react";
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import FullPageLoader from "../../common/FullPageLoader";
import { GET_PARTNER_LIST,GET_CSV_UPLOAD} from '../../../services/repairShop.gql';
import {executeGraphQLQuery,executeUpload} from "../../../common/executeGraphQLQuery";
import Pagination from '../../common/Pagination';
import { Link } from 'react-router-dom';
import AddRepairShopPartner from './AddRepairShopPartner';
import Modal from "../../../common/ModalPopup";
import CSVImport from '../../../common/CsvImportFile';
import secureStorage from "@src/config/encrypt";
import { print } from 'graphql';
import { useSelector,useDispatch} from "react-redux";
import { getMasterConfig } from "@store/slices/common";
import { ROLE_FEATURES } from "@src/config/constant";
import * as HELPER from "@src/config/helper";



export default function RepairShopList() {
    const client = useApolloClient();
    const [partnerList, setPartnerList] = useState<[]>([]);
    const [paginationData, setPaginationData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState<any>({ page_number: 1 });
    const [showAdd, setShowAdd] = useState(false);
    const [bulkUpload, setBulkUpload] = useState<boolean>(false);
    const [urlLink,setUrlLink] = useState<string>('');
    const [csvValue, setCsvValue] = useState<any>(null);
    const [emptyErr, setEmptyErr] = useState<boolean>(false)
    const [csvReset, setCsvReset] = useState(0); 
    const [BlankFlage, setBlankFlage] = useState(0); 
    let result = secureStorage.getItem("authUserInfo");
    const commonState: any = useSelector((state: any) => state.common)
    const dispatch: any = useDispatch()


    const runPaginationFunc = () => { };
    const handleRequestData = (params: any) => {
        let apiParams = {
            page_number: params.page_no
        }
        getPartnerListDetails(apiParams);
    }

    const getPartnerListDetails = async (params: any) => {
        setLoading(true);
        let updatedFilters = {filter_list:{ ...searchParams, ...params }}
        executeGraphQLQuery(GET_PARTNER_LIST(),client,updatedFilters).then((response:any) => { 
            setLoading(false);
            let { status, data,message,pagination } = response?.data?.getRepairShopConfigList;
            if(status && status===200){
                setPaginationData(pagination);
                setPartnerList(data);
            }else{
                toast.error(message);
                setPaginationData({});
                setPartnerList([]);
            }
        }).catch((error:any) => {
            toast.error(error);
            setLoading(false);
            setPaginationData({});
            setPartnerList([]);
        })
    };
     
    const bulkUploadFile = (action: boolean) => {
        setBulkUpload(action);
        document.body.classList.remove("overflow-hidden");
        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    } 

    const addNewPartner = (action: boolean) => {
        setShowAdd(action);
        document.body.classList.remove("overflow-hidden");
        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    }

    const getApiData = async () => {
        await getPartnerListDetails({});
    }


    const handleImport = async (e:any) => {
        if(csvValue){
            e.preventDefault();
            setLoading(true);
            const formData = new FormData();
            const operations = JSON.stringify({
                query: print(GET_CSV_UPLOAD()),
                variables: {
                    file:csvValue,
                    user_id: result?.user_data?.user_id || result?.id,
                    email : result?.user_data?.email || result?.email
                }
            });

            const map = JSON.stringify({
                "0": ["variables.file"]
            });

            formData.append('operations', operations);
            formData.append('map', map);
            formData.append('0', csvValue); // append the file

            try {
                const result = await executeUpload(formData);
                let { status, message } = result?.data?.csv_upload;
                if (status && status === 200) {
                    toast.success(message);
                    getPartnerListDetails({});
                    bulkUploadFile(false);
                    setCsvValue(null);
                }else{
                    toast.error(message);
                }
                setCsvReset(csvReset+1);
                setBlankFlage(BlankFlage+1);
                setLoading(false);
            } catch(err:any) {
                setLoading(false);
                toast.error(err || '');
            } finally {
                setLoading(false);
            }
        }

        // try {
            
        //     executeUpload(formData).then(async (res:any) => {
        //         props.setLoading(false);
        //         if (res?.data?.csv_upload) { 
        //             let result = res.data.csv_upload; 
        //             if(result?.is_error){
        //                 toast.error(result?.message);
        //                 setFile(undefined);
        //             } else {
        //                 if(result?.generated_url){
        //                     setShowUploadedFile(true);
        //                     setUploadedUrl(result?.generated_url);
        //                 } 
        //                 setErrorMessage(result?.errorMsg); 
        //                 if(!result?.errorMsg?.length){
        //                     props.hideModalPdfUploadPopUp();
        //                     props.showSuccessMsgPopUp();
        //                 } else {
        //                     toast.success(result?.message || "");
        //                     setDisableUpload(true);
        //                 }
        //             } 
        //         } else { 
        //             if(res?.errors?.[0]?.statusCode == 408){
        //                 toast.error("It is taking too long. Csv will be uploaded and send to you Email.");
        //             } else {
        //                 toast.error(res?.errors?.[0]?.message || '');
        //             } 
        //         }
        //     })
        // } catch (err) {
        //     props.setLoading(false); 
        //     toast.error(err || '');
        // } 
    };

    const fetchMasterData = async () => {
        let data: any = {}
        if (commonState?.common && Object.keys(commonState.common || {})?.length) {
            data = commonState.common
        } else {
            data = await dispatch(getMasterConfig())
        }

        return data
    }
  
    useEffect(() => {
        getApiData();
    }, []);

    useEffect(() => {
        if (!Object.keys(commonState?.common)?.length) {
            fetchMasterData()
        } else {
            let { sample_csv} = commonState?.common; 
            const RepairSample = sample_csv.find((sample:any) => sample.type === "bulk_upload"); 
            setUrlLink(RepairSample?.url)
        }
    }, [commonState]);

    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className="container-fluid rule-engine-pages">
                <div className="top-heading repair-shop-heading">
                    <h1>Repair Shop Management</h1>
                    {
                        HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['region_management'], accessType: 'edit'}) &&
                        
                        <div className="d-flex g-10">
                            <button className="btn-line" onClick={() => bulkUploadFile(true)}>
                                <i className="ic-add"></i>
                                <span>Bulk Upload</span>
                            </button>
                            <button className="btn-line" onClick={() => addNewPartner(true)}>
                                <i className="ic-add"></i>
                                <span>Add New Repair Shop</span>
                            </button>
                        </div>

                    }
                    

                </div>
                <div className="loan-filter-bg managment-tabs tab-chips">
                    <div className="lead-list-data-table data-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Insurance Partner</th>
                                    <th>Accredited Repair Shop</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                            {(partnerList && partnerList.length > 0) ? 
                              partnerList.map((item:any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Link to={`/partner-repair-detail-list?partner_id=${item.hashId}`}>{item.name}</Link>
                                        </td>
                                        <td>
                                            <span>Active : <label className="green">{item.active}</label> </span><br />
                                            <span>InActive : <label className="red">{item.in_active}</label> </span>
                                        </td>
                                    </tr>
                                )
                              }):
                              <tr><td colSpan={2} style={{ textAlign: 'center' }}>No data found</td></tr>
                            }
                            </tbody>
                        </table>

                        {(paginationData && paginationData['total'] > 10) && <Pagination paginateData={{
                            page: 1,
                            runPaginationFunc,
                            loading: false,
                            tableData: paginationData['limit'],
                            totalLengthWithoutPagination: paginationData['total'],
                            paginationData,
                            filteredData: {}
                            }}
                            sendRequestDataToParent={handleRequestData} />}


                    </div>

                    {
                        showAdd &&
                        <AddRepairShopPartner
                            showAdd={showAdd}
                            showPartner={true}
                            reloadShopData={getApiData}
                            heading={`Add Insurance Partner`}
                            Submitbutton={`Add Insurance Partner`}
                            addNewPartner={(e: boolean) => {addNewPartner(e);}}
                        />
                    }

                    
                    {
                        bulkUpload && 
                        <div className="model-outer Evaluation-Letter-popup">
                            <Modal show={bulkUpload} handleClose={(e: boolean)=>{bulkUploadFile(false)}}>
                                <div className="modal-header">
                                    <h2>Repair Shop - Bulk Upload</h2>
                                </div>
                                <div className="modal-body">
                                    <div className='upload-file-outer'>
                                        <CSVImport 
                                            setCsvValue={setCsvValue}  
                                            csvReset= {csvReset} 
                                            emptyErr= {emptyErr} 
                                            BlankFlage = {BlankFlage} 
                                            setError={setEmptyErr} 
                                        />
                                        <div className="download-txt">Download input format < a href={`${urlLink}`} download>Click Here</a>
                                        </div>
                                        {
                                            (csvValue)?
                                            <div className="col-md-12 p-lg-t">
                                                <button className="btn-primary" onClick={handleImport}>upload File</button>
                                            </div>:
                                            <div className="col-md-12 p-lg-t">
                                                <button className="btn-primary" disabled>upload File</button>
                                            </div>  
                                        }

                                    </div>
                                </div>
                                <div className='modal-footer'></div>
                            </Modal>
                        </div>
                    }
                </div>
                
            </div>
        </React.Fragment>
    )
}
