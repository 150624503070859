import { gql } from "@apollo/client"

export const Login_User = gql`mutation login_user($email: String!, $password: String!,$hash: String!,$captcha:String!,$mobile:String!,$resendOtp:Boolean) {
  login_user(email: $email, password: $password,hash: $hash, captcha: $captcha,mobile: $mobile,resendOtp: $resendOtp) {
         name 
         id       
         email 
         message
         is_error
         status
 }
}`
 
export const VALIDATE_OTP = (email, otp, captchaVal) => {
  return gql`query{
    validate_otp(email:"${email}", otp: "${otp}" captcha_val: "") {
      status  
      message
      user_data {
        id
        user_id
        name
        email
        mobile
        assigned_role_name
        assigned_role_ids
        feature_access
      }
      access_token
      report_access  
      }
}`
}


export const GET_CAPTCHA_IMG = () => {
  return gql`query{
      get_captcha_img{
        message
        image
      }
    }`
}

export const GET_CAPTCHA = () => {
  return gql`query{
    get_captcha
  }`
}

export const GOOGLE_EMAIL = (email) => {
  return gql`query {
      login_with_email(email : "${email}") {
        message
         user_data{
          user_id
          name
          email
          mobile
          assigned_role_name
          assigned_role_ids
          feature_access
         }
        access_token
        report_access  
        is_error
    }
  }`
}