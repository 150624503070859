import { useState } from "react";
import Modal from "./ModalPopup";
import { IMG_SOURCE_CUSTOMER, STATUS_SUB_STATUS } from "@src/config/constant";
import ResubmissionPopup from "./ResubmissionPopup";

export default function ResubmissionModal(props: any) {
    const { showModal, sliderImages } = props

    const [activeImageIndex, setActiveImageIndex] = useState(props.activeImageIndex)
    const [showResubmissionPopup, setShowResubmissionPopup] = useState(false)

    const changeSlider = (sliderIndex: number) => {
        setActiveImageIndex(sliderIndex)
    }

    return (
        <>
            <div className="view-timeline-popup tin-document-popup">
                <Modal show={showModal} handleClose={() => { props.closeResubmitModal(); }} resubmission={true}>
                    <div className="modal-header">
                        <h2>{props.heading}</h2>
                    </div>
                    <div className="modal-body" >
                        {
                            sliderImages?.length && sliderImages?.map((img: any, key: number) => {
                                let fileName = img?.url?.split('?')?.[0]

                                let extension = fileName?.split('.')
                                extension = extension?.[extension.length - 1]?.toLowerCase()

                                let isVideo = ['mp4', 'mov'].includes(extension)
                                let isPdf = ['pdf'].includes(extension);

                                return (
                                    <div key={key} className={`img-bx-select-opt  ${activeImageIndex === key ? "show" : "hide"} p-rel reset-width-height`}>

                                        {
                                            key > 0 && <span onClick={() => changeSlider(key - 1)} className="arrow-prev arrow-silder"> {"<"} </span>
                                        }

                                        {
                                            // img?.type?.indexOf("pdf") !== -1
                                            //     ?
                                            //     <div><i className={`ic-${img?.type}-file icons-normal t-accent`} ></i></div>
                                            //     :
                                            isVideo
                                                ?
                                                <img src={'/play3.svg'} className='' onClick={() => window.open(img.url)} alt="video-play-icon" />
                                                :
                                                (
                                                    isPdf
                                                    ?
                                                        <a href={img.url} target="_blank" rel="noreferrer"><i className={`ic-pdf-file icons-normal`}></i></a>
                                                    :
                                                        <img src={img.url} alt={`preview-${key}`} className="upload-icn" />

                                                )


                                        }
                                        {/* {
                                    <span className="image-requested">Requested</span>
                                } */}
                                        {
                                            (sliderImages?.length - 1 !== key) && <span onClick={() => changeSlider(key + 1)} className="arrow-next arrow-silder"> {">"} </span>
                                        }
                                        {
                                            img.source === IMG_SOURCE_CUSTOMER && img.is_resubmit !== 1 &&
                                                (props.status < STATUS_SUB_STATUS['status']['document-uploaded'] ||
                                                    props.status === STATUS_SUB_STATUS['status']['loa-received']
                                                )
                                                ?
                                                <div className="t-center p-lg-t silder-action-button">
                                                    <button className="btn-line" onClick={() => setShowResubmissionPopup(true)} >
                                                        Request Resubmission
                                                    </button>
                                                </div>
                                                :
                                                img.source === IMG_SOURCE_CUSTOMER && img.is_resubmit === 1
                                                    ?
                                                    <button className="btn-fill">
                                                        <i className="ic-check"></i>
                                                        Re-submission Requested
                                                    </button>
                                                    : undefined

                                        }
                                    </div>
                                )
                            }
                            )
                        }


                    </div>
                </Modal>
            </div>
            {
                showResubmissionPopup
                    ?
                    <ResubmissionPopup show={showResubmissionPopup} handleClose={() => setShowResubmissionPopup(false)} handleBtnClick={(comment: any) => {
                        props.handleResubmitDocTrigger(comment, sliderImages?.[0]?.document_id)
                        setShowResubmissionPopup(false)
                    }
                    } />
                    : undefined
            }
        </>

    )
}