import { useEffect, useState } from "react";

const AddApplicationPopup = (props: any) => {
    let { show } = props;
    const [docUploadLink, setDocUploadLink] = useState<any>('')

    useEffect(() => {
        if (show) {
            setDocUploadLink(`/claim-details/${props?.fields?.hash_id}?type=document-upload`)
            document.body.classList.add("overflow-hidden");
        }

        return () => {
            document.body.classList.remove("overflow-hidden");
        }
    }, [show])
    return (
        <div className="mark-lost-popup deleteConfirmation">
            <div className="modal display-block" role="dialog">
                <section className="modal-main" role="document">
                    <div className="modal-header">
                        <button type="button" className="close_icn" data-dismiss="modal" aria-label="Close" onClick={() => {
                            props.handleClose();
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-img">
                            <img src="/check.svg" className="check-icon" alt='check-img-icon' />
                        </div>
                    </div>
                    <div className="modal-body">
                        <h3 style={{ textAlign: 'center' }}>{"Application Created Successfully"}</h3><br></br>
                    </div>

                    <div className="modal-footer" style={{ textAlign: 'center' }}>
                        <div className="add-application-popup-data">
                            <span>{props?.fields?.customer_name}</span>
                            <span>{props?.fields?.id} </span>
                            <span>{props?.fields?.plate_number}</span>
                        </div>
                        <h5 className="m-xl-t" style={{ cursor: 'pointer' }}><a href={docUploadLink}>Click here to upload docs</a></h5>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AddApplicationPopup;