import { useEffect, useState } from "react"

export default function Pagination(props:any) {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    let { paginateData } = props
    let [page, setPage] = useState(1)
    let [runPaginationFunc, setRunPaginationFunc] = useState(false)
    let [totalLengthWithoutPagination, setTotalLengthWithoutPagination] = useState(0)
    let [cachedPostData, setCachedPostData] = useState({})
    let [loading, setLoading] = useState(false)
    /* eslint-enable @typescript-eslint/no-unused-vars */
    const { paginationData } = paginateData || {};
    // eslint-disable-next-line
    let numberPaginate = []
    let activeClass = ''

    /**
* SUBMIT FILTER HANDLER
*/
    const filterSubmit = async (params:any) => {
        let postData:any = { page_no: params['page'] || 1 };

        setPage(postData.page_no || 1)

        if (params.callingFrom && params.callingFrom === 'pagination') {
            let pageNum = postData['page_no'];
            postData = Object.keys(cachedPostData).length ? Object.assign({}, cachedPostData) : postData
            postData['page_no'] = pageNum
            postData['tab'] = (props.tab) ? props.tab : undefined
        } else {
            let cachedPostData = Object.assign({}, postData);
            setCachedPostData(cachedPostData)
        }
        props.sendRequestDataToParent(postData);

    }

    /**
   * 
   * ON PAGE NUMBER CLICK PAGINATE
   */
    const onPageClick = (page:any, activeClass = '') => (e:any) => {
        e.preventDefault()

        if (!activeClass && page > 0 && page <= props.paginateData.paginationData.pages) {

            let loading = false;

            if (page !== 0) {
                loading = true;
            }

            //CALL FOR API & SENT runPaginationFunc TRUE WHICH SHOWS IF TRUE THEN UPDATE DATA IN TABLE
            setPage(page)
            setLoading(loading)
            setRunPaginationFunc(true)

            filterSubmit({ page, callingFrom: 'pagination' });
        }

    }


    //CREATE PAGINATE NUMBERS
    if (paginationData && Object.keys(paginationData).length && paginationData.total && paginationData.total > 10) {

        //MOVE BACKWARD
        numberPaginate.push(
            <li key={'left'} className="pre">
                <a onClick={onPageClick(paginationData.cur_page - 1)} href="#/"><i className="ic-keyboard_arrow_left"></i></a>
            </li>
        )
        // paginationData.cur_page === 1 ? true : false}
        //SHOW FIRST PAGE LOGIC
        if (paginationData.cur_page > 4) {
            numberPaginate.push(<li className={activeClass} key="1"><a onClick={onPageClick(1, activeClass)} href="#/" >1</a></li>)

            numberPaginate.push(<li key={'seprator'}><a onClick={onPageClick(-1, activeClass)} href="#/" >...</a></li>)
        }

        // let pageUpto = (paginationData.cur_page <= 5) ? (paginationData.pages>10 ? 5 : paginationData.pages) : (paginationData.cur_page === paginationData.pages ? paginationData.pages-1 : paginationData.cur_page );
        // let pageFrom = (paginationData.cur_page <= 5) ? 1 : paginationData.cur_page-4;
        let pageFrom = (paginationData.cur_page < 5) ? 1 : paginationData.cur_page - 2;
        let pageUpto = (paginationData.cur_page < 5) ? (paginationData.pages > 10 ? 5 : paginationData.pages) : ((paginationData.cur_page + 2) > paginationData.pages) ? paginationData.pages : (paginationData.cur_page + 2);

        //PAGINATE NUMBERS UPDATE ON CHANGE LEFT/RIGHT BUTTONS
        for (let number = pageFrom; number <= pageUpto; number++) {

            activeClass = (+paginationData.cur_page === +number) ? 'active' : ''
            //IF MORE THAN 10 PAGES, THEN SHOW ONLY 5 PAGE
            if (paginationData.pages > 10) {
                numberPaginate.push(<li className={activeClass} key={number}><a onClick={onPageClick(number, activeClass)} href="#/" >{number}</a></li>)

            } else { //ELSE SHOW ALL PAGES
                numberPaginate.push(<li className={activeClass} key={number}><a onClick={onPageClick(number, activeClass)} href="#/" >{number}</a></li>)
            }
        }

        //SHOW LAST PAGE AND SEPRATOR
        if (paginationData.pages > 10) {
            activeClass = (paginationData.cur_page === paginationData.pages) ? 'active' : ''

            if (![paginationData.pages - 1, paginationData.pages - 2, paginationData.pages].includes(paginationData.cur_page)) {
                numberPaginate.push(<li key={'sepratorlast'}><a onClick={onPageClick(-1)} href="#/" >...</a></li>)
            }
            if ((paginationData.cur_page + 2) < paginationData.pages) {
                numberPaginate.push(<li className={activeClass} key={paginationData.pages + 'last'}><a onClick={onPageClick(paginationData.pages, activeClass)} href="#/" >{paginationData.pages}</a></li>)
            }

        }
        //MOVE FORWARD
        // if(![ paginationData.pages].includes(paginationData.cur_page) ){
        // numberPaginate.push(<li key={'right'} className="next"><a onClick={this.onPageClick(paginationData.cur_page+1, activeClass)} href="#/" ><i className="ic-keyboard_arrow_right"></i></a></li>)
        // } 
        if ((paginationData.cur_page + 1) === paginationData.pages) {
            numberPaginate.push(<li key={'right'} className="next"><a onClick={onPageClick(paginationData.cur_page + 1, "")} href="#/" ><i className="ic-keyboard_arrow_right"></i></a></li>)
        } else if (paginationData.cur_page !== paginationData.pages) {
            numberPaginate.push(<li key={'right'} className="next"><a onClick={onPageClick(paginationData.cur_page + 1, activeClass)} href="#/" ><i className="ic-keyboard_arrow_right"></i></a></li>)
        }
    }



    useEffect(() => {

        if (paginateData && paginateData.runPaginationFunc) {
            setRunPaginationFunc(false)
        }
        if (paginateData && paginateData.page) {
            let page = paginateData.page || 0
            setPage(page)
        }

        if (paginateData && paginateData.filterData) {
            let filter = paginateData.filterData || {}
            setCachedPostData(filter)
        }
        let totalLengthWithoutPagination = (paginateData && paginateData.totalLengthWithoutPagination) || 0
        setTotalLengthWithoutPagination(totalLengthWithoutPagination)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberPaginate])

    return (
        <div className="pagination-bx">
            <ul>
                {
                    numberPaginate.map(page => {
                        return page
                    })
                }
            </ul>
        </div>
    )
}