import React from 'react';
import Select, { components } from 'react-select';


const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }:any) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const ReactSelect = (props:any) => {

    return (
        <>
            <fieldset className={`single-select ${props.fieldSetClassName||""} ${(props.fieldItemName==="lead_source" && ![3,4,5,7].includes(props?.value?.value)) ? "p-b-50" : ""} `} key={props.key || "reactSelect"}>
                <div className={props?.disableMargin ? '' : "material"}>
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        options={props.options || []}
                        value={props.value}
                        onChange={(e) => props.handleChange(e, props.fieldKey)}
                        placeholder={props.placeholder}
                        className='react-select'
                        classNamePrefix='react-select'
                        isDisabled={props.isDisabled}
                        isOptionDisabled={(option) => option.disabled}
                        menuIsOpen={props.fieldKey === 'agency' ? props.menuIsOpen : undefined}
                    />
                    
                    {props?.errorField ? <span className={props?.errorClassName || "error-txt"}>{props.errorField}</span> : null}

                </div>
            </fieldset>
        </>
    );
};

export default ReactSelect;
