import { gql } from '@apollo/client';

export const GET_LOA_RECEIVED_DATA = (claim_id) => {
    return gql`query{
        getLoaData(claim_id:"${claim_id}"){
            status
            message
            data {
                id
                accident_date
                claim_number
                approval_date
                approve_ref_no
                approve_final_amt
                dep_amt_applied
                deduc_amt_applied
                net_amt_approved
                img_name
                url
            }
        }
    }`
};


export const SAVE_LOA_DATA = gql`mutation save_loa_received($saveLoaInput:SaveLoaInput!) {
    save_loa_received(saveLoaInput: $saveLoaInput) {
           status
           message
            data {
                status_id
            }
        
   }
}`