 import Login from "../view/auth/Login" 
import NotFoundPage from "../view/common/404Page";
import { useLocation, useRoutes } from "react-router-dom";
// import Dashboard from "../view/dashboard/Dashboard";
import DealerList from "../view/dashboard/common/DealerList";
import DealerConfiguration from "../view/dashboard/common/DealerConfiguration";
import Dashboard from "../dashboard/Dashboard";
import ClaimLeadList from  "../component/claim/ClaimList";
import ClaimDetails from "../component/claim/details/index"
import LeadDetailsForm from "../component/claim/details/ClaimDetailForm";
import DocumentsUpload from "../component/claim/details/DocumentsUpload";
import SendInsurance from "../component/SendInsurance";
import EvaluationReceived from "../component/EvaluationReceived";
import LOAReceived from "../component/LOAReceived";
import ClaimClosing from "../component/claim/ClaimClosing";
import TransferProof from "../component/TransferProof";
import PrivateRoute from './privateRoute';
import RuleEngine from "../view/rule-engine/RuleEngine";
import UserListTable from "../view/rule-engine/role-user/UserListTable";
import RoleList from "../view/rule-engine/role-user/RoleList";
import { Route, Routes, Navigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import SendToFinancierTemplateListing from "@component/template/SentToFinancerConfigListing";
import SendToFinancierTemplateDetail from "@component/template/SentToFinancerGroupDetail";
import TrackClaim from "../insurance-mobile/TrackClaim"
import Claimstatus from "../insurance-mobile/Claimstatus"
import UploadDocClaim from "../insurance-mobile/UploadDocClaim"
import RepairShopList from "../view/rule-engine/repair-shop/RepairShopList";
import PartnerRepairShopList from "../view/rule-engine/repair-shop/PartnerRepairShopDetail";
 
const AppRoutes = () => {
    const location = useLocation()
    let currentPath = location.pathname;

    const [loadRoutes, setLoadRoutes] = useState(true)

    useEffect(()=>{

        // getPublicKey().then((res: any)=> {
        //     if(res?.data) setLoadRoutes(true)
        // })

    },[])
    
    return (
        <>
            {
                // !["/", "", "/login", "/not-found"].includes(currentPath) && <Header />
            }
            { loadRoutes && <Routes>

                <Route path="/" element={<PrivateRoute components={Dashboard} accessPageId="23"  accessType = "isVisible"/>} />        
                <Route path="/dashboard" element={<PrivateRoute components={Dashboard} accessPageId="23"  accessType = "isVisible"/>} />        
                <Route path="/login" element={<PrivateRoute components={Login} accessPageId="23"  accessType = "isVisible"/>} />        
                <Route path="/rule-engine" element={<PrivateRoute components={RuleEngine} accessPageId="23"  accessType = "isVisible"/>} />        
               
                <Route path="/claim-list" element={<PrivateRoute components={ClaimLeadList} accessPageId="23"  accessType = "isVisible"/>} />        
                <Route path="/claim-details/:id" element={<PrivateRoute components={ClaimDetails} accessPageId="23"  accessType = "isVisible"/>} />        
                <Route path="/user-list" element={<PrivateRoute components={RoleList} accessPageId="23"  accessType = "isVisible"/>} />
                <Route path="/email-template-config" element={<PrivateRoute components={SendToFinancierTemplateListing} accessPageId="23"  accessType = "isVisible"/>} />
                <Route path="/email-template-config-detail" element={<PrivateRoute components={SendToFinancierTemplateDetail} accessPageId="23"  accessType = "isVisible"/>} />


                <Route path="/upload-doc" element={<PrivateRoute components={UploadDocClaim} accessPageId="23"  accessType = "isVisible"/>} />
                <Route path="/claim-status" element={<PrivateRoute components={Claimstatus} accessPageId="23"  accessType = "isVisible"/>} />
                <Route path="/track-claim"  element={<TrackClaim />} />

                <Route path="/repair-shop-list" element={<PrivateRoute components={RepairShopList} accessPageId="23"  accessType = "isVisible"/>} />

                <Route path="/partner-repair-detail-list" element={<PrivateRoute components={PartnerRepairShopList} accessPageId="23"  accessType = "isVisible"/>} />           
                
            </Routes>
            }

            {
                // !["/", "", "/login", "/not-found"].includes(currentPath) && <Footer />
            }
        </>
    );
}


  export default AppRoutes;
