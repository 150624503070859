import React from "react";

export default function FullPageLoader(props:any) {
    let { show } = props
    const showHideClassName = show ? 'loader-full' : 'display-none';

    return (
        <div className={showHideClassName}>
            <div className=" loader loader-default is-active loader-main" data-text="loading"></div>
        </div>
    );
};
