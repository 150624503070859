import secureStorage from '../../config/encrypt';
import { UserService } from '../../services/UserService';

import { createSlice , PayloadAction, Dispatch } from '@reduxjs/toolkit'

import { toast } from "react-toastify";
import client from '../apolloClient';
import { GET_CAPTCHA, Login_User, VALIDATE_OTP,GOOGLE_EMAIL } from "@src/services/login.gql";
import { executeGraphQLQuery } from '@common/executeGraphQLQuery';

interface CaptchaData {
   // data: string; 
   data: {
    svg: string;
    value: string;
  }; 
}

export interface OtpResponse {
    data: {
            
        status: number;
        message: string;
        user_id: string;
    };
  }

interface OtpRequest {
    email: string;
    password?: string;
    captcha?: string;
    resendOtp?: boolean;
    otp?:number;
}

interface UserState {
    authUser: any; // Replace 'any' with the appropriate type for 'authUser'
    login: boolean;
    redirectTo: string;
    loginUserData: object;
  } 
  
const user = createSlice({
    name: 'user',
    initialState: {
      authUser: null, // Set the initial value for 'authUser' to null or the appropriate initial value
      login: false,
      redirectTo: '/',
      loginUserData: {}

    } as UserState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<any>) => {
            state.authUser = action.payload;
            state.login = true;
            state.redirectTo = '/'

        },

        loginUserData: (state, action: PayloadAction<any>) => {
            console.log("ashdaldjlkasd")
            state.loginUserData = action.payload;           

        }
    },
});

export default user.reducer;

const { loginSuccess, loginUserData  } = user.actions

export const loadloginCaptcha = () => {
    return new Promise<CaptchaData>((resolve, reject) => {
        // client.query({ query: GET_CAPTCHA() })
        executeGraphQLQuery(GET_CAPTCHA(), client)

            .then(
                (captcha:any) => { 
                    let captchaData = {data: captcha?.data?.get_captcha}
                    resolve(captchaData)
                 },
                (error:any) => {
                    reject(error)
                }
            )
    })
}


export const createOtp = (params: any, dispatch: Dispatch): Promise<OtpResponse> => {

    return new Promise<OtpResponse>((resolve, reject) => {
        // UserService.createOtp(params)
        // client.query({ query: Login_User, variables: params })
        executeGraphQLQuery(Login_User, client, params)

            .then(
                (user: any) => { 
                    console.log(user)
                    if (user && user.data && user.data?.login_user) {
                        const loginUser = user.data?.login_user;

                        if (loginUser.is_error) {
                            toast.error(loginUser.message); 
                            
                        } else {
                            toast.success(loginUser.message); 
                            dispatch(loginUserData(loginUser));
                        }

                        return resolve({ data: loginUser });
                    } else {
                        if(user?.errors?.[0]?.message){
                            toast.error('Invalid Email or Password');
                            return resolve({ data: {status: 400, message: 'Invalid Email or Password', user_id: ''}  });
                        }      
                    }

                },
                (error:any) => {
                    toast.error(error.message); 
                    reject(error);
                }
            );
    });

}


export const VerifyOtpHandler = async (params: OtpRequest): Promise<OtpResponse> => {

    return new Promise((resolve, reject) => {
        // UserService.VerifyOtp(params)
        executeGraphQLQuery(VALIDATE_OTP(params.email, params.otp, ''), client, params, { headers: { api_name: "validate_otp" }})

        // client.query({ query: VALIDATE_OTP(params.email, params.otp, ''), variables: params, context: { headers: { api_name: "validate_otp" }} })
            .then(
                (user:any) => {
                    console.log(user)
                    if (user && user.data && user.data?.validate_otp?.status === 200) {

                        let ob = user.data.validate_otp;

                        secureStorage.setItem('authUserInfo', {access_token: ob?.access_token, user_data: ob?.user_data});
                        toast.success(user.data?.validate_otp.message);
                        // dispatch(loginSuccess(ob));
                        resolve(user.data?.validate_otp)
                    }
                    else {
                        toast.error(user.data?.validate_otp?.message);
                        resolve(user && user.data?.validate_otp)
                    }
                },
                (error:any) => {
                    reject(error);
                }
            );
    });
}


export const getUserList = async (params: any): Promise<any> => {

    return new Promise((resolve, reject) => {
        UserService.getAllUsers(params)
            .then(
                user => {
                    if (user && user.data && user.data.status === 200) {
                        resolve(user.data)
                    }
                    else {
                        toast.error(user.data.message);
                        resolve(user && user.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

export const googleWithLogin = (params: any, dispatch: Dispatch): Promise<OtpResponse> => {

    return new Promise<OtpResponse>((resolve, reject) => {
        // UserService.createOtp(params)
        executeGraphQLQuery(GOOGLE_EMAIL(params.email), client, params, { headers: { api_name: "login_with_email"}})

        // client.query({ query: GOOGLE_EMAIL(params.email), variables: params , context: { headers: { api_name: "login_with_email"}}})
            .then(
                (user: any) => { 
                    console.log(user)
                    if (user && user.data && user.data?.login_with_email) {
                        const loginUser = user.data?.login_with_email;

                        if (loginUser.is_error) {
                            toast.error(loginUser.message); 
                            
                        } else {
                            secureStorage.setItem('authUserInfo', {access_token: loginUser?.access_token, user_data: loginUser?.user_data});
                            toast.success(loginUser?.message);
                            // dispatch(loginSuccess(ob));
                            resolve(user.data?.login_with_email)
                        }

                        return resolve({ data: loginUser });
                    } else {
                        if(user?.errors?.[0]?.message){
                            // toast.error('Invalid Email or Password');
                            return resolve({ data: {status: 400, message: user?.errors?.[0]?.message, user_id: ''}  });
                        }      
                    }

                },
                (error:any) => {
                    toast.error(error.message); 
                    reject(error);
                }
            );
    });

}


// export const checkAuth = () => {
//     UserService.checkAuth();
// }