import { gql } from '@apollo/client';

export const GET_MASTER_DATA = () => gql`
    query{
      master_config(status:1) {
        scheme_type_list{
            id
            name
        } 
        status_list{
            id
            name 
        } 
        modify_status_list{
            id
            name 
        } 
    }
    }
`;

export const GET_INSURANCE_FOR_CLAIM = () => gql`
    query getLeadsForClaims{
        get_leads_for_claim {
            status
            message
            data {
                label
                value
                lead_id
                customer_name
                plate_number
                policy_number
                expiry_date
            }
        }
    }
`;


export const GET_INSURANCE_FOR_CLAIM_BY_ID = () => gql`
    query getLeadsForClaims($lead_id: Float!){
        get_leads_for_claim_by_id(lead_id: $lead_id) {
            status
            message
            data {
                lead_id
                insurance_type
                name
                plate_number
                policy_number
                expiry_date
                make
                make_name
                model
                model_name
                version
                version_name
                mobile
                email
                engine_number
                chassis_number
                partner_id
                region
                mfg_year
                remaining_instalment
                remaining_amount
                payment_status
                vehicle_id
                vehicle_type_id
                effectivity_date
                expiry_date
                documents {
                    document_id
                    doc_path
                    status
                }
            }
        }
    }
`;

export const SEARCH_INSURANCE = () => gql`
    query searchInsurance($plate_number: String){
        searchInsurance(plate_number: $plate_number) {
            status
            message
            data {
                lead_id
            }
        }
    }
`;


export const GET_MMV_LIST = () => gql`
    query get_mmv($params: MMVListInput!) {
        get_mmv(params: $params) {
            status
            message
            data {
                make {
                    value
                    label
                }
                model {
                    value
                    label
                    make
                }
                version {
                    value
                    label
                    model
                    fuel_type
                    transmission
                    seating_capacity
                }
            }
        }
    }
`;

export const GET_REGION_LISTING = () => gql`
 query getRegionListing {
    getRegionListing {
        status
        message
        data {
            value
            label
        }
    }
}`;


export const CREATE_CLAIM = () => gql`
  mutation createClaim($claim_details: ClaimDetailsInput!, $vehicle_details: VehicleDetailsInput!, $customer_details: CustomerDetailsInput!, $documents: [DocumentInput!]){
    create_claim(claim_details: $claim_details, vehicle_details: $vehicle_details, customer_details: $customer_details, documents: $documents) {
        status
        message
        data {
            id
            customer_name
            plate_number
            mobile
            hash_id
        }
    }
}`;

export const GET_FORM_CONFIG = () => gql`
 query getFormConfig {
    formConfig {
        status
        message
        data {
            claimFormFields {
                id
                name
                label
                type
                keyboard_type
                is_required
                prefix
                is_show
                is_enable
                value
                validation {
                    regex
                    min_length
                    max_length
                }
                parent_depedencies
                child_depedencies
                error_msg
                options
                field_show
                data_type
            }
        }
    }
}`;


export const UPDATE_INSURANCE_CLAIM_PROGRESS = () => gql`
   query updateInsuranceClaimStatus($insurance_id: Float!, $status: Float!){
    updateInsuranceClaimStatus(insurance_id: $insurance_id, status: $status) {
        status
        message
    }
}`;


export const UPDATE_CLAIM = () => gql`
  mutation updateClaim($claim_details: ClaimDetailsInput!, $vehicle_details: VehicleDetailsInput!, $customer_details: CustomerDetailsInput!, $type: String){
    create_claim(claim_details: $claim_details, vehicle_details: $vehicle_details, customer_details: $customer_details, type: $type) {
        status
        message
        data {
            id
            customer_name
            plate_number
            mobile
        }
    }
}`;