import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import secureStorage from "../config/encrypt";
import { API_URL } from '../config/constant';

// const client = new ApolloClient({
//   uri: 'https://your-graphql-endpoint.com/graphql', // Replace with your GraphQL endpoint
//   cache: new InMemoryCache(),
// });



const authLink = setContext((_, { headers }) => {
    let authInfo: any = secureStorage.getItem('authUserInfo');

    const user_info: any = (authInfo);
    const token = user_info?.access_token;
    const user_data = user_info?.user_data;
    const isUserDataSet = secureStorage.getItem('isUserDataSet') || false;
    let headers2send = Object.assign({ api_source: 'claimbox' }, headers);
    if (token) {
        headers2send.Authorization = `${token}`;
        if (!isUserDataSet && user_data) {
            headers2send.user_data = JSON.stringify(user_data);
            secureStorage.setItem('isUserDataSet', true);
        }
    } else {
        headers2send.apikey = API_URL.API_KEY;
    }
    return {
        headers: headers2send
    }
});

const httpLink = createHttpLink({
    uri: `${API_URL.GETWAY_API}api/v1`,
    //uri: `http://localhost:4119/incentive/api/v1`,
    fetch: (uri, options) => {
        const timeout = 300000;        
        return new Promise((resolve, reject) => {
            const timer = setTimeout(() => {
                reject(new Error('Request timeout'));
            }, timeout);
            fetch(uri, options)
                .then((response) => {
                    clearTimeout(timer);
                    resolve(response);
                })
                .catch((error) => {
                    clearTimeout(timer);
                    reject(error);
                });
        });
    },
});


const defaultOptions: any = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
});

export default client;