import React, { useEffect, useState } from 'react';
// import Modal from "@src/common/ModalPopup";
// import AonRate from "@src/component/rule-engine/update-rule/AonRate";
// import VatRate from "@src/component/rule-engine/update-rule/VatRate";
// import BaseIssueRate from "@src/component/rule-engine/update-rule/BaseIssueRate";
// import ImportModal from "@src/component/rule-engine/import/ImportModal";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
// import { fetchMasterRates, getMasterConfig } from '@src/store/slices/common';
import FullPageLoader from '../../view/common/FullPageLoader';
// import { getPartnerMappingList } from '@src/store/slices/partner';
import secureStorage from 'config/encrypt';
import * as HELPER from "@src/config/helper";
import { NavLink } from "react-router-dom";
import { ROLE_FEATURES } from '@src/config/constant';
// import { getSampleFile } from '@src/store/slices/insurance';
// import TraRate from "@src/component/rule-engine/update-rule/TraRate";
// import { ROLE_FEATURES } from '@src/config/constant';


const RuleEngine = () => {
    let [applicationTypes, setApplicationTypes] = useState<Array<{ value: number, label: string }>>()
    let [insuranceType, setInsuranceType] = useState<Array<{ value: number, label: string }>>()
    let [partnerList, setPartnerList] = useState<Array<{ value: number, label: string }>>()
    let [ruleEngine, setRuleEngine] = useState<Array<object>>()
    let [agencyPartnerList, setAgencyPartnerList] = useState<Array<{ value: number, label: string }>>()

    let authUserInfo = secureStorage.getItem('authUserInfo');
    let userId = authUserInfo && authUserInfo.user_data && authUserInfo.user_data.id

    let [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate();
    const dispatch: any = useDispatch()
    const commonState = useSelector((state: any) => state.common)
    const insuranceState = useSelector((state: any) => state.insurance)

    const modalSwitch = (param: string, cases: { [key: string]: any }) => {
        if (cases[param]) {
            return cases[param]
        } else {
            return cases.default
        }
    }

    const [updateFormType, setUpdateFormType] = useState("");
    const [showFormName, setShowFormName] = useState("");
    const [updateModal, setUpdateModal] = useState(false);


    const showUpdateModal = (updateFormType: string, action: boolean, formName: string) => {
        setUpdateModal(action);
        setUpdateFormType(updateFormType);
        setShowFormName(formName)
    }

    const handlerLoader = (loader: boolean) => {
        setLoading(loader)
    }

    const handleModalCloseFromChild = () => {
        showUpdateModal("", false, '')
    }

    useEffect(() => {
        // setLoading(true)

        let filters = {
            page_number: 1,
            pagination: false,
            namesOnly: true
        }


    }, [dispatch])


console.log(HELPER.isUserHasAccessPage({ permissionID: "", accessType: 'isVisible' }))
    return (
        <React.Fragment>

            <FullPageLoader show={loading} />

            <div className="container-fluid">
                <div className="top-heading">
                    <h1>Rule Engine</h1>
                </div>
                <div className="rule-engine-menu-sec">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="menu-list">
                                {
                                    HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['email_template_management'], accessType: 'isVisible' }) || HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'isVisible' }) || HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['region_management'], accessType: 'isVisible' })  ?
                                    <div>
                                        <div className="menu-icn">
                                            <i className="ic-configuration"></i>
                                        </div>
                                        <h2>Configuration</h2>
                                    </div>
                                    : ''
                                }
                                <ul>
                                    {
                                        (HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['email_template_management'], accessType: 'isVisible' }) || HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'isVisible' })) ?
                                        <li>
                                            {/* <a href="#!" onClick={() => showCkEditorModal('ckEdit', true, "Email/Whats app/Viber templates - Sent to insurance broker", '1')}>Email/Whats app/Viber templates - Sent to insurance broker</a> */}
                                            <NavLink to="/email-template-config"> Email/Whats app templates Configuration</NavLink>
                                        </li>
                                        : ''
                                    }


                                    {
                                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'isVisible' }) ?
                                        <li>
                                            <a href="#!" onClick={(() => navigate('/user-list'))}>User/Role Management</a>
                                        </li>
                                        : ''
                                    }
                                    {
                                        HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['region_management'], accessType: 'isVisible' }) ?
                                        <li>
                                            <a href="#!" onClick={(() => navigate('/repair-shop-list'))}>Repair Shop Management</a>
                                        </li>
                                        : ''
                                    }

                                </ul>

                            </div>
                        </div>
                        {
                            // HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['import_module'], accessType: 'isVisible' }) ?
                            // <div className="col-md-3">
                            //     <div className="menu-list">
                            //         <div className="menu-icn">
                            //             <i className="ic-import"></i>
                            //         </div>
                            //         <h2>Import</h2>


                            //     </div>
                            // </div>
                            // : ''
                        }


                    </div>
                </div>



            </div>
        </React.Fragment >
    )

}

export default RuleEngine;