import { executeGraphQLQuery } from '@src/common/executeGraphQLQuery';
import client from '../apolloClient';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CUSTOMER_PENDING_DOCUMENTS, CUSTOMER_TEMPLATE_FIELD_INFO, SAVE_CUSTOMER_TEMPLATE_DATA, TEMPLATE_FIELD_INFO } from "@src/services/templatedata.gql";
import { UPDATE_CLAIM } from '@src/services/masterdata.gql';


const template = createSlice({
    name: 'template',
    initialState: {
        templateConfig: [],
        partnerConfigTemplate: []
    } as any,
    reducers: {
        setTemplateConfig: (state, action: PayloadAction<any>) => {
            state.templateConfig = action.payload
        },
        setPartnerTemplateConfig: (state, action: PayloadAction<any>) => {
            state.partnerConfigTemplate = action.payload
        }

    },
});

export default template.reducer;

const { setTemplateConfig, setPartnerTemplateConfig} = template.actions



// export const customerTemplate = (variables: any) => async (dispatch: any) => {
//     return new Promise<any>((resolve, reject) => {

//         try {
//             executeGraphQLQuery(CUSTOMER_TEMPLATE_FIELD_INFO(), client, variables).then((response: any) => {
//                 if (response && response.data && response.data.customerTemplateFieldinfo) {
//                     let result = response.data.customerTemplateFieldinfo
//                     dispatch(setTemplateConfig(response?.data?.customerTemplateFieldinfo?.data))
//                     return resolve(result)
//                 } else {
//                     return resolve(response)
//                 }
//             }).catch((err: any) => {
//                 return resolve(err)
//             })
//         } catch (error) {
//             return resolve({})
//         }

//     })
// }

export const saveCustomerTemplateData = (variables: any) => {
    return new Promise((resolve, reject) => {
        try {
            executeGraphQLQuery(SAVE_CUSTOMER_TEMPLATE_DATA(), client, variables).then((response: any) => {
                if (response && response.data && response.data.saveCustomerTemplateData) {
                    let result = response.data.saveCustomerTemplateData
                    return resolve(result)
                } else {
                    return resolve(response)
                }
            }).catch((err: any) => {
                return resolve(err)
            })
        } catch (error) {
            return resolve({})
        }
    })
}

export const getRequestedDocuments = (variables: any) => {
    return new Promise((resolve, reject) => {
        try {
            executeGraphQLQuery(CUSTOMER_PENDING_DOCUMENTS(), client, variables).then((response: any) => {
                if (response && response.data && response.data.getRequestedDocuments) {
                    let result = response.data.getRequestedDocuments
                    return resolve(result)
                } else {
                    return resolve(response)
                }
            }).catch((err: any) => {
                return resolve(err)
            })
        } catch (error) {
            return resolve({})
        }
    })
}


// TEMPLATE FIELD INFO
export const templateFieldInfo = (variables: any) => async (dispatch: any) => {
    return new Promise<any>((resolve, reject) => {

        try {
            executeGraphQLQuery(TEMPLATE_FIELD_INFO(), client, variables).then((response: any) => {
                if (response && response.data && response.data.templateFieldsinfo) {
                    let result = response.data.templateFieldsinfo
                    dispatch(setPartnerTemplateConfig(response?.data?.templateFieldsinfo?.data))
                    return resolve(result)
                } else {
                    return resolve(response)
                }
            }).catch((err: any) => {
                return resolve(err)
            })
        } catch (error) {
            return resolve({})
        }

    })
}


// TEMPLATE FIELD INFO
export const updateTemplateValue = (variables: any) => async (dispatch: any) => {
    dispatch(setPartnerTemplateConfig(variables))
}
