import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './slices/auth'; 
import UserSlice from './slices/user'; 
import masterDataReducer from './reducers/masterDataReducer'
import roleUserSlice from './slices/RoleUserSlice';

import { loadStateFromLocalStorage, saveStateToLocalStorage } from './localStorage';
import common from './slices/common';
import claim from './slices/claim';
import { createLogger } from 'redux-logger';
import { applyMiddleware, combineReducers, compose } from 'redux';
import {thunk} from 'redux-thunk';
import template from './slices/template';

// const persistedState = loadStateFromLocalStorage();


const loggerMiddleware = createLogger();

const appReducer = combineReducers({ user: UserSlice,
  // masterdata: masterDataReducer
  roleUser: roleUserSlice,
  common,
  claim,
  template
 });


let middleware = [];
if (process.env.NODE_ENV === 'dev') { //set development to show errors
    middleware = [...middleware, thunk, loggerMiddleware];
} else {
    middleware = [...middleware, thunk];
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore(
    { reducer: appReducer },
    composeEnhancer(applyMiddleware(...middleware)),
);

// const store = configureStore({
//   reducer: {
//     user: UserSlice, 
//     masterdata: masterDataReducer,
//     roleUser: RoleUserSlice,
//     common: CommonSlice,
//     claim: ClaimSlice
//   },
//   // preloadedState: persistedState,
// });

// store.subscribe(() => {
//   saveStateToLocalStorage(store.getState());
// });



export default store;