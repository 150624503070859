/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../common/ToggleSwitch";
import MultiSelect from  "../../../common/MultiSelect";//"../../common/MultiSelect";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
// import { getRoleListing } from '../../store/slices/RoleUser';
import ReactSelect from "@common/ReactSelect";
import { getMasterConfig } from "@store/slices/common";
// import * as HELPER from '../../config/helper';
import { ROLE_FEATURES } from '@src/config/constant';
import { saveUserData } from "@store/slices/UserSlice";
import { getUserListing } from '../../../store/slices/UserSlice';
import * as HELPER from '@src/config/helper';

function UserListPopup(props: any) {
    const dispatch: any = useDispatch();
    let { showUserPopup, userEditPopup, role_list, userDetails } = props;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    let [userList, setUserList] = useState<any>([]);

    let [assigned_feature_list, setAssignedFeatureList] = useState<any>([]);
    let [additional_feature_list, setAdditionalFeatureList] = useState<any>([]);
    let [formData, setFormData] = useState<any>({ ...userDetails || {} });
    let [errors, setErrors] = useState<any>({});
    let [changeRole , setChangeRole] = useState<any>([])
    const mobile_regex = /^[0-9]{9,12}$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    
    const toggleDropdown = () => {
        
        setIsDropdownOpen(!isDropdownOpen);
    };
    const commonState = useSelector((state: any) => state.common);
    useEffect(() => {
        if (Object.keys(commonState.common).length === 0) {
            dispatch(getMasterConfig());
        }
        // eslint-disable-next-line
    }, []);

    let vertical_list = commonState?.common?.vertical_list??[];


    const handleChange = async (key: any, event: any) => {
        let value: any = '';
        let target = event.target;
        if (key === 'status') {
            value = formData.status === "1" ? "0" : "1";
        }
        else if (event instanceof Array) value = event.map((e: any) => e.value);
        else if (target) value = target.value;
        else if (event) value = event.value;
        if (key === 'reportee_role_id') formData.reportee_id = ''
        formData[key] = value;
        errors[key] = '';

        setErrors({ ...errors });
        setAdditionalFeatureList([...additional_feature_list]);
        setFormData({ ...formData });
        if (key === 'assigned_role') {
            if (!changeRole.includes(event)) {        
                setChangeRole([...changeRole, event]);
            }
        }
    }

    const isFormValid = async (formData: any) => {
        let isValid = true;
        if (!formData.name || formData.name === '') {
            errors['name'] = "This Field is Required";
            isValid = false;
        } else if (formData && formData.name && (formData.name.split('').length < 3 || formData.name.split('').length > 30)) {
            errors['name'] = "User Name should be between 3 and 30";
            isValid = false;
        }
        if (!formData.mobile || formData.mobile === '') {
            errors['mobile'] = "Mobile No is required";
            isValid = false;
        } else if (!mobile_regex.test(formData.mobile)) {
            errors['mobile'] = 'Invalid mobile no.';
            isValid = false;
        }
        if (!formData.email || formData.email === '') {
            errors['email'] = "Email is required";
            isValid = false;
        } else if (!email_regex.test(formData.email)) {
            errors['email'] = "Email is not valid";
            isValid = false;
        }
        if (formData.password && !password_regex.test(formData.password)) {
            errors['password'] = 'Password should be atleast 8 character with 1 special char,1 Uppercase,1 lowercase and 1 digit';
            isValid = false;
        }
        if (!formData.reportee_id || formData.reportee_id === '') {
            errors['reportee_id'] = "Reporting user is required";
            isValid = false;
        }
        setErrors({ ...errors });
        return isValid;

    }


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let formData = await getFormData();
        //In assigned Role to check any changes make or not 
        let userDetailsArray = props?.userDetails?.assigned_role_ids?.split(',').map(Number);
        let isMatched = userDetailsArray?.every((value: any) => formData?.assigned_role?.includes(value)) &&
            formData?.assigned_role?.every((value:any) => userDetailsArray.includes(value));
        // match name and status is match or not with updated value
        const isNameStatusMatch = userDetails?.name === formData?.name && userDetails?.status === formData?.status;
        
        if (await isFormValid(formData)) {
            props.setLoading(true);
            // if role change then it add in payload
            if (changeRole && changeRole.length > 0 && isMatched === false) {
                formData.changeRole = changeRole
            }
            if (isNameStatusMatch === false) {
                formData.changeValue = true;
            }
           
            formData.reportee_id = Number(formData.reportee_id);
            formData.reportee_role_id = Number(formData.reportee_role_id);
            formData.status = Number(formData.status);

           // dispatch(saveUserData(formData)).then((res: any) => {
            saveUserData({requestData: {...formData}}).then((res: any) => { 
            props.setLoading(false);
                if (res && res.status === 200) {
                    //toast.success(res.message);
                    setFormData({});
                    props.userEditPopup(false, {});
                    props.getUserManageListDetails({});
                }
            }).catch((err: Error) => {
                props.setLoading(false);
            })
        }
     props.setSearchText('');
    }

    const getUserList = async () => {
        //dispatch(getRoleListing.getUserManagementList({ isPaginationApply: false }))
        dispatch(getUserListing({ 'filter_list': { isPaginationApply: false } }))
        .then((res: any) => {
            if (res && res.status === 200) {
                let userList = [];
                userList = (res.data && res.data.length && formData) ?
                    res.data.map((e: any) => {
                        let isChecked = formData.reportee_id ? formData.reportee_id === e.id : false;
                        return {
                            value: e.id,
                            label: e.name,
                            isChecked: isChecked,
                            assigned_role: e.assigned_role_ids ? e.assigned_role_ids.split(',') : []
                        }
                    }) : [];
                let assignedRole = [];
                if (formData.assigned_role_ids) {
                    assignedRole = formData.assigned_role_ids.split(',');
                    assignedRole = assignedRole.map((id: any) => Number(id));
                }
                formData.vertical_id = 1;
                formData.assigned_role = assignedRole;
                setFormData({ ...formData });
                setUserList([...userList]);
            }
        })
    };

    const getFormData = async () => {
        let postData: any = {};
        if (formData && formData.id) postData['id'] = formData.id;
        if (formData && formData.name) postData['name'] = formData.name?.replace(/\s+/g,' ').trim();
        if (formData && formData.email) postData['email'] = formData.email?.replace(/\s+/g,' ').trim();
        if (formData && formData.password) postData['password'] = formData.password ;
        if (formData && formData.mobile) postData['mobile'] = formData.mobile;
        if (formData && formData.assigned_role) postData['assigned_role'] = formData.assigned_role;
        if (formData && formData.status) postData['status'] = formData.status;
        if (formData && formData.reportee_id) postData['reportee_id'] = formData.reportee_id;
        if (formData && formData.reportee_role_id) postData['reportee_role_id'] = formData.reportee_role_id;
        if (formData && formData.vertical_id) postData['vertical_id'] = formData.vertical_id;
     
        return postData;
    }


    useEffect(() => {
       getAsyncData();
        // eslint-disable-next-line
    }, [])

    const getAsyncData = async () => {
        await getUserList();
        if (userDetails && userDetails.assigned_feature_names) {
            let assignedFeatures = userDetails.assigned_feature_names.split(",");
            setAssignedFeatureList([...assignedFeatures]);
        }
    }
    let user_list : any = [];

    if (formData && formData.reportee_role_id) {
        user_list = userList.filter((e: any) => {
            return e.assigned_role?.includes(String(formData.reportee_role_id));
        })
    }
    if (userDetails && userDetails.id && user_list?.length) {
        user_list = user_list.filter((e: any) => e.value !== userDetails.id);
    }
    
    return (
        <React.Fragment>
            {
              showUserPopup?
                <div className="edit-role-outer user-list-outer edit-user-popup-bx">
                    <div className="edit-role-main">
                        <h2>Edit User</h2>
                        <a className="close-icn"  onClick={() => userEditPopup(false)}><i className="ic-clearclose"></i></a>
                      
                        <div className="row">
                            <div className=" role-status-outer w-100">
                                <div className="col-md-6">
                                    <fieldset className="form-filed">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="UserName" className="form-input" onChange={(e: any) => handleChange('name', e)} value={formData.name || ''} />
                                            <label data-label="User Name" className="form-label"></label>
                                            <span className="error-txt">
                                                {errors.name || ""}
                                            </span>
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-filed">
                                        <div className="material status-btn" onClick={(e) => handleChange('status', e)}>
                                            <ToggleSwitch checked={String(formData.status) === "1" ? true : false} />
                                            <div className="status-txt">{String(formData.status) === "1" ? "Active" : "Inactive"}</div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                                <div className="col-md-6">
                                    <fieldset className="">
                                        <div className="material">
                                            <input id="mobileNo" type="text" placeholder=" " name="MobileNumber" className="form-input" value={formData.mobile || ''} disabled={true} />
                                            <label data-label="Mobile Number" className="form-label"></label>
                                            <span className="error-txt">
                                                {errors.mobile || ""}
                                            </span>
                                        </div>
                                    </fieldset>

                                    <fieldset className="form-filed password-txt-msg">
                                        <div className="material">
                                            <input id="password" type="text" placeholder=" " name="password" className="form-input" onChange={(e: any) => handleChange('password', e)}  />
                                            <label data-label={"Password"} className="form-label"></label>
                                            <span className="error-txt">
                                                {errors.password || ""}
                                            </span>
                                        </div>
                                    </fieldset>

                                    <ReactSelect 
                                        options={user_list} 
                                        value={
                                                formData &&
                                                user_list.filter(
                                                    (v: any) =>
                                                        v.value === +formData.reportee_id
                                                )
                                            } 
                                        handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Reporting Name" fieldKey="reportee_id" key="reportee_id" errorField={errors.reportee_id} 
                                        />
                                    
                                    <fieldset className="single-select">
                                        <div className="material">
                                            <MultiSelect
                                                id={"assigned_role"}
                                                value={(role_list instanceof Array &&
                                                    formData.assigned_role &&
                                                    role_list.filter((e) =>
                                                        [
                                                            ...(formData.assigned_role instanceof
                                                                Array
                                                                ? formData.assigned_role
                                                                : []),
                                                        ].includes(e.value)
                                                    )) || []}
                                                onChange={(e: any) => handleChange("assigned_role", e)}
                                                options={role_list}
                                                isSelectAll={true}
                                                placeholder={"Add Role"}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-filed">
                                        <div className="material">
                                            <input id="email" type="text" placeholder=" " name="email" className="form-input" value={formData.email || ''} disabled={true} />
                                            <label data-label="Email" className="form-label"></label>
                                            <span className="error-txt">
                                                {errors.email || ""}
                                            </span>
                                        </div>
                                    </fieldset>
                                    <div className="m-b-30">
                                    <ReactSelect options={role_list} value={
                                        formData &&
                                        role_list.filter(
                                            (v: any) =>
                                                v.value === Number(formData.reportee_role_id)
                                        )
                                    } handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)} placeholder="Reporting Role" fieldKey="reportee_role_id" key="reportee_role_id" errorField={errors.reportee_role_id} />
                                </div>
                                    {/* <fieldset className="multiselect-dropDown">
                                        <div className="material">
                                            <Select
                                                id="reportee_role_id"
                                                options={role_list}
                                                name="reportee_role_id"
                                                onChange={(e: any) => handleChange(
                                                    "reportee_role_id", e)}
                                                value={
                                                    formData &&
                                                    role_list.filter(
                                                        (v: any) =>
                                                            v.value === Number(formData.reportee_role_id)
                                                    ) || formData.reportee_name
                                                }
                                                placeholder={"Reporting Role"}
                                            />
                                        </div>
                                    </fieldset> */}

                                    <ReactSelect options={vertical_list}
                                        value={
                                            formData &&
                                            vertical_list.filter(
                                                ({ value }: any) =>
                                                    value === 1
                                            )
                                        }
                                        isDisabled={true}
                                         placeholder="Verticle" fieldKey="vertical_id" key="vertical_id" errorField={errors.reportee_id}
                                    />
                                </div>
                                {userDetails.assigned_role_name && <div className="col-md-12 feature-assign-heading" onClick={toggleDropdown}>
                                    <span className={(isDropdownOpen)?"active":''}>Features assigned to {userDetails?.assigned_role_name || ''}</span>
                                </div>
                                }
                                {
                                isDropdownOpen && assigned_feature_list.length > 0 &&
                                    <div className="col-md-12 userList-feature-assign">
                                    <ul>
                                        {
                                           assigned_feature_list.map((item: any) => (
                                            <li>{item} 
                                            </li>
                                           ))  
                                        }
                                    </ul>
                                    </div>
                                }
                                
                            {
                                HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['user_role_management'], accessType: 'edit' }) &&
                                <div className="col-md-12">
                                    <button className="btn-primary m-md-t" onClick={(e: any) => handleSubmit(e)}>Save Changes</button>
                                </div>
                            }
                        </div>
                        
                    </div>
                </div>
                :null
            }
        </React.Fragment>
    )
}
export default UserListPopup;