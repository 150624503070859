import { gql } from '@apollo/client';

export const GET_PARTNER_LIST = () => {
    return gql`query getRepairShopConfigList($filter_list: FilterInput!){ 
        getRepairShopConfigList(filter_list:$filter_list){
            data {
                id
                name
                hashId
                active
                in_active
            }
            status
            message
            pagination{
                total
                pages
                limit
                cur_page
                prev_page
                next_page
            }
        }
    }`
}
export const GET_PARTNER_SHOP_LIST = () => {
    return gql`query getPartnerRepairList($filter_list: FilterInput!){ 
        getPartnerRepairList(filter_list:$filter_list){
            data{
                partner_data{
                id 
                name
                }
                shop_list{
                    id
                    name
                    region 
                    status
                }
            }
            status
            message
            pagination{
                total
                pages
                limit
                cur_page
                prev_page
                next_page
            }
        }
    }`
}

export const UPDATE_SHOP_STATUS = () => {
    return gql`mutation repairShopUpdate($repairInput: RepairInput!) {
        repairShopUpdate(repairInput: $repairInput) {
               message
               status
               data{
                   status
               }
       }
    }`
}

export const ADD_REPAIR_SHOP = gql`mutation addRepairShop($saveRepairInput: SaveRepairInput!){ 
    addRepairShop(saveRepairInput:$saveRepairInput){
        status
        message
    }
}`

export const GET_CSV_UPLOAD = () => {
    return gql
    ` mutation ($file: Upload!, $user_id: Float!, $email: String!) {
        csv_upload(csvInput: {images: [$file], user_id: $user_id, email: $email}) {
            status
            message
        }
    }`
}

    
    