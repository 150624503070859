import { useEffect, useState } from "react";
import Modal from "../../../common/ModalPopup";
import { useApolloClient } from "@apollo/client";
import FullPageLoader from "../../../view/common/FullPageLoader";
import { createClaim, getFormConfig, getInsuranceForClaim, getInsuranceForClaimById, getMMVList, getRegionListing } from "../../../store/action/allAction";
import { getMasterConfig } from "@store/slices/common";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DynamicFormFields from "./DynamicFormFields";
import { times } from "lodash";
import { setClaimInsuranceData } from "@src/store/slices/claim";
import AddApplicationPopup from "@src/common/AddApplicationPopup";
import { createClaimFields, validateInputFields } from "@src/common/FunctionList";
import React from "react";
import { addCurrencyFormatting } from "@src/config/helper";


export default function AddClaimApplication({ showAddApplicationPopup, hideAddApplicationPopup, getClaimList }: any) {
    const [loading, setLoading] = useState(false)
    const [formFields, setFormFields] = useState<any>([])
    const [masterData, setMasterData] = useState<any>({})
    const [refresh, setRefresh] = useState<boolean>(false)
    const [popupFields, setPopupFields] = useState({})
    const [showApplicationPopup, setShowApplicationPopup] = useState(false)

    const client = useApolloClient();
    const dispatch: any = useDispatch()

    const commonState = useSelector((state: any) => state?.common)
    const claimState = useSelector((state: any) => state.claim)

    const fetchInsuranceForClaim = async () => {
        setLoading(true)
        await dispatch(getInsuranceForClaim(client))
    }

    const fetchInsuranceForClaimById = async (params: any) => {
        setLoading(true)
        const data: any = await dispatch(getInsuranceForClaimById(client, params))

        //case when searchInsurance API is called
        if (params.plate_number && data?.message) {
            if (data.message !== 'Success' && data?.data?.lead_id !== params?.id) {
                toast.error(data.message)
            }
        }
        setLoading(false)
    }

    const fetchMMVList: any = async (params: any) => {
        const mmvList: any = await dispatch(getMMVList(client, { params: { ...params } }))
        if (mmvList && mmvList?.status === 200) {
            setMasterData((prev: any) => ({ ...prev, make: mmvList?.data?.make }))
        }

        return mmvList?.data
    }

    const fetchMasterData = async () => {
        let data: any = null
        if (commonState?.common && Object.keys(commonState.common?.length || {})) {
            data = commonState.common
        } else {
            data = await dispatch(getMasterConfig())
        }

        setMasterData(data)
    }

    const getRegionList = async () => {
        const result: any = await getRegionListing(client)
        if (result?.data?.length) {
            setMasterData((prev: any) => ({ ...prev, region_list: result.data }))
        }
    }

    const fetchFormConfig = async () => {
        let result: any = null
        if (!formFields.length) {
            result = await getFormConfig(client)
            setFormFields(result?.data?.claimFormFields || [])
        } else {
            result = formFields
            updateFormFields(result)
        }
    }

    const updateFormFields = (fields: any) => {
        let mappedFields: any = {
            vehicle_type_id: 'vehicle_type',
            partner_id: 'partner_list',
            region: 'region_list'
        }

        for (let field of fields) {
            let fieldName: any = mappedFields?.[field.name] || field.name

            if (fieldName === 'insurance_id' && claimState?.insuranceLeads?.length) {
                field['options'] = claimState?.insuranceLeads
            }

            else if (masterData?.[fieldName]) {
                field['options'] = masterData[fieldName]
            }
        }

        setFormFields([...fields])
        setRefresh(!refresh)
    }

    const validateInputField = () => {
        let error = false
        error = validateInputFields(formFields)

        setRefresh(!refresh)
        return error
    }

    const handleSubmit = async () => {
        setLoading(true)

        const errorExist = validateInputField()
        if (errorExist) {
            setLoading(false)
            return
        }

        let { claimDetails, customerDetails, vehicleDetails } = createClaimFields(formFields)
        let createFields: any = {
            claim_details: claimDetails,
            vehicle_details: vehicleDetails,
            customer_details: customerDetails,
            documents: claimState?.insuranceData?.documents || []
        }

        const result: any = await createClaim(client, createFields)
        if (result?.data?.create_claim?.data?.id) {
            let { data } = result?.data?.create_claim
            setPopupFields({ ...data })
            setShowApplicationPopup(true)
            dispatch(setClaimInsuranceData({}))
            getClaimList({ page_number: 1, searchKeyword: '' }, 'reset')
        } else {
            toast.error('Error in Creating Application')
        }
        setLoading(false)
    }

    useEffect(() => {
        try {
            dispatch(setClaimInsuranceData({}))
            fetchMasterData()
            fetchInsuranceForClaim()
            fetchMMVList()
            getRegionList()
        } finally {
            setLoading(false)
        }

        return (() => {
            dispatch(setClaimInsuranceData({}))
        })
    }, [])

    useEffect(() => {
        if (masterData && Object.keys(masterData)?.length) {
            fetchFormConfig()
        }
    }, [masterData])

    useEffect(() => {
        if (claimState?.insuranceLeads?.length) {
            updateFormFields(formFields)
        }
    }, [claimState?.insuranceLeads])
    return (
        <>
            <FullPageLoader show={loading} />
            <div className="model-outer add-application-popup">
                <Modal show={showAddApplicationPopup} handleClose={hideAddApplicationPopup} >
                    <div className="modal-headerhideAddApplicationPopup">
                        <h2>Add Claim Application</h2>
                    </div>
                    <div className="modal-body" >
                        <div className='row'>
                            <DynamicFormFields
                                fields={formFields}
                                callFrom={'add_claim'}
                                fetchInsuranceForClaimById={fetchInsuranceForClaimById}
                                fetchMMV={fetchMMVList}
                            />
                        </div>
                        {
                            claimState?.insuranceData && Object.keys(claimState?.insuranceData || {}).length
                                ? <div className="info-bar">
                                    {
                                        times(5, (idx: any) => {
                                            let keys = ['lead_id', 'payment_status', 'remaining_instalment', 'remaining_amount', 'status_name']
                                            let keyName = ['Insurance ID', 'Payment', 'Remaining Instalment', 'Remaining Amount', 'Status']
                                            return (
                                                <React.Fragment key={`${keys[idx]}_${idx}`}>
                                                    {

                                                        (idx === 4 ? true : claimState?.insuranceData?.[keys[idx]]) !== null
                                                            ? <span className="info-bar-span" key={idx + "_" + keyName[idx]}>
                                                                {
                                                                    keyName[idx] + ":" + " " + (idx === 4 ? "Policy Shared" : (keys[idx] === "remaining_amount" ? addCurrencyFormatting(claimState?.insuranceData?.[keys[idx]]) : claimState?.insuranceData?.[keys[idx]]))
                                                                }
                                                            </span>
                                                            : undefined
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                                : undefined
                        }
                    </div>
                    <div className='modal-footer'>
                        <div className="btn-continue"><button type="button" className="btn-primary" onClick={handleSubmit} disabled={!formFields?.length}>Add Application</button></div>
                    </div>
                </Modal>
            </div>
            {
                showApplicationPopup && <AddApplicationPopup show={showAddApplicationPopup} handleClose={() => {
                    setShowApplicationPopup(false)
                    hideAddApplicationPopup()
                }} fields={popupFields} />
            }
        </>
    )
}