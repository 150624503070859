import { getDocumentNotification } from "@src/store/slices/claim";
import client from "@src/store/apolloClient";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function DocNotification(props: any) {
    const [notificationData, setNotificationData] = useState<Array<any>>([])

    const navigate = useNavigate()

    const handleClick = (item: any) => {
        let type = item?.type === 2 ? 'claim-closing' : 'document-upload'
        let documentDetailPage = `/claim-details/${item.claim_id}?type=${type}`
        navigate(documentDetailPage)
    }

    const fetchDocumentNotification = async () => {
        const result: any = await getDocumentNotification(client, {})
        if (result?.data?.length) {
            setNotificationData(result.data)
        }
    }

    useEffect(() => {
        fetchDocumentNotification() //eslint-disable-next-line
    }, [])

    return (
        notificationData?.length
            ?
            <ul className={`notification-hisorty-list ${props.callFrom !== 'dashboard' ? 'notification-history-claimlist' : ''}`}>
                {
                    notificationData?.map((item: any, idx: number) => {
                        return (
                            <li key={item.claim_id + "_" + idx} onClick={() => handleClick(item)}>
                                <h3>{"Document Received " + "-" + " ID" + " " + item.claim}</h3>
                                <span className="notification-msg">
                                    {item.description}
                                </span>
                                <span className="time-date">
                                    {item.created_time}
                                </span>
                            </li>
                        )
                    })
                }
            </ul>
            : null
    )
}