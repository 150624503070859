import { useEffect, useState } from 'react';
import { ClaimsClosingDate } from './DatePicker';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { SAVE_CNC_TRANSFER_PROOF } from '@src/services/claimdata.gql';
import { print } from 'graphql'
import { executeUpload } from '@src/common/executeGraphQLQuery';
import { fetchClaimProcessingData, updateClaimDetailsInStore, updateClaimHistory, updateClaimRibbonData } from '@src/store/slices/claim';
import FullPageLoader from '@src/view/common/FullPageLoader'
import { useDispatch, useSelector } from 'react-redux';
import * as HELPER from '@src/config/helper';
import { CNC_RECEIVED, ROLE_FEATURES, TRANSFER_PROOF } from '@src/config/constant'
import ResubmissionModal from '@common/ResubmissionModal';

const TransferProof = (props: any) => {
    const [formData, setFormData] = useState<any>({
        doc_id: [],
        remarks: '',
        carRelease: null,
        date: null,
        images: [],
        disabled: false
    });
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const [errors, setError] = useState<any>({});
    const [claimData, setClaimData] = useState<any>();
    const dispatch: any = useDispatch()
    const [sliderImgs, setSliderImgs] = useState<any>([])
    const [activeSliderImg, setActiveSliderImg] = useState<any>(0)
    const [showResubmissionModal, setShowResubmissionModal] = useState(false)

    const claimState = useSelector((state: any) => state.claim)

    // claim id
    const { id } = useParams();

    // Function to save transfer proof & CNC-recieve data base on there type
    const saveTransferProof = async () => {

        if (isFormValid(formData)) {
            setLoading(true);
            // Immediately disable fields after submitting
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                disabled: true,
            }));
            setButtonDisabled(true);
            const formReq = new FormData();

            let filesArray: any = formData.images;
            // Construct GraphQL operations and map for file uploads
            const operations = JSON.stringify({
                query: print(SAVE_CNC_TRANSFER_PROOF()),
                variables: {

                    claim_input: {
                        claim_id: id,
                        remarks: formData.remarks,
                        car_released_cust: formData.carRelease,
                        claim_closing_date: formData.date,
                        type: props?.type,
                        images: filesArray.map(() => null),
                    }
                }
            });

            const map: any = {};
            filesArray.forEach((_: any, index: any) => {
                map[index] = [`variables.claim_input.images.${index}`];
            });

            formReq.append('operations', operations);
            formReq.append('map', JSON.stringify(map));

            filesArray.forEach((file: any, index: any) => {
                formReq.append(index.toString(), file);
            });

            try {
                // Execute file upload and save data
                const result = await executeUpload(formReq)
                let { status, data, status_id } = result?.data?.saveTransferCncProof
                setLoading(false);
                if (status && +status === 200) {
                    if (props?.type === 1) {
                        toast.success('CNC-Received details saved successfully')
                    }
                    else if (props?.type === 2) {
                        toast.success('Transfer proof details saved successfully')
                    }

                    //update claim status in ribbon
                    let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
                        if (e.key === 'status') {
                            return { ...e, value: '' + status_id }
                        }
                        return e
                    })
                    dispatch(updateClaimRibbonData(updatedRibbonData))
                    dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, status_id: status_id }))

                    let updatedHistory = claimState?.claimHistory?.includes(status_id) ? [] : [...claimState?.claimHistory, status_id]

                    if (updatedHistory.length) dispatch(updateClaimHistory(updatedHistory)) //update claim status history

                    setFormData({
                        ...formData,
                        remarks: data.remarks,
                        carRelease: data.car_released_cust,
                        date: new Date(parseInt(data.claim_closing_date)),
                        images: data.document.map((doc: any) => ({
                            url: doc.file_url,
                            file_name: doc?.file_name
                        })),
                        disabled: true,
                    });
                    // setButtonDisabled(true);
                    setLoading(false);

                } else {
                    setLoading(false);
                }
            } catch {

            }
        }
    }

    const handleChange = async (event: any, key: string) => {
        formData[key] = event.target.value;
        errors[key] = '';
        setError({ ...errors });
        setFormData({ ...formData });

    }

    // Handle radio button value change
    const handleRadioChange = (event: any) => {
        const value = event.target.value === "1" ? 1 : 0; // Yes = 1, No = 0
        setFormData({ ...formData, carRelease: value });
        errors['carRelease'] = '';
        setError({ ...errors });
    }

    // Handle date selection
    const handleDateChange = (date: any) => {
        setFormData({ ...formData, date });
        setError({ ...errors, date: '' });
    }

    // Handle file uploads with validation
    const handleFileUpload = async (event: any) => {

        let validateFile = await HELPER.validateFileInput(event.target.files)

        if (validateFile) {
            event.target.value = null
            return
        }

        const files = Array.from(event.target.files);
        // Combine existing and newly selected files
        const combinedFiles = [...formData.images, ...files];
        if (combinedFiles.length > 5) {
            // Show error if more than 5 files are selected
            setError({ ...errors, images: 'Maximum 5 images are allowed' });
            event.target.value = null;
        } else {
            setFormData({ ...formData, images: combinedFiles });
            setError({ ...errors, images: '' });
        }
    };

    const removeImage = (index: number) => {
        const updatedImages = formData.images.filter((_: any, imgIndex: number) => imgIndex !== index);
        setFormData({ ...formData, images: updatedImages });
    };

    // Fetch initial data on component mount
    useEffect(() => {
        fetchClaimProcessingData({ type: 'transferProof', variables: { claim_id: id, api_type: "transferProof", type: props?.type } }).then((res: any) => {
            if (res?.data) {
                const { claim_closing_date, car_released_cust, remarks, documents } = res.data;
                setFormData({
                    ...formData,
                    remarks: remarks,
                    carRelease: car_released_cust,
                    date: new Date(claim_closing_date),
                    images: documents.map((doc: any) => ({
                        url: doc.doc_path,
                        file_name: doc.id
                    })),
                    disabled: true,
                });
                setButtonDisabled(true);
            }
            else {
                // Reset the form values if no data is found
                setFormData({
                    remarks: '',
                    carRelease: null,
                    date: null,
                    images: [],
                    disabled: false,
                });
                setButtonDisabled(false);
            }

        })
    }, [props?.type]);


    const isFormValid = (formData: any) => {
        let isValid = true;
        if (!formData?.date || formData.date === '') {
            errors['date'] = props.name === "CNC-Received" ? 'CNC-Received date is required' : 'Claim closing date is required';
            isValid = false;
        }
        if (!formData.images || formData.images.length === 0) {
            errors['images'] = `${props.name} Images is required`;
            isValid = false;
        }
        if (props.type === 2 && (formData.carRelease === null)) {
            errors['carRelease'] = 'Car release status is required';
            isValid = false;
        }

        setError({ ...errors });
        return isValid;

    }

    // Fetch claim details if not present in the store
    useEffect(() => {
        if (claimState?.claimDetails?.partner_hash_id) {
            setClaimData(claimState?.claimDetails);
        } else {
            fetchClaimProcessingData({ type: "applicationDetails", variables: { claim_id: id, api_type: "applicationDetails" } }).then((res: any) => {
                if (res?.data) {
                    dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, ...res.data }));
                    setClaimData(res?.data)
                }

            })
        }
    }, [])


    // Control button enable/disable based on claim status
    useEffect(() => {
        if (claimState?.ribbonData?.length && claimState.claimDetails && Object.keys(claimState.claimDetails)?.length) {
            let checkButtonTypeStatus = props.type === 1 ? CNC_RECEIVED : TRANSFER_PROOF;
            if (claimState.claimDetails.status_id >= checkButtonTypeStatus) setButtonDisabled(true)
            else setButtonDisabled(false)
        }
    }, [claimState?.ribbonData, claimState?.claimDetails])


    const showImage=async(activeImgIdx:any, img:any)=>{
        // if(url){
        //     let fileName:any = url?.split("?")[0]
        //     fileName = fileName?.split("/")
        //     console.log("fileName", fileName)
        //     fileName = fileName?.[fileName?.length-1]?.split(".")?.[1] || ""
        //     if(fileName){

        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.target="_blank";
        //         link.download = fileName; 
        //         document.body.appendChild(link);
        //         link.click();
        //         document.body.removeChild(link);
        //     }
        // }
        if(img.url){

            
            setSliderImgs(formData?.images?.map((e: any) => {
                return { ...e, url: e.url || URL.createObjectURL(e) }
            }))
            setActiveSliderImg(activeImgIdx)
            setShowResubmissionModal(true)
        }
    }


    return (
        <div className="form-tab-right-panel">
            <FullPageLoader show={loading} />
            <div className="lead-detail-from-outer">
                <div className="lead-form-heading ">
                    <h2>{props?.name}</h2>

                </div>
                <div className="lead-form-filed p-lg-t">
                    <div className='row'>
                        <fieldset className="form-filed date-picker-detail col-md-6">
                            <div className={"material animation-effect" + (formData.date ? ' active-label' : '')}>
                                <ClaimsClosingDate onDateChange={handleDateChange}
                                    selectedDate={formData.date}
                                    disabled={formData.disabled}
                                    type={props?.type}
                                    evalDate={claimData?.evaldate}
                                    placeholder={(props.type === 2)?`Claims Closing Date*`:`${props.name}*`}
                                />
                                {errors && errors['date'] ? <span className="error-txt">{errors.date}</span> : null}
                            </div>
                        </fieldset>

                        {
                            (props.name === "Transfer Proof") &&

                            <fieldset className="from-group col-md-4 car-release-radio">
                                <label className="form-label">Car Release to Customer *</label>
                                <div className='radio-btn d-flex'>
                                    <div className="custom-control custom-checkbox">
                                        <input id="Individual" type="radio" className="custom-control-input " value="1" name="Buyer Type" onChange={handleRadioChange}
                                            checked={formData.carRelease === 1} disabled={formData.disabled}
                                        />
                                        <label className="custom-control-label">Yes </label>
                                    </div>
                                    <div className=" custom-control custom-checkbox m-lg-l">
                                        <input id="Company" type="radio" className="custom-control-input" name="Buyer Type" value="0" onChange={handleRadioChange}
                                            checked={formData.carRelease === 0} disabled={formData.disabled}
                                        />
                                        <label className="custom-control-label">No</label>
                                    </div>
                                </div>
                                {errors.carRelease && <span className="error-txt">{errors.carRelease}</span>}
                            </fieldset>
                        }

                    </div>


                    <div className='doc-upload-main m-sm-t'>
                        <span className='doc-heading'>
                            {props?.name}*
                            {errors.images && <span className="error-txt"> {errors.images} </span>}
                        </span>

                        <div className='upload-doc-img'>

                            {formData?.images?.length > 0 && (
                                formData?.images?.map((img: any, index: number) =>{
                                    let image = img.url || URL.createObjectURL(img);
                                    let splitName = img?.url?.split('?') || '';
                                    let splitPathName = splitName?.[0]?.split('.') || img?.name?.split('.');
                                    let fileType = (splitPathName[splitPathName.length - 1])?.toLowerCase();
                                    fileType = fileType === 'pdf' ? 'pdf' : (fileType === 'doc' || fileType === 'docx') ? fileType = 'word' : fileType

                                    return(
                                        <div key={index} className="img-bx-select-opt">
                                            <div className="image-bx" onClick={()=>{showImage(index, img)}}>
                                                {
                                                    fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' 
                                                    ?  
                                                        <img src={image} className="" alt="uploaded" />
                                                    :
                                                    <i className={`ic-${fileType}-file icons-normal`} style={{color:"#008CF0"}}></i>
                                                    // <a href={image} target="_blank" rel="noreferrer"></a>
                                                }

                                            </div>
                                            {(!formData.disabled && !isButtonDisabled) && (
                                                <div className="close-icn icon-top-right" onClick={() => removeImage(index)}>
                                                    <i className="ic-clearclose"></i>
                                                </div>
                                            )}
                                        </div>
                                    )

                                } )
                            )}

                            {/* Add more images */}
                            {
                                !isButtonDisabled &&
                                (formData?.images?.length < 5) &&
                                <div className="img-bx-select-opt">
                                    <div className="image-bx more-file-upload">
                                        <i className='ic-add'></i>
                                        <input type="file"
                                            onChange={handleFileUpload}
                                            multiple
                                            disabled={formData.disabled}
                                        />
                                        <p>Add Photo</p>
                                    </div>

                                </div>
                            }
                        </div>

                    </div>

                    {(props.name === "Transfer Proof") &&
                        <fieldset className="form-filed">
                            <div className="material">
                                <textarea className='form-input'
                                    placeholder=' '
                                    onChange={(e) => handleChange(e, "remarks")}
                                    value={formData?.remarks || ''}
                                    disabled={formData.disabled}
                                ></textarea>
                                <label data-label="Remarks" className="form-label"></label>
                            </div>
                        </fieldset>
                    }

                    <div className="btn-continue">
                        <button type="button" className="btn-primary" onClick={saveTransferProof}
                            disabled={isButtonDisabled || !HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: claimState?.claimDetails?.assign_user_id })}
                        >Save</button>
                    </div>
                </div>
            </div>

            {
                showResubmissionModal
                    ?
                    <ResubmissionModal showModal={showResubmissionModal} sliderImages={sliderImgs} activeImageIndex={activeSliderImg} closeResubmitModal={() => setShowResubmissionModal(false)}  />
                    : undefined
            }

        </div>

    )
}


export default TransferProof;