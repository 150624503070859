import { DOCUMENT_STATUS, ROLE_FEATURES } from '@src/config/constant';
import React, { Component, useEffect, useState } from 'react';
import { Button, Dropdown, Tab, Tabs, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MarkAsLostPopup from './MarkAsLostPopup';
import { useSearchParams, useParams } from 'react-router-dom';
import { MARK_AS_LOST, TRANSFER_PROOF } from '@src/config/constant'
import { fetchClaimProcessingData } from '@src/store/slices/claim';
import * as HELPER from '@src/config/helper';
import secureStorage from '@src/config/encrypt';


export default function ClaimRibbenBox(props: any) {
    const [ribbonData, setRibbonData] = useState<any>([])
    const commonState = useSelector((state: any) => state.common)
    const claimState = useSelector((state: any) => state.claim)
    const [markLostPopup, setMarkLostPopup] = useState(false);
    const [claimData, setClaimData] = useState<any>();

    let authUserInfo: any = secureStorage.getItem('authUserInfo');

    useEffect(() => {
        setRibbonData(claimState?.ribbonData)
    }, [claimState?.ribbonData])

    const showModalMarkAsLost = (set: boolean) => {
        setMarkLostPopup(set)
    }
    let statusId = MARK_AS_LOST;
    // claim id
    const { id } = useParams();

    // if claimDetails is not present in store then call api for fetch calim details
    useEffect(() => {
        //if(claimState?.claimDetails){
        if (claimState?.claimDetails && Object.keys(claimState.claimDetails).length > 0) {
            setClaimData(claimState?.claimDetails);
            //set data
        }
        // else{
        //     fetchClaimProcessingData(type: { variables: { claim_id: id, api_type: "applicationDetails" } }).then((res: any) => {
        //         if (res?.data) {
        //             setClaimData(res?.data)
        //            // setDocuments(res?.data?.documents);
        //         }

        //     })
        // }
    }, [claimState?.claimDetails, id])

    return (
        <>
            <div className='lead-top-heading'>
                <h1>Details: Claim ID {ribbonData?.[0]?.value}</h1>
                {
                    HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: authUserInfo?.user_data?.id}) &&
                    (claimData?.status_id !== MARK_AS_LOST && claimData?.status_id !== TRANSFER_PROOF) &&
                    <button className='mark-lost-btn' onClick={() => showModalMarkAsLost(true)}>
                        <i className='ic-clearclose'></i> Mark as Lost
                    </button>
                }

            </div>
            <div className="loan-bx-ribben">
                <ul className="maindetail-list clearfix ">
                    {
                        ribbonData?.length
                            ?
                            ribbonData.map((data: any, idx: number) => {
                                let value = data.value
                                //fetch status name
                                if (data.key === 'status') {
                                    value = (commonState?.common?.claim_status?.find((e: any) => e.value === +data.value)?.label) || null
                                }
                                //fetch partner name
                                if (data.key === 'partner_id') {
                                    value = (commonState?.common?.partner_list?.find((e: any) => e.value === +data.value)?.label) || null
                                }

                                if (data.key === 'doc_status') {
                                    value = DOCUMENT_STATUS[data.value as keyof typeof DOCUMENT_STATUS]
                                }

                                return (
                                    <li className="maindetail-item" key={data.key + "_" + idx}>
                                        <h3 className="subheading">
                                            {data.title}
                                        </h3>
                                        <div className="sub-value">
                                            <ul className="sub-value-list">
                                                <li title={value}>{value || '-'}
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                )
                            })
                            : undefined
                    }
                </ul>
            </div>

            {markLostPopup ?
                <MarkAsLostPopup
                    markLostPopup={markLostPopup}
                    showModalMarkAsLost={(e: boolean) => showModalMarkAsLost(e)}
                    statusId={statusId}
                    leadHashId={id}
                    setClaimData={setClaimData}
                />
                : ''
            }

        </>
    )
}