import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter, Route, NavLink, Router, useParams, useSearchParams } from 'react-router-dom';
import ClaimRibbenBox from './ClaimRibbenBox';
import ClaimDetailsForm from './ClaimDetailForm';
import DocumentsUpload from './DocumentsUpload'
import SendInsurance from '../../SendInsurance'
import LOAReceived from '../../LOAReceived'
import ClaimClosing from '../ClaimClosing'
import TransferProof from '../../TransferProof'
import { useSelector } from 'react-redux';
import { CLAIM_TYPE_CNC, DETAIL_NAV_MAPPING, STATUS_SUB_STATUS } from '@src/config/constant';
import EvaluationReceived from './EvaluationReceived'
import LoaReceived from './LoaReceived'

export default function ClaimDetailTabs(props: any) {
    const [subRoute, setSubRoute] = useState<any>()
    const [statusList, setStatusList] = useState<Array<any>>([])
    const [statusHistory, setStatusHistory] = useState<any>([])
    const [showCNCReceived, setShowCNCReceived] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedRouteType: any = searchParams.get('type');

    const commonState = useSelector((state: any) => state.common)
    const claimState = useSelector((state: any) => state.claim)

    const isNavLinkClickable = (pageIdx: any, statusHistoryObj = statusHistory) => {
        if (pageIdx === 0) return true

        //if current status is evaluation received then 
        //skip cnc-received status check in history instead find send to broker
        if (pageIdx === 4) pageIdx = 3

        return statusHistoryObj?.includes(pageIdx)
    }

    const pageSwitch = (param: any, cases: any) => {
        if (cases[param]) {
            return cases[param]
        } else {
            return cases.default
        }
    }

    const isURLAccessable = (currRoute: any, historyObj: any) => {
        let pageTypeIndx = DETAIL_NAV_MAPPING?.findIndex((ele: any) => currRoute === ele)
        let access = pageTypeIndx >= 0 ? isNavLinkClickable(pageTypeIndx, historyObj) : false

        if (!access) {
            setSearchParams(`?${new URLSearchParams({ type: 'claim-details' })}`)
        }
    }

    useEffect(() => {
        if (!selectedRouteType) {
            setSearchParams(`?${new URLSearchParams({ type: 'claim-details' })}`)
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedRouteType && (subRoute !== selectedRouteType)) {
            setSubRoute(selectedRouteType);
        }
        // eslint-disable-next-line
    }, [selectedRouteType]);

    useEffect(() => {
        let { claim_status } = commonState?.common || {}
        if (claim_status?.length) {
            setStatusList(claim_status)
        }
        // eslint-disable-next-line
    }, [commonState?.common])

    useEffect(() => {
        let { claimHistory } = claimState
        if (claimHistory?.length) {
            setStatusHistory(claimHistory)
            isURLAccessable(selectedRouteType, claimState?.claimHistory)
        }
        // eslint-disable-next-line
    }, [claimState?.claimHistory])

    useEffect(() => {
        if (claimState?.claimDetails?.claim_type === CLAIM_TYPE_CNC) {
            setShowCNCReceived(true)
        } else if (claimState?.claimDetails?.claim_type !== CLAIM_TYPE_CNC) {
            setShowCNCReceived(false)
        }
        // eslint-disable-next-line
    }, [claimState?.claimDetails?.claim_type])

    return (
        <div className="lead-detail-tabs">
            <div className="detail-form-sec">
                <div className="menu-tab-left-panel">
                    <div className="dealer-tab-left-panel nav">
                        <ul>
                            {
                                statusList?.map((ele: any, idx: number) => {
                                    let hasAccess = isNavLinkClickable(idx)
                                    if (ele.value === STATUS_SUB_STATUS['status']['mark-as-lost']) { //skiping mark as lost
                                        return
                                    }

                                    if (showCNCReceived && ele.value > STATUS_SUB_STATUS['status']['cnc-received']) {
                                        return
                                    } else if (!showCNCReceived && ele.value === STATUS_SUB_STATUS['status']['cnc-received']) {
                                        return
                                    }

                                    return (
                                        <li key={DETAIL_NAV_MAPPING[idx] + "_" + idx}>
                                            <NavLink to={hasAccess ? `?type=${DETAIL_NAV_MAPPING[idx]}` : `?type=${subRoute}`} className={() => {
                                                if (statusHistory?.includes(ele?.value)) {
                                                    return 'completed'
                                                }
                                                //if cnc is skiped, and currently on evaluation received, then show blue on evaluation received
                                                else if (!showCNCReceived && Math.max(...statusHistory) === STATUS_SUB_STATUS['status']['send-to-insurance-broker'] && (idx + 1) === STATUS_SUB_STATUS['status']['evaluation-received']) return "active"
                                                else if (idx === Math.max(...statusHistory)) return 'active'

                                                return ''
                                            }} aria-current="page">
                                                <span className="img-type"></span>
                                                {ele.label}
                                            </NavLink>
                                        </li>
                                    )
                                })
                            }

                        </ul>

                    </div>
                </div>


                <div className="route-files">
                    <ClaimRibbenBox />
                    {
                        pageSwitch(subRoute, {
                            "claim-details": <ClaimDetailsForm setLoader={props.setLoader} />,
                            "document-upload": <DocumentsUpload setLoader={props.setLoader} claimDetails={claimState} />,
                            "send-insurance": <SendInsurance type={3} />,
                            "cnc-received": <TransferProof name="CNC-Received" type={1} />,
                            "evaluation-received": <EvaluationReceived setLoader={props.setLoader} />,
                            "loa-received": <LoaReceived setLoader={props.setLoader} claimDetails={claimState.claimDetails} />,
                            "claim-closing": <ClaimClosing />,
                            "transfer-proof": <TransferProof name="Transfer Proof" type={2} />,

                            default: <ClaimDetailsForm setLoader={props.setLoader} />
                        })
                    }
                </div>

            </div>
            {/* </BrowserRouter> */}

        </div>
    )
    // }
}


// export default LeadDetailTabs;