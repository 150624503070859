import { useEffect, useState } from 'react';
import ClaimDetailsTab from './ClaimDetailsTabs'
import { getRibbonData, updateClaimDetailsInStore, updateClaimHistory, updateClaimRibbonData } from '@src/store/slices/claim';
import { useLocation } from 'react-router-dom';
import FullPageLoader from '@src/view/common/FullPageLoader';
import useClaimProcessingData from '@src/hooks/useClaimProcessingData';
import { useDispatch, useSelector } from 'react-redux';

export default function ClaimDetails(props: any) {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false)
    const [showClaimDetailsTab, setShowClaimDetailsTab] = useState(false); 

    const claimId = location?.pathname?.split('/')?.[2] || null
    const dispatch: any = useDispatch()
    const claimState = useSelector((state: any) => state.claim)
    

    useClaimProcessingData({ type: 'applicationDetails', api_type: 'applicationDetails', call_from: 'applicationDetails' })

    const getClaimRibbonData = async () => {
        if (claimId) {
            await dispatch(getRibbonData({ claim_id: claimId }))
        }
    }

    useEffect(() => {
        getClaimRibbonData()

        return (() => {
            dispatch(updateClaimDetailsInStore({}))
            dispatch(updateClaimRibbonData([]))
            dispatch(updateClaimHistory([]))
        })
    }, [])


    useEffect(()=>{
        if(claimState?.claimDetails?.id){
            setShowClaimDetailsTab(true);
        }
    },[claimState?.claimDetails?.id]);

    return (
        <>
            <FullPageLoader show={loading} />
            <div className="container-fluid">
               {showClaimDetailsTab && <ClaimDetailsTab setLoader={setLoading} /> }
            </div>

        </>
    )
}