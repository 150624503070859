import { gql } from '@apollo/client';

export const GET_FINANCIER_TEMPLATE_CONFIG_DATA = () => {

    return gql`query getFinancierTemplateConfig($page_number: Int) { 
        getFinancierTemplateConfig(page_number: $page_number) {  
        message    
        data
            {
                    hashId
                    partner_id
                    partner_name
                    all_template_types
                    all_status
                    send_to_insurance_broker_email
                    insurance_policy_shared_email
                    send_to_insurance_broker_wa
                    insurance_policy_shared_wa
                    send_to_insurance_broker_viber
                    insurance_policy_shared_viber
                    send_to_insurance_broker_email_ucf
                    send_to_insurance_broker_wa_ucf
                    send_to_insurance_broker_email_standalone
                    send_to_insurance_broker_wa_standalone
                    insurance_policy_shared_email_standalone
                    insurance_policy_shared_wa_standalone
                    insurance_policy_shared_email_ucf
                    insurance_policy_shared_wa_ucf
                    send_to_insurance_broker_email_renewal
                    send_to_insurance_broker_wa_renewal
                    insurance_policy_shared_email_renewal
                    insurance_policy_shared_wa_renewal
                    send_to_customer_for_claim_closing_docs_email
                    send_to_customer_for_claim_closing_docs_wa
                  
                    send_to_customer_for_claim_closing_docs_email_ucf
                    send_to_customer_for_claim_closing_docs_wa_ucf
                    send_to_customer_for_claim_closing_docs_email_standalone
                    send_to_customer_for_claim_closing_docs_wa_standalone
            }
        pagination
            {
                pages
                total
                limit
                cur_page
                next_page
                prev_page
            },
            status
        }
    }`
}


//GET TEMPLATE FIELDS
export const GET_TEMPLATE_FIELDS = () => {

    return gql`{
    templateFieldsDetails {
      data
      message
      status
    }
  }`
}



export const UPDATE_TEMPLATE_DATA = () => {

    return gql`mutation updateTemplate($template_input: TemplateInputs!) {
        updateTemplate(template_input: $template_input) {
            message
            data {
                email
                id
                description
                status
                template_for
                template_type
                partner_id
            }
            status
        }
    }`
}


//MASTER CONFIG
export const MASTER_CONFIG = () => {

    return gql`
    query masterConfig {
            masterConfig {
                data {
                    apptype {
                        value
                        label
                    }
                    insurance_type {
                        value
                        label
                    }
                    make_year {
                        mgf_ctpl { label value}
                        mgf_comprehensive { label value }
                    }
                    partner_list {
                        value
                        label
                    }
                    vehicle_type{
                        value
                        label
                        vehicle_usage
                        category_id
                    }
                    claim_status{
                        value
                        label
                    }
                    date_type{
                        value
                        label
                    }
                    groupedVehicleTypes{
                        value
                        label
                        vals
                    }
                    vehicle_usage {
                        value
                        label
                    }
                    claim_type {
                        value
                        label
                    }
                    vertical_list {
                        value
                        label
                    }
                    payment_status {
                        value
                        label
                    }
                    sub_status_list{
                        value
                        label
                    }
                    sample_csv {
                        type
                        url
                    }

                }
                message
                status
            }
        }`
}


// TEMPLATE FIELD INFO
export const TEMPLATE_FIELD_INFO = () => {
    return gql`
    query templateFieldsinfo($partner_id: String, $template_type: Int, $template_for: Int, $insurance_type: Int,$claim_id: String,$key: String, $lead_id: Int, $status: Int){
        templateFieldsinfo( partner_id: $partner_id
    template_type: $template_type
    template_for: $template_for
    insurance_type: $insurance_type
    claim_id: $claim_id
    key: $key
    lead_id: $lead_id
    status: $status
    ) {
            message
            data {
                financier_name
                templateType {
                    value
                    label
                    key
                }
                templateFor {
                    value
                    label
                }
                cohort_list {
                    partner_id
                    partner_name
                    template_id
                    template_type
                    template_for
                    email
                    description
                    status
                    insurance_type
                    dealer_present
                    send_to_insurance_broker_email
                    insurance_policy_shared_email
                    insurance_policy_shared_email
                    send_to_insurance_broker_wa
                    insurance_policy_shared_wa
                    send_to_insurance_broker_viber
                    insurance_policy_shared_viber
                    send_to_insurance_broker_email_ucf
                    send_to_insurance_broker_wa_ucf
                    send_to_insurance_broker_email_standalone
                    send_to_insurance_broker_wa_standalone
                    insurance_policy_shared_email_standalone
                    insurance_policy_shared_wa_standalone
                    insurance_policy_shared_email_ucf
                    insurance_policy_shared_wa_ucf
                    send_to_insurance_broker_email_renewal
                    send_to_insurance_broker_wa_renewal
                    insurance_policy_shared_wa_renewal
                    document_id
                    payment_status
                    saved_template_type

                }
                dealerPresent {
                    value
                    label
                }
            }
            status
        }
    }`;
};


// CUSTOMER Template FIELD INFO
export const CUSTOMER_TEMPLATE_FIELD_INFO = () => {
    return gql`
     query customerTemplateFieldinfo($template_input: Claim_id_res!)  {
    customerTemplateFieldinfo(template_input:$template_input){
         message 
         data {
            id
            description
            template_type
            status
        }
        status
    }
 }
`;
};



export const SAVE_CUSTOMER_TEMPLATE_DATA = () => {
    return gql`
     mutation saveCustomerTemplateData($template_input: TemplateInputs!) {
     saveCustomerTemplateData(template_input: $template_input) {
            message 
            status 
            doc_status_id    
            status_id 
    }
}
`;
};

export const CUSTOMER_PENDING_DOCUMENTS = () => {
    return gql`
    query getRequestedDocuments($claim_id: String!, $key: String)  {
        getRequestedDocuments(claim_id:$claim_id, key:$key){
            message 
            data {
                doc_id
                name
            }
            status
        }
    }
`};