import { setMMVList } from "@store/slices/common";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { CREATE_CLAIM, GET_FORM_CONFIG, GET_INSURANCE_FOR_CLAIM, GET_INSURANCE_FOR_CLAIM_BY_ID, GET_MASTER_DATA, GET_MMV_LIST, GET_REGION_LISTING, SEARCH_INSURANCE, UPDATE_INSURANCE_CLAIM_PROGRESS } from "../../services/masterdata.gql";
//import { GET_INCENTIVE_DATA } from "../../services/incentivedata.gql";  

import { MASTER_CONFIG } from "../../services/templatedata.gql";
import { setClaimInsuranceData, setInsuranceLeads } from "@store/slices/claim";

export const getMasterData = (client:any) => {
	return async (dispatch:any) => {
		try {
			executeGraphQLQuery(GET_MASTER_DATA(), client).then((response:any) => {
				if (response && response.data && response.data.master_config) {
					let result = response.data.master_config;
					dispatch(success(result))
				} else dispatch(success({}));
			})
				.catch((error:any) => {
					dispatch(success({}));
				});
		} catch (err) {
			dispatch(success({}))
		}
	};
	function success(payload:any) {
		return { type: "GET_MASTER_DATA", payload };
	}
}

export const getInsuranceForClaim = (client: any) => async (dispatch: any) => {
	return new Promise((resolve, reject) => {
		try {
			executeGraphQLQuery(GET_INSURANCE_FOR_CLAIM(), client).then((response: any) => {
				if (response && response.data && response.data.get_leads_for_claim) {
					let result = response.data.get_leads_for_claim
					dispatch(setInsuranceLeads(result.data))
					return resolve(result)
				} else {
					dispatch(setInsuranceLeads({}))
					resolve(response)
				}
			}).catch((err: any) => resolve(err))
		} catch (error) {
			return resolve({})
		}
	})
}


export const getInsuranceForClaimById = (client: any, variables: any) => async (dispatch: any) => {
	return new Promise((resolve, reject) => {
		try {
			let query = variables?.lead_id ? GET_INSURANCE_FOR_CLAIM_BY_ID() : SEARCH_INSURANCE()
			let queryName = variables?.lead_id ? 'get_leads_for_claim_by_id' : 'searchInsurance'
			executeGraphQLQuery(query, client, variables).then((response: any) => {
				if (response && response.data && response.data?.[queryName]) {
					let result = response.data?.[queryName]
					if (variables.lead_id) {
						if (result?.data?.documents?.length) result['data']['documents'] = result.data.documents.map((e: any) => ({ doc_path: e.doc_path, document_id: e.document_id, status: e.status }))
						dispatch(setClaimInsuranceData(result?.data))
					}
					return resolve(result)
				} else {
					dispatch(setClaimInsuranceData({}))
					resolve(response)
				}
			}).catch((err: any) => resolve(err))
		} catch (error) {
			return resolve({})
		}
	})
}

export const getMMVList = (client: any, variables: any) => async (dispatch: any) => {
	return new Promise((resolve, reject) => {
		try {
			executeGraphQLQuery(GET_MMV_LIST(), client, variables).then((response: any) => {
				if (response && response.data && response.data.get_mmv) {
					let result = response.data.get_mmv
					dispatch(setMMVList(result?.data))
					return resolve(result)
				} else {
					dispatch(setMMVList({}))
					resolve(response)
				}
			}).catch((err: any) => resolve(err))
		} catch (error) {
			return resolve({})
		}
	})
}


// export const getMasterConfig = (client: any) => (dispatch: any) => {
// 	return new Promise((resolve, reject) => {
// 		try {
// 			executeGraphQLQuery(MASTER_CONFIG(), client).then((response: any) => {
// 				if (response && response.data && response.data.masterConfig) {
// 					let result = response.data.masterConfig.data;
// 					dispatch(setMasterConfigData(result))
// 					resolve(result)
// 				} else {
// 					dispatch(setMasterConfigData({}))
// 					resolve({})
// 				}
// 			})
// 				.catch((error: any) => {
// 					dispatch(setMasterConfigData({}))
// 				});
// 		} catch (err) {
// 			dispatch(setMasterConfigData({}))
// 		}
// 	});
// }


export const getRegionListing = (client:any) => {
	return new Promise((resolve, reject) => {
		try {
			executeGraphQLQuery(GET_REGION_LISTING(), client).then((response:any) => {
				if (response && response.data && response.data.getRegionListing) {
					let result = response.data.getRegionListing
					return resolve(result)
				} else {
					return resolve(response)
				}
			}).catch((err:any) => {
				return resolve(err)
			})
		} catch (error) {
			return resolve({})
		}
	})
}


export const createClaim = (client:any, variables:any) => {
	return new Promise((resolve, reject) => {
		try {
			executeGraphQLQuery(CREATE_CLAIM(), client, variables).then((response:any) => {
				if (response && response.data && response.data.createClaim) {
					let result = response.data.createClaim
					return resolve(result)
				} else {
					return resolve(response)
				}
			}).catch((err:any) => {
				return resolve(err)
			})
		} catch (error) {
			return resolve({})
		}
	})
}

export const getFormConfig = (client: any) => {
	return new Promise((resolve, reject) => {
		try {
			executeGraphQLQuery(GET_FORM_CONFIG(), client).then((response: any) => {
				if (response && response.data && response.data.formConfig) {
					let result = response.data.formConfig
					return resolve(result)
				} else {
					return resolve(response)
				}
			}).catch((err: any) => {
				return resolve(err)
			})
		} catch (error) {
			return resolve({})
		}
	})
}

export const updateInsuranceClaimStatus = (client:any, variables:any) => {
	return new Promise((resolve, reject) => {
		try {
			executeGraphQLQuery(UPDATE_INSURANCE_CLAIM_PROGRESS(), client, variables).then((response:any) => {
				if (response && response.data && response.data.updateInsuranceClaimStatus) {
					let result = response.data.updateInsuranceClaimStatus
					return resolve(result)
				} else {
					return resolve(response)
				}
			}).catch((err:any) => {
				return resolve(err)
			})
		} catch (error) {
			return resolve({})
		}
	})
}
