import Select, { components } from 'react-select';
import React, { useCallback, useEffect, useState } from "react";
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { executeUpload } from '@src/common/executeGraphQLQuery';
import { print } from 'graphql'
import { EvaluationDate } from '../../DatePicker';
import * as HELPER from '../../../config/helper';
import { LOA_FIELDS,LOA_AMT_FIELDS, ROLE_FEATURES, STATUS_SUB_STATUS } from '@src/config/constant';
import CurrencyInputField from "@src/common/CurrencyInputField";
import {GET_LOA_RECEIVED_DATA,SAVE_LOA_DATA} from "../../../services/loaReceived.gql";

import {UPLOAD_EVAL_FILE} from "../../../services/claimEvaluation.gql";
import { executeGraphQLMutation,executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import FileUpload from '../../../common/FileUpload';
import { validateLoaFields } from '@src/common/FunctionList';
import { fetchClaimProcessingData,updateClaimDetailsInStore,updateClaimHistory, updateClaimRibbonData} from '@src/store/slices/claim';



export default function LoaReceived(props: any) {
    let {claimDetails}=props
    const [loaDetails, setLoaDetails] = useState<any>([]);
    const [showContinue, setShowContinue] = useState<boolean>(false);
    const [fieldDisable, setFieldDisable] = useState<any>(false); 
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedRouteType = searchParams.get('type');
    const client = useApolloClient();
    const dispatch: any = useDispatch()
    const [effectivityDate, setEffectivityDate] = useState<any>();
    const [errorMsg, setErrorMsg] = useState<any>({})
    const claimId = location?.pathname?.split('/')?.[2] || null;

    const [imageName, setImageName] = useState<any>();
    const [loaHeading, setLoaHeading] = useState<string>();
    const [loaLetter, setLoaLetter] = useState<boolean>(false)
    const [uploadReset, setUploadReset] = useState<any>(0); 
    const [BlankFlage, setBlankFlage] = useState<any>(0); 
    let [emptyErr, setEmptyErr] = useState<boolean>(false)
    const [isNewCreated, setIsNewCreated] = useState<boolean>(true)

    const [refresh, setRefresh] = useState<boolean>(false);
    const claimState: any = useSelector((state: any) => state.claim);
    const keysToRemove = ["__typename"];
    
    const showloaLetterPopup = (index:number) => {
        let heading=`Attach Letter of Approval Received`;
        let imgName=`loa letter`
        setImageName(imgName);
        setLoaHeading(heading);
        setLoaLetter(true);
        document.body.classList.add("overflow-hidden");
    }

    const hideEvaluationLetterPopup = () => {
        setLoaLetter(false);
        setLoaHeading('');
        setImageName('');
        document.body.classList.remove("overflow-hidden");
    }

    /*
    * Add LOA Received 2nd Time
    */
    const addLoaReceivedSlab = () => {
        if(loaDetails.length<2 && loaDetails[0]?.id){
            let defaultObj={accident_date:null,claim_number:null,approval_date:null,approve_ref_no:null,approve_final_amt:null,dep_amt_applied:null,deduc_amt_applied:null,net_amt_approved:null,url:null,img_name:null};

            setLoaDetails([...loaDetails,defaultObj]);
        }
    };
    
    /*
    *Upload Image
    */
    const uploadImage=async(file:any)=>{
        if(file){
            let fileType = file.type.split("/")[1];
            let filesArray: any = []
            filesArray.push(file);
            const formData = new FormData();
            const operations = JSON.stringify({
                query: print(UPLOAD_EVAL_FILE()),
                variables: {
                    file:filesArray,
                    claim_id: claimId
                }
            });
    
            const map: any = {};
            filesArray.forEach((_: any, index: any) => {
                map[index] = [`variables.file.${index}`];
            });
    
            formData.append('operations', operations);
            formData.append('map', JSON.stringify(map));
    
            filesArray.forEach((file: any, index: any) => {
                formData.append(index.toString(), file);
            });

            try {
                const result = await executeUpload(formData);
                let { status, data } = result?.data?.uploadEvalFile;
                if (status && status === 200) {
                    let loa_details=[...loaDetails];
                    if(loa_details.length){
                        let index =loa_details.length-1 ;
                        loa_details[index]["url"]=data;
                        loa_details[index]["img_name"]=`${imageName}.${fileType}`;

                        errorMsg[`img_name_${index}`] = '';
                        setErrorMsg({ ...errorMsg }); 
                        setLoaDetails(loa_details);
                        hideEvaluationLetterPopup();
                        setUploadReset('');
                        setBlankFlage(BlankFlage+1);
                    }else{
                        setUploadReset('');
                        setBlankFlage(BlankFlage+1);
                    }
                }
               
            } catch {
    
            } finally {
                props.setLoader(false);
            }
        }

    }
    const getLoaData = async() => {
        props.setLoader(true);
        executeGraphQLQuery(GET_LOA_RECEIVED_DATA(claimId), client).then((response:any) => { 
            props.setLoader(false);
            if(response && response.data && response.data.getLoaData && response.data.getLoaData.status===200){
                let data:any=response.data.getLoaData.data;
                data=data.map(({ [keysToRemove[0]]: _, ...rest })=>rest);
                setIsNewCreated(false);
                if(data!=null){
                    delete data["__typename"];
                    addLoaDetails([...data]);
                }else{
                    addLoaDetails([]);
                }
            }
        }).catch((error:string) => {
            toast.error(error);
            props.setLoader(false);
        });
    }
    
    const handleChange=(name:any,index:number, value: any)=>{
        let detail_loa=[...loaDetails];
        if(detail_loa.length){
            if(LOA_AMT_FIELDS.includes(name)){
                let net_amount_approved:any;
                value=HELPER.removeCurrencyFormatting(value);                
                if(name==="approve_final_amt"){
                    net_amount_approved = Number(value)-Number(detail_loa[index]["dep_amt_applied"])-Number(detail_loa[index]["deduc_amt_applied"]);
                }
                if(name==="dep_amt_applied"){
                    net_amount_approved = Number(detail_loa[index]["approve_final_amt"])-Number(value)-Number(detail_loa[index]["deduc_amt_applied"]);
                }
                if(name==="deduc_amt_applied"){
                    net_amount_approved = Number(detail_loa[index]["approve_final_amt"])-Number(detail_loa[index]["dep_amt_applied"])-Number(value);
                }
                if(net_amount_approved>0){
                    errorMsg[`net_amt_approved_${index}`] = '';
                }
                detail_loa[index]["net_amt_approved"]= +parseFloat(net_amount_approved).toFixed(2)
            }
            detail_loa[index][name] = LOA_FIELDS.includes(name) ? +value : value
            setLoaDetails(detail_loa);
            errorMsg[`${name}_${index}`] = '';
            setErrorMsg({ ...errorMsg });
        }
    }
    const validateFields = () => {
        let errorData:any=validateLoaFields(loaDetails);
        setErrorMsg(errorData.error_msg);
        setRefresh(!refresh)
        return errorData.error;
    }

    const handleSave = async () => {
        props.setLoader(true)
        const isValid = validateFields()
        if (isValid){
            props.setLoader(false)
            return false
        }
        let loa_details=[...loaDetails];
        loa_details=loa_details.map((el:any)=>({...el,approve_final_amt:Number(el.approve_final_amt),deduc_amt_applied:Number(el.deduc_amt_applied),dep_amt_applied:Number(el.dep_amt_applied)}));
        let loaFields={
            saveLoaInput:{
                claim_id:claimId,
                is_new_created:isNewCreated,
                loa_details:loa_details
            }
        }
        executeGraphQLMutation(SAVE_LOA_DATA,loaFields, client).then((response:any) => { 
            props.setLoader(false);
            let { status, data,message } = response?.data?.save_loa_received;
            if(status && status===200){
                let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
                    if (e.key === 'status') {
                        return { ...e, value: data.status_id }
                    }
                    return e
                })
                let updatedHistory = claimState?.claimHistory?.includes(data.status_id) ? [] : [...claimState?.claimHistory, data.status_id]

                dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, status_id:data.status_id }))
                dispatch(updateClaimRibbonData(updatedRibbonData)) //update claim status in ribbon
                if (updatedHistory.length) dispatch(updateClaimHistory(updatedHistory)) //update claim status history
                setSearchParams(`?${new URLSearchParams({ type: 'claim-closing' })}`)
                toast.success(message);
            }
           
        }).catch((error:any) => {
            props.setLoader(false);
            toast.error(error);
        });
    }


    const addLoaDetails=(data:any)=>{
        if(data.length>0){
            setLoaDetails([...data]);
        }else{
            let defaultdata=[{accident_date:null,claim_number:null,approval_date:null,approve_ref_no:null,approve_final_amt:null,dep_amt_applied:null,deduc_amt_applied:null,net_amt_approved:null,url:null,img_name:null}];
            setLoaDetails(defaultdata);
        }
    }
    
    const fetchprocessData = async()=>{
        try {
            const result: any = await fetchClaimProcessingData({ type: 'applicationDetails', variables: { claim_id: claimId, api_type: 'applicationDetails' } });
            dispatch(updateClaimDetailsInStore({ ...claimDetails, ...result?.data, loading: false }));
            if(result.status===200){
                return result.data;
            }
        } catch {
            toast.error("Error in fetching data")
        }
    }

    /*
    *Remove Image
    */
    const removeImage=async(index:any)=>{
        let loa_Detail=[...loaDetails];
        loa_Detail[index]["img_name"]=null;
        loa_Detail[index]["url"]=null;
        setLoaDetails(loa_Detail);
    }
    const showImage=async(url:string,name:string)=>{
        const link = document.createElement('a');
        link.href = url;
        link.target="_blank";
        link.download = name; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const callApi=async()=>{
        let status_id:any;
        let disableStatus:any;
        if(claimDetails && Object.keys(claimDetails).length
        ){   
            status_id=claimDetails.status_id;
            setEffectivityDate(claimDetails.effectivity_date);
        }else{
            let claimData=await fetchprocessData();
            if(Object.keys(claimData).length){
                status_id=claimData.status_id;
                setEffectivityDate(claimData.effectivity_date);
            }
        }
        disableStatus=(status_id>=7)?true:false;
        setFieldDisable(disableStatus);
        if ([5,6].includes(status_id)) setShowContinue(true)
            else setShowContinue(false)
        
        if(status_id>5){
            getLoaData();
        }else{
            addLoaDetails([]);
        }    
    }

    useEffect(() => {
        callApi();
    }, [0]);

    return (
        <div className="form-tab-right-panel">
            <div className="lead-detail-from-outer">
                <div className="lead-form-heading lead-top-heading">
                    <h2>Letter Of Approval Received</h2>
                    {
                        loaDetails.length !== 2 &&
                        <button className='btn-primary' disabled={fieldDisable} onClick={(e:any)=>{addLoaReceivedSlab()}}>
                            <i className='ic-add'></i> Add Revised LOA
                        </button>
                    }
                   
                </div>
                <div className="lead-form-filed p-lg-t">
                    {
                        loaDetails.length>0 && loaDetails.map((el:any,index:number)=>
                        
                            <div className='row' key={index}>
                                <fieldset className={"form-filed date-picker-detail col-md-4"} key={index}>
                                    <div  className={"material animation-effect" + (el.accident_date ? ' active-label' : '')}>
                                    
                                        <EvaluationDate
                                        placeholder="Date of Accident*" 
                                        minDate={new Date(effectivityDate)}
                                        maxDate={new Date()}
                                        disabled={(el.id)?true:false}
                                        startDate={(el.accident_date)?new Date(el.accident_date):null}
                                        dateFormat={'dd MMM, yyyy'}
                                        showTimeSelect={1}

                                        onChange={(e: Date) => handleChange("accident_date",index, e)}
                                        />
                                        {errorMsg && errorMsg['accident_date_'+index]? <span className="error-txt">{errorMsg['accident_date_'+index]}</span> : null}
                                    </div>
                                </fieldset>
                                <fieldset className="form-filed col-md-4">
                                    <div className="material">
                                        <input
                                            maxLength={1000}
                                            disabled={(el.id)?true:false}
                                            type="text"
                                            placeholder=" "
                                            name="claim_number"
                                            className="form-input"
                                            value={el.claim_number || ''} 
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (/^\d*$/.test(value)) {  
                                                  handleChange("claim_number", index, value);
                                                }
                                            }}
                                        />
                                        <label data-label="Claim Number *" className="form-label"></label>

                                        {errorMsg && errorMsg['claim_number_'+index] ? <span className="error-txt">{errorMsg['claim_number_'+index]}</span> : null}
                                    </div>
                                </fieldset> 
                                <fieldset className={"form-filed date-picker-detail col-md-4"} key={index}>
                                    <div key={index} className={"material animation-effect" + (el.approval_date ? ' active-label' : '')}>
                                    
                                        <EvaluationDate
                                        placeholder="Date of Approval*" 
                                        minDate={new Date(effectivityDate)}
                                        maxDate={null}
                                        disabled={(el.id)?true:false}
                                        startDate={(el.approval_date)? new Date(el.approval_date):null}
                                        onChange={(e: Date) => handleChange("approval_date",index, e)}
                                        />
                                        {errorMsg && errorMsg['approval_date_'+index]? <span className="error-txt">{errorMsg['approval_date_'+index]}</span> : null}
                                    </div>
                                </fieldset>
                                
                                <fieldset className="form-filed col-md-4">
                                    <div className="material">
                                        <input
                                            maxLength={20}
                                            disabled={(el.id)?true:false}
                                            type="text"
                                            placeholder=" "
                                            name="approve_ref_no"
                                            className="form-input"
                                            value={el.approve_ref_no || ''} 
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (/^\d*$/.test(value)) {  
                                                  handleChange("approve_ref_no", index, value);
                                                }
                                            }}
                                        />
                                        <label data-label="Approval Letter Reference No. *" className="form-label"></label>

                                        {errorMsg && errorMsg['approve_ref_no_'+index] ? <span className="error-txt">{errorMsg['approve_ref_no_'+index]}</span> : null}
                                    </div>
                                </fieldset> 

                                <fieldset className="form-filed col-md-4">
                                    <div className="material">
                                        <CurrencyInputField
                                            inputProps={{
                                            id: "approve_final_amt",
                                            type: "text",
                                            name: "approve_final_amt",
                                            placeholder: " ",
                                            value: el.approve_final_amt || '',
                                            className: "form-input",
                                            disabled:(el.id)?true:false
                                            }}
                                            onChange={(event: any) => handleChange("approve_final_amt",index, event.target.value)}
                                        />
                                        <label data-label="Final Approved Claims Amount *" className="form-label"></label>

                                        {errorMsg && errorMsg['approve_final_amt_'+index] ? <span className="error-txt">{errorMsg['approve_final_amt_'+index]}</span> : null}
                                    </div>
                                </fieldset> 
                                <fieldset className="form-filed col-md-4">
                                    <div className="material">
                                        <CurrencyInputField
                                            inputProps={{
                                            id: "dep_amt_applied",
                                            type: "text",
                                            name: "dep_amt_applied",
                                            placeholder: " ",
                                            value: el.dep_amt_applied || '',
                                            className: "form-input",
                                            disabled:(el.id)?true:false
                                            }}
                                            onChange={(event: any) => handleChange("dep_amt_applied",index, event.target.value)}
                                        />
                                        <label data-label="Depreciation Amount Applied *" className="form-label"></label>

                                        {errorMsg && errorMsg['dep_amt_applied_'+index] ? <span className="error-txt">{errorMsg['dep_amt_applied_'+index]}</span> : null}
                                    </div>
                                </fieldset> 
                                <fieldset className="form-filed col-md-4">
                                    <div className="material">
                                        <CurrencyInputField
                                            inputProps={{
                                            id: "deduc_amt_applied",
                                            type: "text",
                                            name: "deduc_amt_applied",
                                            placeholder: " ",
                                            value: el.deduc_amt_applied || '',
                                            className: "form-input",
                                            disabled:(el.id)?true:false
                                            }}
                                            onChange={(event: any) => handleChange("deduc_amt_applied",index, event.target.value)}
                                        />
                                        <label data-label="Deductible Amount Applied *" className="form-label"></label>

                                        {errorMsg && errorMsg['deduc_amt_applied_'+index] ? <span className="error-txt">{errorMsg['deduc_amt_applied_'+index]}</span> : null}
                                    </div>
                                </fieldset> 
                                <fieldset className="form-filed col-md-4">
                                    <div className="material">
                                        <CurrencyInputField
                                            inputProps={{
                                            id: "net_amt_approved",
                                            type: "text",
                                            name: "net_amt_approved",
                                            placeholder: " ",
                                            value: el.net_amt_approved || '',
                                            className: "form-input",
                                            disabled:true
                                            }}
                                            onChange={(event: any) => handleChange("net_amt_approved",index, event.target.value)}
                                        />
                                        <label data-label="Net Amount Approved *" className="form-label"></label>

                                        {errorMsg && errorMsg['net_amt_approved_'+index] ? <span className="error-txt">{errorMsg['net_amt_approved_'+index]}</span> : null}
                                    </div>
                                </fieldset> 
                                <fieldset className="col-md-4">
                                    {
                                      (el.img_name)?
                                        (el.id)?
                                            <div className='attech-repair-doc' onClick={()=>{showImage(el.url,el.name)}}>
                                                    <p>{el.img_name}</p>
                                            </div>:
                                            <div className='attech-repair-doc'>
                                                <i className='ic-attach_file' onClick={()=>{showloaLetterPopup(index)}}></i>
                                                    <p>{el.img_name}</p>
                                                    <i className='ic-cancel' onClick={()=>{removeImage(index)}}></i>
                                            </div>:
                                        
                                        <button className='btn-line attach-loa' onClick={()=>{showloaLetterPopup(index)}}>
                                                <i className='ic-attach_file'></i>
                                                Attach LOA Received Letter
                                        </button>
                                          
                                    }
                                    {errorMsg && errorMsg['img_name_'+index]? <span className="error-txt">{errorMsg['img_name_'+index]}</span> : null}
                                </fieldset>
                                {/* <fieldset className="form-filed col-md-8" key={index}>
                                    <div className="material">
                                        <textarea 
                                            className='form-input' 
                                            placeholder=' ' 
                                            disabled={(el.id)?true:false}
                                            value={el.remark}  
                                            onChange={(event) => { handleChange("remark",index, event.target.value) }}>

                                        </textarea>
                                        <label data-label="Remarks *" className="form-label"></label>

                                        {errorMsg && errorMsg['remark_'+index] ? <span className="error-txt">{errorMsg['remark_'+index]}</span> : null}
                                    </div>
                                </fieldset> */}
                            </div>
                        )
                    }
                    <div className="btn-continue">
                    {
                        (showContinue && HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: claimState?.claimDetails?.assign_user_id }) && (claimState.claimDetails.status_id !== STATUS_SUB_STATUS['status']['mark-as-lost']))?
                        <button type="button" className="btn-primary" onClick={handleSave}>Continue</button>:
                        <button type="button" className="btn-primary" disabled>Continue</button>
                    }
                    </div>
                </div>
            </div>

            <div className="model-outer Evaluation-Letter-popup">
                <Modal show={loaLetter} handleClose={hideEvaluationLetterPopup} >
                    <div className="modal-header">
                        <h2>{loaHeading}</h2>
                    </div>
                    <div className="modal-body" >
                        <div className='upload-file-outer'>
                            <FileUpload setImageValue={uploadImage} uploadReset={uploadReset} setError={setEmptyErr} BlankFlage = {BlankFlage} />
                        </div>
                    </div>
                    <div className='modal-footer'>
                    </div>
                </Modal>
                
            </div>
        </div>
    );

};

const Modal = (props: any) => {
    let { handleClose, show, children } = props
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};