import { useEffect, useState } from 'react';
import Modal from "./ModalPopup";
import { Tab } from 'react-bootstrap';
import { getLeadHistory } from '@src/store/slices/claim';
import dateFormat from "dateformat";

function LeadTimelineModal(props: any) {

    const {viewTimeLine, selectedRemarkLead} = props;


    const [loading, setLoading] = useState(false);

    const [timelineList, setTimelineList] = useState([]);

    useEffect(()=>{

       // if(viewTimeLine){
            setLoading(true);
           
            let params = {
                claim_input: {
                    claim_id: props.selectedRemarkLead
                }
            };

            getLeadHistory(params).then((res: any) => {
                if (res?.data?.data) {
                    setLoading(false);
                    setTimelineList(res?.data?.data)
                }

            })
       // }

    // eslint-disable-next-line
    }, [viewTimeLine])

    return (
        

            <div className="view-timeline-popup image-timeline-popup">
                <Modal show={viewTimeLine} handleClose={() => props.handleViewTimeLine(false, 0)} >
                    <div className="modal-header">
                        <h2>Claim Timeline</h2>
                    </div>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                        <div className="modal-body" >

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <table>
                                        <tbody>

                                            {
                                                !loading && timelineList.length
                                                    ?
                                                    timelineList.map((timeline: any, idx: number) =>
                                                        <tr key={idx+1}>
                                                            <td>
                                                                <span className="date">{dateFormat(timeline.history_created_date, 'd mmm')}</span>
                                                                <span className="time">{dateFormat(timeline.history_created_date, 'hh:MM tt')}</span>
                                                            </td>
                                                            <td>
                                                                <span className="heading">{timeline.status_name}</span>
                                                                <span className="">{timeline.sub_status_name}</span>
                                                                <span className="">{timeline.remarks}</span>
                                                            </td>
                                                            <td>{timeline.created_by_name || 'Admin'}</td>
                                                        </tr>
                                                    )
                                                    :
                                                    loading ? <tr><p style={{ textAlign: "center" }}>Loading...</p></tr> : <tr><p style={{ textAlign: "center" }}>No data found</p></tr>

                                            }

                                        </tbody>
                                    </table>

                                </Tab.Pane>

                            </Tab.Content>
                        </div>

                    </Tab.Container>
                </Modal>

            </div>

    )
}

export default LeadTimelineModal;