import { useState, useEffect } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector, useDispatch } from 'react-redux';
import WhatApp from "../webroot/images/whatsapp.svg";
import Viber from "../webroot/images/Viber.svg";
import Mail from "../webroot/images/mail.svg";
import { saveCustomerTemplateData } from '@store/slices/template';
import { useSearchParams, useParams } from 'react-router-dom';
import {templateFieldInfo, updateTemplateValue} from '@src/store/slices/template'
import { WHATSAPP, EMAIL, STATUS_SUB_STATUS, CLAIM_TYPE_CNC, ROLE_FEATURES } from '@src/config/constant';
import { fetchClaimProcessingData, updateClaimDetailsInStore, updateClaimHistory, updateClaimRibbonData} from '@src/store/slices/claim';
import { toast } from 'react-toastify';
import { WHATSAPP_WEB_URL,SEND_TO_INS_PARTNER,COMPREHENSIVE } from '@src/config/constant'
import FullPageLoader from '@src/view/common/FullPageLoader'
import * as HELPER from '@src/config/helper';

const SendInsurance = (props: any) => {
    const [askCustomer, setAskCustomer] = useState<any>(false);
    const claimState: any = useSelector((state: any) => state.claim);
    const [formData, setFormData] = useState<any>({
        doc_id: [],
        template_type: EMAIL,
        description: ''
    });
    const [showContinue, setShowContinue] = useState(false)

    const { id } = useParams();
    const dispatch: any = useDispatch()
    const [documents, setDocuments] = useState<Array<any>>([])
    let [trigger, setTrigger] = useState(0);
    const [loading, setLoading] = useState(false)
   
    const [searchParams, setSearchParams] = useSearchParams();
    const [claimData,setClaimData] = useState<any>();
    const selectedRouteType = searchParams.get('type');
    const partnerTemplate = useSelector((state: any) => state.template)
    const templateinfo = useSelector((state: any) => state.template)
    
    const [initialDescription, setInitialDescription] = useState({email: "", whatsApp: ""});
    const [updatedDescription, setUpdatedDescription] = useState<any>({email: "", whatsApp: ""});

    const hideAskCustomerPopup = () => {
        setAskCustomer(false);
        document.body.classList.remove("overflow-hidden");
    }

    // document not in present in store then call api for document fetch list
    // useEffect(() => {
    //     if (claimState?.claimDetails?.documents) {
    //         setDocuments(claimState?.claimDetails?.documents)
    //     }else{
    //          fetchClaimProcessingData({ variables: { claim_id: id, api_type: "docUpload" , type: 1} }).then((res: any) => {
    //             if (res?.data?.documents) {
    //                 setDocuments(res?.data?.documents);
    //             }

    //         })
    //     }

    // }, [claimState?.claimDetails?.documents])

    useEffect(() => {
        // if (claimState?.claimDetails?.documents) {
        //     setDocuments(claimState?.claimDetails?.documents)
        // }else{
        if(id){

            fetchClaimProcessingData({ variables: { claim_id: id, api_type: "docUpload" , type: 1} }).then((res: any) => {
                if (res?.data?.documents) {
                    setDocuments(res?.data?.documents);
                }
                
            })
        }
        // }

    }, [id])

    // if claimDetails is not present in store then call api for fetch calim details
    useEffect(()=>{
        if(claimState?.claimDetails){
            setClaimData(claimState?.claimDetails);
            //set data
        }else{
            fetchClaimProcessingData({ variables: { claim_id: id, api_type: "applicationDetails" } }).then((res: any) => {
                if (res?.data) {
                    setClaimData(res?.data)
                   // setDocuments(res?.data?.documents);
                }

            })
        }
    },[claimState?.claimDetails])


    const handleCheckboxChange = (event: any, docId: any) => {
        const checked = event.target.checked;
        setFormData((prevData: any) => {
            // If checked, add docId to doc_id array, otherwise remove it
            const currentDocIds = Array.isArray(prevData.doc_id) ? prevData.doc_id : [];

            const updatedDocIds = checked
            ? currentDocIds.includes(docId) ? currentDocIds : [...currentDocIds, docId]
            : currentDocIds.filter((id: any) => id !== docId);

            return { ...prevData, doc_id: updatedDocIds };
        });
    };

    // save details
    const shareInsurance = async () => {
        if (!checkValidateData()) {
            return;
        }

        setLoading(true)
        let template_input: any = {};
        template_input.description = formData?.description || ''
        template_input.claim_id = id || ''
        template_input.template_type = formData?.template_type || []
        template_input.doc_id = formData?.doc_id || []
        template_input.key = 'sendToInsurancePartner'
        template_input.partner_id = claimData?.partner_hash_id || ''
        template_input.template_id = formData?.template_id || 0
        template_input.template_for = 2


        console.log(template_input, formData)
        saveCustomerTemplateData({ template_input }).then(async (res: any) => {
            if (res?.status === 200) {
                // toast.success(res.message)
                // hideAskCustomerPopup()
                setFormData((prevData:any) => ({
                    ...prevData,
                    doc_id: template_input.doc_id
                })); 
                setLoading(false)
                
                const templateData = partnerTemplate?.partnerConfigTemplate?.cohort_list?.map((val: any) => {
                    let value = {...val}
                    if(val.template_type === EMAIL){
                        value.document_id = formData.doc_id
                        return value;
                    }
                    return value
                });
                dispatch(updateTemplateValue(templateData))
               
                //setTrigger(trigger + 1);
            
              // share detail on whatsapp   
            if (formData?.template_type === WHATSAPP) {
                let waMsg = "";
                let openWhatsapp = true;
                let formatMsg = formData?.description.replaceAll('<br/>', "\n");

                let fetchAllDocuments:any = await fetchClaimProcessingData({ type: "docUpload", variables: { claim_id: id, api_type: "docUpload", type: 1 } })
                if(fetchAllDocuments?.data?.documents?.length){
                    formatMsg += `\n\n`;

                    for (let doc of fetchAllDocuments?.data?.documents) {
                        if(!formData?.doc_id?.includes(doc.value)) continue;
                        
                        if (doc.images.length) {
                            for(let img of doc.images){
                                formatMsg += `${doc.label}: \n`;
                                formatMsg += `${img.doc_path} \n`;

                            }
                            
                        }
                        
                    }
                }
                waMsg = WHATSAPP_WEB_URL + encodeURIComponent(formatMsg);


                if (openWhatsapp) window.open(waMsg, '_blank', 'noreferrer');
                setLoading(false)
                // resetData();
            }

            let redirectType = claimState?.claimDetails?.claim_type === CLAIM_TYPE_CNC ? 'cnc-received' : 'evaluation-received'

            setSearchParams(`?${new URLSearchParams({ type: redirectType })}`)
                toast.success("Data Saved Successfully")
                //setTrigger(prevTrigger => prevTrigger + 1);
              
                //update claim status in ribbon
                let updatedRibbonData = claimState?.ribbonData?.map((e: any) => {
                    if (e.key === 'status') {
                        return { ...e, value: res.status_id }
                    }
                    return e
                })
                dispatch(updateClaimRibbonData(updatedRibbonData)) 

                let updatedHistory = claimState?.claimHistory?.includes(res.status_id) ? [] : [...claimState?.claimHistory, res.status_id]

                dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, status_id: res.status_id }))
                if (updatedHistory.length) dispatch(updateClaimHistory(updatedHistory)) //update claim status history

                
            }
            else {
                if (res?.errors?.[0]?.message) {
                    toast.error(res.errors[0].message);
                    setLoading(false)
                }
            }       
        })
    }

    const handleContinueClick = () => {
        shareInsurance()
    }

    useEffect(() => {
        //show/hide continue btn
        if (claimState?.ribbonData?.length && claimState.claimDetails && Object.keys(claimState.claimDetails)?.length) {
            const docStatus = +claimState?.ribbonData?.find((e: any) => e.key === 'doc_status')?.value
            
            if (docStatus === 2 && claimState.claimDetails.status_id < STATUS_SUB_STATUS['status']['send-to-insurance-broker']) setShowContinue(true)
            else setShowContinue(false)
        }
    }, [claimState?.ribbonData, claimState?.claimDetails])
    

    const handleChange = async (event:any, key:string) => {
       // formData[key] = event.target.value;
        //setFormData({ ...formData });
        
        const value = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [key]: value
        }));

        if(key === "description"){
            let updatedTemplateType = formData?.template_type === 1 ? "email" : "whatsApp"
            setUpdatedDescription((prev:any)=> ({...prev, [updatedTemplateType]: event.target.value}))
        }

    }

    // fetch template description data
    useEffect(() => {
       const fetchTemplateData = async () => {
        // if (partnerTemplate?.partnerConfigTemplate?.cohort_list?.length) {
        //         const templateData = partnerTemplate.partnerConfigTemplate.cohort_list.filter((val: any) => val.template_type === EMAIL && val.template_for === SEND_TO_INS_PARTNER );
        //         setFormData((prev:any) => ({ ...prev, description: templateData[0]?.description || '',doc_id: templateData[0]?.document_id || [] }));
        //     } else {
                setLoading(true)        
                let params: any = {
                    partner_id: claimData?.partner_hash_id,
                    template_for: SEND_TO_INS_PARTNER,
                    insurance_type: claimData?.insurance_type,
                    key: "sendToInsurancePartner",
                    claim_id :id
                };

                dispatch(templateFieldInfo(params)).then((res: any) => {
                   
                    const cohortList = res?.data?.cohort_list || [];
                    const emailTemplate = cohortList.find((val: any) => val.template_type === EMAIL);
                    const whatsappTemplate = cohortList.find((val: any) => val.template_type === WHATSAPP);
                    // set default template
                    if (emailTemplate) {
                        // Set email template if present, default to email when both are available
                        setFormData((prev: any) => ({
                            ...prev,
                            description: emailTemplate?.description || '',
                            doc_id: emailTemplate.document_id || [],
                            template_type: EMAIL,
                            template_id: emailTemplate?.template_id

                        }));
                        setInitialDescription((prev)=> ({...prev, email: emailTemplate?.description}));

                    } 
                    if (whatsappTemplate) {
                        // Set WhatsApp template if only WhatsApp is available
                        // setFormData((prev: any) => ({
                        //     ...prev,
                        //     description: whatsappTemplate.description || '',
                        //     doc_id: whatsappTemplate.document_id || [],
                        //     template_type: WHATSAPP,
                        //     template_id: emailTemplate?.template_id

                        // }));
                        setInitialDescription((prev)=> ({...prev, email: emailTemplate?.description}));

                    }
                    
                    //const templateData = res?.data?.cohort_list?.filter((val: any) => val.template_type === EMAIL);
                    //setFormData((prev:any) => ({ ...prev, description: templateData?.[0]?.description || '',doc_id: templateData?.[0]?.document_id || []}));                    
                    setLoading(false)
                });
            //}
        };

        if (claimData?.partner_hash_id) {
            fetchTemplateData();
        }
      
    }, [props.type,claimData?.partner_hash_id]);


    const handleTemplateTypeChange = (type: number) => {
        const selectedTemplate = partnerTemplate?.partnerConfigTemplate?.cohort_list?.find((template: any) => template.template_type === type); 
        // if (selectedTemplate) {

            let updatedTemplateType = type === 1 ? "email" : "whatsApp"

            setFormData((prevFormData: any) => ({
                ...prevFormData,
                template_type: type,
                description: updatedDescription[updatedTemplateType] || selectedTemplate?.description || "",
                doc_id: selectedTemplate?.document_id || [],
                saved_template_type: selectedTemplate?.saved_template_type            
            }));
        // }
    };


    const checkValidateData = () => {
        if (!formData?.doc_id || formData?.doc_id.length === 0) {
            toast.error('Please select at least one document category');
            return false;
        }
        // if (!formData?.template_type || formData?.template_type.length === 0) {
        //     toast.error('Please select at least one of the source Email or Whatsapp');
        //     return false;
        // }
        if(formData?.description.trim() === ""){
            toast.error('Please enter any Message');
            return false;
        }
        return true;
    }

     // if claimDetails is not present in store then call api for fetch calim details
     useEffect(()=>{
        if(claimState?.claimDetails?.partner_hash_id){
            setClaimData(claimState?.claimDetails);
        }else{
            fetchClaimProcessingData({type: "applicationDetails",variables: { claim_id: id, api_type: "applicationDetails" } }).then((res: any) => {
                if (res?.data) {
                    dispatch(updateClaimDetailsInStore({...claimState?.claimDetails, ...res.data}));
                    setClaimData(res?.data)
                    // setDocuments(res?.data?.documents);
                }

            })
        }
    },[])


    const resetData = () => {
        setFormData({
            ...formData,
            description: (formData?.template_type === EMAIL) ? initialDescription?.email : initialDescription?.whatsApp,
            doc_id: [],
            template_type: formData?.template_type,
        });

        let updatedTemplateType = formData?.template_type === 1 ? "email" : "whatsApp"
        setUpdatedDescription((prev:any)=> ({...prev, [updatedTemplateType]: ""}))
    };
    
    console.log(showContinue , HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: claimState?.claimDetails?.assign_user_id }))
    return (
        <div className="form-tab-right-panel">
             <FullPageLoader show={loading} />
            <div className="lead-detail-from-outer">
                <div className="lead-form-heading">
                    <h2>Send to Insurance Partner</h2>
                </div>
                <div className="lead-form-filed p-lg-t">

                    <div className='doc-share-link'>
                        <ul>
                            
                            <li className={formData?.template_type == EMAIL ? 'active' : ''}
                               
                               onClick={() => handleTemplateTypeChange(EMAIL)}
                           >
                               <input
                                   type="checkbox"
                                   id="gmail"
                                   name="gmail"
                                   checked= {formData?.template_type == EMAIL ? true : false }
                                   onChange={() => handleTemplateTypeChange(EMAIL)}
                               />
                               <span>
                                   <img src={Mail} />
                                   Gmail/Email
                               </span>

                           </li>

                           <li className={formData?.template_type === WHATSAPP ? 'active' : ''}
                                onClick={() => handleTemplateTypeChange(WHATSAPP)}
                                >
                                    <input type="checkbox" id="whatsApp" name="whatsApp"
                                        checked= {formData?.template_type === WHATSAPP ? true : false }
                                        onChange={() => handleTemplateTypeChange(WHATSAPP)}
                                    />

                                    <span>
                                        <img src={WhatApp} />
                                        WhatsApp
                                    </span>
                                </li>
                            {/* <li>
                                <img src={Viber} alt="Viber" />
                                Viber
                            </li> */}
                        </ul>
                    </div>
                    <div className='ask-customer-msg-box material'>
                        <label>Message</label>
                        <textarea
                            className='form-input'
                            placeholder=' '
                            id="send_insurance_template"
                            value={formData?.description || ''}
                            onChange={(e) => handleChange(e, "description")}
                        ></textarea>
                        <span className='reset-txt' onClick = {resetData} >Reset</span> 
                    </div>
                    <div className='send-insurnace-doc-list'>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <h3>Attach documents list to upload</h3>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    {documents && documents?.length>0 && documents.map((doc: any) => (

                                        doc?.images?.length > 0 && <div className="custom-control custom-checkbox col-md-4 p-sm-b" key={doc.value}>
                                            <input
                                                id={doc.slug}
                                                type= {"checkbox"}//{doc.is_required ? "radio" : "checkbox"}
                                                className="custom-control-input"
                                                name={doc.slug}
                                                onChange={(e) => handleCheckboxChange(e, doc.value)}
                                                checked={formData?.doc_id?.includes(doc.value)}
                                            />
                                            <label className="custom-control-label" htmlFor={doc.slug}>
                                                {doc.label}
                                            </label>
                                        </div>
                                    ))}
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>

                        <div className="btn-continue">
                            {
                                showContinue && HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: claimState?.claimDetails?.assign_user_id })
                                    ?
                                    <button type="button" className="btn-primary" onClick={handleContinueClick}>Continue</button>
                                    :
                                    <button type="button" className="btn-primary" disabled>Continue</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Modal = ({ handleClose, show, children }: any) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default SendInsurance;
