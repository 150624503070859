import { useState, useEffect} from 'react'
import { WHATSAPP, EMAIL } from '@src/config/constant';
import whatsApp from "@src/webroot/images/whatsapp.svg";
import Mail from '@src/webroot/images/mail.svg'
import { useDispatch, useSelector } from 'react-redux';
import { templateFieldInfo, saveCustomerTemplateData, updateTemplateValue } from '@store/slices/template';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { WHATSAPP_WEB_URL, SEND_TO_CUST_DOCS } from '@src/config/constant'
import { fetchClaimProcessingData } from '@store/slices/claim';

function AskToCustomerPopup({ formData, askCustomer,documents, setFormData, setLoading, setAskCustomer, keys, template_for, claimData, pendingDocuments, setPendingDocuments}: any) {

    const { id } = useParams();
    const templateinfo = useSelector((state: any) => state.template)
    const partnerTemplate = useSelector((state: any) => state.template)
    const dispatch: any = useDispatch()
    const [initialDescription, setInitialDescription] = useState({email: "", whatsApp: ""});
    const [updatedDescription, setUpdatedDescription] = useState<any>({email: "", whatsApp: ""});

    // Function to handle template_type email or whatsapp
    const handleTemplateTypeChange = (type: number) => {
        const selectedTemplate = templateinfo?.partnerConfigTemplate?.cohort_list?.find((template: any) => template?.template_type === type && template?.template_for === template_for );
        if (selectedTemplate) {
            let updatedTemplateType = type === 1 ? "email" : "whatsApp"

            setFormData((prevFormData: any) => ({
                ...prevFormData,
                template_type: type,
                description: updatedDescription[updatedTemplateType] || selectedTemplate?.description || ""
            }));
        }else{
            if(formData?.template_type!==type){
                toast.error(`${type===1?"Email/Gmail":"WhatsApp"} template not found`)
            }
        }
    };


    const handleCheckboxChange = (event: any, docId: any) => {
        const checked = event.target.checked;
        setFormData((prevData: any) => {
            // If checked, add docId to doc_id array, otherwise remove it
            const currentDocIds = Array.isArray(prevData.doc_id) ? prevData.doc_id : [];

            const updatedDocIds = checked
                ? [...currentDocIds, docId]  // Use currentDocIds here
                : currentDocIds.filter((id: any) => id !== docId);

            return { ...prevData, doc_id: updatedDocIds };
        });
    };

    const handleChange = async (event: any, key: string) => {
        formData[key] = event.target.value;
        setFormData({ ...formData });

        if(key === "description"){
            let updatedTemplateType = formData?.template_type === 1 ? "email" : "whatsApp"
            setUpdatedDescription((prev:any)=> ({...prev, [updatedTemplateType]: event.target.value}))
        }
            

    }

    const shareUrlCustomer = () => {
        if (!checkValidateData()) {
            return;
        }

        setLoading(true)
        let template_input: any = {};
        template_input.description = formData?.description || ''
        template_input.claim_id = id || ''
        template_input.template_type = formData?.template_type || []
        template_input.template_for = template_for || 0
        template_input.template_id = formData?.template_id || 0
        template_input.doc_id = formData?.doc_id || []
        template_input.key = keys
        template_input.partner_id = claimData?.partner_hash_id || ''
        saveCustomerTemplateData({ template_input }).then(async (res: any) => {
            if (res?.status === 200) {
                toast.success(res.message)
                hideAskCustomerPopup()
                setLoading(false)
                // setTrigger(trigger + 1);


                const templateData = partnerTemplate?.partnerConfigTemplate?.cohort_list?.map((val: any) => {
                    let value = { ...val }
                    if (val.template_type === EMAIL) {
                        value.document_id = formData?.doc_id
                        return value;
                    }
                    return value
                });
                dispatch(updateTemplateValue(templateData))
                // share detail on whatsapp   
                if (formData?.template_type === WHATSAPP) {
                    let waMsg = "";
                    let openWhatsapp = true;
                    let formatMsg = formData?.description.replaceAll('<br/>', "\n");

                    waMsg = WHATSAPP_WEB_URL + encodeURIComponent(formatMsg);
                    if (openWhatsapp) window.open(waMsg, '_blank', 'noreferrer');
                    setLoading(false)
                    // resetData();
                }

                //updating requested document list
                if (template_input?.doc_id?.length) {
                    let updatedPendingDocs = [...pendingDocuments]
                    for (let doc of template_input.doc_id) {
                        if (!pendingDocuments.find((e: any) => e.doc_id === doc)) {
                            updatedPendingDocs.push({
                                doc_id: doc,
                                name: documents?.find((e: any) => e.value === doc)?.label
                            })
                        }
                    }

                    if (updatedPendingDocs?.length) setPendingDocuments(updatedPendingDocs)
                }
            } 
            else {
                if (res?.errors?.[0]?.message) {
                    toast.error(res.errors[0].message);
                    setLoading(false)
                }
            }  
           
        })

    }

    const hideAskCustomerPopup = () => {
        setAskCustomer(false);
        document.body.classList.remove("overflow-hidden");
    };


    const checkValidateData = () => {
        if (!formData?.doc_id || formData?.doc_id.length === 0) {
            toast.error('Please select at least one document category');
            return false;
        }
        // if (!formData?.template_type || formData?.template_type.length === 0) {
        //     toast.error('Please select at least one of the source Email or Whatsapp');
        //     return false;
        // }
        if(formData?.description.trim() === ""){
            toast.error('Please enter any Message');
            return false;
        }
        return true;
    }

    
     // fetch template description data
     useEffect(() => {
        const fetchTemplateData = async () => {
            // if (partnerTemplate?.partnerConfigTemplate?.cohort_list?.length) {
            //     console.log('partnerTemplate.partnerConfigTemplate.cohort_list==',partnerTemplate.partnerConfigTemplate.cohort_list);
            //     const templateData = partnerTemplate.partnerConfigTemplate.cohort_list.filter((val: any) => val.template_for === template_for && val.template_type === EMAIL);
            //     console.log('templateData==',templateData);
            //     setFormData((prev:any) => ({ ...prev, description: templateData[0]?.description || '',doc_id: templateData[0]?.document_id || [] }));
            // } else {
                setLoading(true)
                let params: any = {
                    partner_id: claimData?.partner_hash_id,
                    template_for: template_for,
                    insurance_type: claimData?.insurance_type,
                    key: keys,
                    claim_id :id,
                    status: 1,
                    lead_id: claimData?.insurance_id || 0,
                };
                dispatch(templateFieldInfo(params)).then((res: any) => {
                    
                    const templates = res?.data?.cohort_list || [];
                    const emailTemplate = templates.find((template:any) => template.template_type === EMAIL);
                    const whatsappTemplate = templates.find((template:any) => template.template_type === WHATSAPP);
                    // set by deafult tempalte
                    if (emailTemplate) {
                        // Default to EMAIL if both templates are present or only EMAIL is present
                        setFormData((prev:any) => ({
                            ...prev,
                            template_type: EMAIL,
                            description: emailTemplate.description,
                            doc_id: emailTemplate.document_id || [],
                            template_id: emailTemplate.template_id
                        }));
                        setInitialDescription((prev)=> ({...prev, email: emailTemplate.description}));
                    }
                    if (whatsappTemplate) {
                        // Set to WHATSAPP if only WhatsApp template is present
                        // setFormData((prev:any) => ({
                        //     ...prev,
                        //     template_type: WHATSAPP,
                        //     description: whatsappTemplate.description,
                        //     doc_id: whatsappTemplate.document_id || [],
                        //     template_id: emailTemplate?.template_id
                        // }));
                        setInitialDescription((prev)=> ({...prev, whatsApp: whatsappTemplate.description}));

                    }
                    
                    
                  //  const templateData = res?.data?.cohort_list?.filter((val: any) => val.template_type === EMAIL);
                   // const description = templateData?.[0]?.description || '';
                   // setFormData((prev:any) => ({ ...prev, description: templateData?.[0]?.description || '',doc_id: templateData?.[0]?.document_id || []}));                    
                    setLoading(false)
                   // setInitialDescription(description);
                });
           // }
        };

        if (claimData?.partner_hash_id) {
            fetchTemplateData();
        }
      
    }, [template_for,claimData?.partner_hash_id]);

    
    const resetData = () => {
        setFormData({
            ...formData,
            description: (formData?.template_type === EMAIL) ? initialDescription?.email : initialDescription?.whatsApp,
            doc_id: [],
            template_type: formData?.template_type,
        });

        let updatedTemplateType = formData?.template_type === 1 ? "email" : "whatsApp"
        setUpdatedDescription((prev:any)=> ({...prev, [updatedTemplateType]: ""}))

    };



    return (
        <>
            <div className="model-outer ask-customer-popup">
                <Modal show={askCustomer} handleClose={hideAskCustomerPopup} >
                    <div className="modal-header">
                        <h2>Ask Customer to Upload Docs</h2>
                    </div>
                    <div className="modal-body" >

                        <div className='doc-share-link'>
                            <ul>
                                {/* <li className={Array.isArray(formData?.template_type) && formData?.template_type?.includes(EMAIL) ? 'active' : ''} */}
                                <li className={formData?.template_type == EMAIL ? 'active' : ''}

                                    onClick={() => handleTemplateTypeChange(EMAIL)}
                                >
                                    <input
                                        type="checkbox"
                                        id="gmail"
                                        name="gmail"
                                        checked={formData?.template_type == EMAIL ? true : false} //{Array.isArray(formData?.template_type) && formData?.template_type?.includes(EMAIL)}
                                        onChange={() => handleTemplateTypeChange(EMAIL)}
                                    />
                                    <span>
                                        <img src={Mail} />
                                        Gmail/Email
                                    </span>

                                </li>

                                <li className={formData?.template_type === WHATSAPP ? 'active' : ''}
                                    onClick={() => handleTemplateTypeChange(WHATSAPP)}
                                >
                                    <input type="checkbox" id="whatsApp" name="whatsApp"
                                        checked={formData?.template_type === WHATSAPP ? true : false}
                                        onChange={() => handleTemplateTypeChange(WHATSAPP)}
                                    />

                                    <span>
                                        <img src={whatsApp} />
                                        WhatsApp
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className='ask-customer-msg-box material'>
                            <label>Message</label>
                            {/* <textarea className='form-input' placeholder=' '></textarea> */}
                            <textarea
                                id="cust_template"
                                className='form-input'
                                placeholder=' '
                                value={formData?.description || ''}
                                onChange={(e) => handleChange(e, "description")}
                            ></textarea>
                            <span className='reset-txt' onClick = {resetData} >Reset</span>
                        </div>
                        <div className="attech-doc-list">
                            <h3>Attach documents list to upload</h3>

                            {documents?.map((doc: any, index: any) => (
                                <div key={index} className="custom-control custom-checkbox col-md-6">
                                    <input
                                        id={doc.slug}
                                        type='checkbox'
                                        className="custom-control-input"
                                        name={doc.slug}
                                        onChange={(e) => handleCheckboxChange(e, doc.value)}
                                        checked={formData?.doc_id?.includes(doc.value)}
                                    />
                                    <label className="custom-control-label" htmlFor={doc.slug}>
                                        {doc.label}
                                        {doc?.images?.length ? <i className='ic-peson-listed'></i> : null}
                                    </label>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button className='btn-primary' onClick={shareUrlCustomer}>
                            Share URL with Customer
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default AskToCustomerPopup


const Modal = ({ handleClose, show, children }: any) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};