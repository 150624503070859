import React, { useState,useEffect } from "react";
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import FullPageLoader from "../../common/FullPageLoader";
import { GET_PARTNER_SHOP_LIST,UPDATE_SHOP_STATUS} from '../../../services/repairShop.gql';
import {executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import Pagination from '../../common/Pagination';
import { Link,useSearchParams } from 'react-router-dom';
import ToggleSwitch from '../../common/ToggleSwitch';
import AddRepairShopPartner from './AddRepairShopPartner';
import { ROLE_FEATURES } from "@src/config/constant";
import * as HELPER from '@src/config/helper';


function PartnerRepairShopList() {
    const client = useApolloClient();
    const [shopList, setShopList] = useState<[]>([]);
    const [partnerDetail, setPartnerDetail] = useState<any>({});
    const [paginationData, setPaginationData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [apiParams, setApiParams] = useState<any>({ page_number: 1 });
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAdd, setShowAdd] = useState(false);
    

    const runPaginationFunc = () => { };
    const handleRequestData = (params: any) => {
        let apiParams = {
            page_number: params.page_no,
            partner_id: searchParams.get('partner_id')
        }
        getShopListDetails(apiParams);
    }

    const handleToggleSwitch = (element: any) => {
        setLoading(true);
        let updatestatus=(element.status===1)?0:1;
        let updateData = {repairInput:{shop_id:element.id,partner_id:partnerDetail.id,status:updatestatus}};

         executeGraphQLQuery(UPDATE_SHOP_STATUS(),client,updateData).then((response:any) => { 
            setLoading(false);
            let { status,data,message} = response?.data?.repairShopUpdate;
            if(status && status===200){
                let shopData = JSON.parse(JSON.stringify(shopList));
               
                for(let data of shopData){
                    if(data.id === +element.id){
                        data.status=updatestatus;
                    }
                }
                setShopList(shopData);
                toast.success(message);
            }else{
                toast.error(message);
            }
        }).catch((error:any) => {
            toast.error(error);
            setLoading(false);
           
        })
        
    };

    const getShopListDetails = async (params: any) => {
        setLoading(true);
        let updatedFilters = {filter_list:{ ...apiParams, ...params }}
        executeGraphQLQuery(GET_PARTNER_SHOP_LIST(),client,updatedFilters).then((response:any) => { 
            setLoading(false);
            let { status, data,message,pagination } = response?.data?.getPartnerRepairList;
            if(status && status===200){
                setPaginationData(pagination);
                setShopList(data.shop_list);
                setPartnerDetail(data.partner_data);
            }else{
                toast.error(message);
                setPaginationData({});
                setShopList([]);
                setPartnerDetail({});
            }
        }).catch((error:any) => {
            toast.error(error);
            setLoading(false);
            setPaginationData({});
            setPartnerDetail({});
            setShopList([]);
        })
    };

    const addNewShop = (action: boolean) => {
        setShowAdd(action);
        document.body.classList.remove("overflow-hidden");
        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    }

    const getApiData = async () => {
        await getShopListDetails({partner_id: searchParams.get('partner_id')});
    }
  
    useEffect(() => {
        getApiData();
        
    }, []);

    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className="top-heading-outer">
                {/* <div className="back-opt">
                    <Link to="/repair-shop-list" title="Back">
                        <i className="ic-arrow_back"></i>
                        Back
                    </Link>
                </div> */}
                <div className="top-heading repair-shop-heading">
                    <h1>{partnerDetail?.name}</h1>
                    {
                        HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['region_management'], accessType: 'edit'}) &&

                        <button className="btn-line" onClick={() => addNewShop(true)}>
                            <i className="ic-add"></i>
                            <span>Add New Repair Shop</span>
                        </button>
                    }
                </div>
                <div className="loan-filter-bg managment-tabs tab-chips">
                    <div className="lead-list-data-table data-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Repair Shop Name</th>
                                    <th>Repair Shop Region</th>
                                    {
                                        HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['region_management'], accessType: 'edit'}) &&
                                        <th>Status</th>
                                    }
                                    {/* <th>Edit</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {(shopList && shopList.length > 0) ? 
                              shopList.map((item:any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>{item?.name}</td>
                                        <td title={item?.region}>{item?.region}</td>
                                        {
                                            HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['region_management'], accessType: 'edit'}) &&

                                            <td>
                                                <div className="on-off-switch">
                                                    <ToggleSwitch checked={item.status} onChange={() => handleToggleSwitch(item)} />
                                                </div>
                                            </td>
                                        }
                                        {/* <td></td> */}
                                        
                                    </tr>
                                )
                              }):
                              <tr><td colSpan={4} style={{ textAlign: 'center' }}>No data found</td></tr>
                            }
                            </tbody>
                        </table>

                        {(paginationData && paginationData['total'] > 10) && <Pagination paginateData={{
                            page: 1,
                            runPaginationFunc,
                            loading: false,
                            tableData: paginationData['limit'],
                            totalLengthWithoutPagination: paginationData['total'],
                            paginationData,
                            filteredData: {}
                            }}
                            sendRequestDataToParent={handleRequestData} />}
                    </div>

                    {
                        showAdd &&
                        <AddRepairShopPartner
                            showAdd={showAdd}
                            showPartner={false}
                            partnerId={partnerDetail?.id}
                            heading={`Add New Repair Shop`}
                            Submitbutton={`Add Shop`}
                            reloadShopData={getApiData}
                            addNewPartner={(e: boolean) => {addNewShop(e);}}
                        />
                    }
                </div>
            </div>
        </React.Fragment>

    );

}


export default PartnerRepairShopList