import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../../common/Pagination';
import { Nav } from 'react-bootstrap';
import FullPageLoader from '../../common/FullPageLoader';
import * as HELPER from "../../config/helper";
import { ROLE_FEATURES } from "../../config/constant";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { GET_FINANCIER_TEMPLATE_CONFIG_DATA } from "../../services/templatedata.gql";
import { useApolloClient } from '@apollo/client';

const SendToFinancierTemplateListing = () => {
    let [loading, setLoading] = useState(false);
    let [templateFinancierList, setTemplateFinancierList] = useState([]);

    let [paginationData, setPaginationData] = useState({})
    let page = 1
    const client = useApolloClient();


    // Function to fetch template financier list
    const getTemplateFinancierList = async (filters) => {
        setLoading(true);

        // let updatedFilters = {}
        let updatedFilters = {
                page_number: filters.page_number || 1
        };

        const result = await executeGraphQLQuery(GET_FINANCIER_TEMPLATE_CONFIG_DATA(), client, updatedFilters)
        if (result.data.getFinancierTemplateConfig.status === 200) {
            setTemplateFinancierList(result.data.getFinancierTemplateConfig.data || []);
            setPaginationData(result.data.getFinancierTemplateConfig.pagination ?? {})
            setLoading(false);
        }
        else {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        setLoading(true);
        let filters = {
            page_number: 1,
            pagination: true
        }
        getTemplateFinancierList(filters);

        setPaginationData({})
        // eslint-disable-next-line
    }, []);

    // Function to handle pagination data requests
    const handleRequestData = (params) => {
        setLoading(true);
        let apiParams = {
            page_number: params.page_no
        }
        getTemplateFinancierList(apiParams);       
    }

    return (
        <React.Fragment>
             <FullPageLoader show={loading} />
            <div className="top-heading-outer">
                <div className="top-heading p-lg-t p-lg-b">
                    <h1>Template Configuration</h1>
                </div>
                <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                    <Nav.Item>
                        <Nav.Link eventKey="first" active> Template </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div className="container-fluid">
                <div className="data-table financier-bx-table-outer">
                    <table>
                        <thead>
                            <tr>
                                <th style={{width:"10%"}}>Financier</th>
                                <th style={{width:"10%"}}>Cohort Analysis</th>
                                <th style={{width:"20%"}}>Send to Customer for Docs Template</th>
                                <th style={{width:"20%"}}>Send to Insurance Partner Template</th>
                                <th style={{width:"25%"}}> Send to Customer for Other Categories Template </th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templateFinancierList.map((financierData) => (
                                <tr key={financierData.partner_id}>
                                    <td>
                                        <Link
                                            to={`/email-template-config-detail?partner_id=${financierData.hashId}`}
                                        >
                                            {financierData.partner_name}
                                        </Link>
                                    </td>

                                    <td>
                                        <span>Active : {financierData.all_status ? financierData.all_status.split(",").filter((status) => +status === 1).length : 0} </span><br />
                                        <span>InActive : {financierData.all_status ? financierData.all_status.split(",").filter((status) => +status === 0).length : 0} </span>
                                    </td>
                                   
                                        <td>
                                            <span>Email : {financierData?.send_to_insurance_broker_email}
                                                {
                                                    (financierData?.send_to_insurance_broker_email_ucf || financierData?.send_to_insurance_broker_email_standalone || financierData?.send_to_insurance_broker_email_renewal)
                                                        ?
                                                        ` (${financierData?.send_to_insurance_broker_email_ucf ? "Comprehensive" : ""}${financierData?.send_to_insurance_broker_email_ucf && financierData?.send_to_insurance_broker_email_standalone ? ", " : ""}${financierData?.send_to_insurance_broker_email_standalone ? "CTPL" : ""}${(financierData?.send_to_insurance_broker_email_ucf || financierData?.send_to_insurance_broker_email_standalone) && financierData?.send_to_insurance_broker_email_renewal ? ", " : ""}${financierData?.send_to_insurance_broker_email_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                            </span><br />
                                            <span>Whatsapp : {financierData?.send_to_insurance_broker_wa}
                                                {
                                                    (financierData?.send_to_insurance_broker_wa_ucf || financierData?.send_to_insurance_broker_wa_standalone || financierData?.send_to_insurance_broker_wa_renewal)
                                                        ?
                                                        ` (${financierData?.send_to_insurance_broker_wa_ucf ? "Comprehensive" : ""}${financierData?.send_to_insurance_broker_wa_ucf && financierData?.send_to_insurance_broker_wa_standalone ? ", " : ""}${financierData?.send_to_insurance_broker_wa_standalone ? "CTPL" : ""}${(financierData?.send_to_insurance_broker_wa_ucf || financierData?.send_to_insurance_broker_wa_standalone) && financierData?.send_to_insurance_broker_wa_renewal ? ", " : ""}${financierData?.send_to_insurance_broker_wa_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                            </span>
                                        </td>
                                    
                                        <td>
                                            <span>Email : {financierData?.insurance_policy_shared_email}
                                                {
                                                    (financierData?.insurance_policy_shared_email_ucf || financierData?.insurance_policy_shared_email_standalone  || financierData?.insurance_policy_shared_email_renewal)
                                                        ?
                                                        ` (${financierData?.insurance_policy_shared_email_ucf ? "Comprehensive" : ""}${financierData?.insurance_policy_shared_email_ucf && financierData?.insurance_policy_shared_email_standalone ? ", " : ""}${financierData?.insurance_policy_shared_email_standalone ? "CTPL" : ""}${(financierData?.insurance_policy_shared_email_ucf || financierData?.insurance_policy_shared_email_standalone) && financierData?.insurance_policy_shared_email_renewal ? ", " : ""}${financierData?.insurance_policy_shared_email_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                            </span><br />
                                            <span>Whatsapp : {financierData?.insurance_policy_shared_wa}
                                                {
                                                    (financierData?.insurance_policy_shared_wa_ucf || financierData?.insurance_policy_shared_wa_standalone || financierData?.insurance_policy_shared_wa_renewal)
                                                        ?
                                                        ` (${financierData?.insurance_policy_shared_wa_ucf ? "Comprehensive" : ""}${financierData?.insurance_policy_shared_wa_ucf && financierData?.insurance_policy_shared_wa_standalone ? ", " : ""}${financierData?.insurance_policy_shared_wa_standalone ? "CTPL" : ""}
                                                        ${(financierData?.insurance_policy_shared_wa_ucf || financierData?.insurance_policy_shared_wa_standalone) && financierData?.insurance_policy_shared_wa_renewal ? ", " : ""}${financierData?.insurance_policy_shared_wa_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                            </span>
                                        </td>
                                    
                                    <td>
                                        <span>
                                            Email :{financierData?.send_to_customer_for_claim_closing_docs_email}
                                            {
                                                    (financierData?.send_to_customer_for_claim_closing_docs_email_ucf || financierData?.send_to_customer_for_claim_closing_docs_email_standalone )
                                                        ?
                                                        ` (${financierData?.send_to_customer_for_claim_closing_docs_email_ucf ? "Comprehensive" : ""}${financierData?.send_to_customer_for_claim_closing_docs_email_ucf && financierData?.send_to_customer_for_claim_closing_docs_email_standalone ? ", " : ""}${financierData?.send_to_customer_for_claim_closing_docs_email_standalone ? "CTPL" : ""}${(financierData?.send_to_customer_for_claim_closing_docs_email_ucf || financierData?.insurance_policy_shared_email_standalone) && financierData?.send_to_customer_for_claim_closing_docs_email_renewal ? ", " : ""}${financierData?.send_to_customer_for_claim_closing_docs_email_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                        </span> <br />
                                        <span>
                                            Whatsapp :{financierData?.send_to_customer_for_claim_closing_docs_wa}
                                            {
                                                    (financierData?.send_to_customer_for_claim_closing_docs_wa_ucf || financierData?.send_to_customer_for_claim_closing_docs_wa_standalone )
                                                        ?
                                                        ` (${financierData?.send_to_customer_for_claim_closing_docs_wa_ucf ? "Comprehensive" : ""}${financierData?.send_to_customer_for_claim_closing_docs_wa_ucf && financierData?.send_to_customer_for_claim_closing_docs_wa_standalone ? ", " : ""}${financierData?.send_to_customer_for_claim_closing_docs_wa_standalone ? "CTPL" : ""}
                                                        ${(financierData?.send_to_customer_for_claim_closing_docs_wa_ucf || financierData?.send_to_customer_for_claim_closing_docs_wa_standalone) && financierData?.send_to_customer_for_claim_closing_docs_wa_renewal ? ", " : ""}${financierData?.send_to_customer_for_claim_closing_docs_wa_renewal ? "Renewal" : ""})`
                                                        : ''
                                                }
                                        </span>

                                    </td>

                                    <td>
                                        <span
                                            className={`status-${financierData?.all_status?.split(",")?.filter((status) => +status).length ? "active" : "inactive"
                                                }`}
                                        >
                                            {
                                            // financierData?.all_status?.split(",")?.filter((status: any) => +status).length ? "Active" : "InActive"
                                            financierData?.all_status?.split(",")?.includes("1") ? "Active" : "InActive"
                                            }
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="pagination-outer">
                        {(paginationData && paginationData['total'] > 10) && <Pagination
                            paginateData={{
                                page,
                                loading: false,
                                totalLengthWithoutPagination: paginationData.total,
                                paginationData,
                                filteredData: {}
                            }}
                            sendRequestDataToParent={handleRequestData}
                        />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SendToFinancierTemplateListing;