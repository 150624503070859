import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../apolloClient';
import { GET_ROLE_LIST, SAVE_ROLE, GET_FEATURE_LIST, UPDATE_ROLE_STATUS } from "../../services/role.gql";
import { toast } from 'react-toastify';
import { executeGraphQLQuery } from '@common/executeGraphQLQuery';


// export const getUserList = createAsyncThunk('roleUser/fetchUsers', async (variables: any) => {
//     const response = await client.query({ query: GET_USER_LIST(), variables });
//     return response.data.userList;
// });


// Create a slice
const roleUserSlice = createSlice({
    name: 'roleUser',
    initialState: {
        data: [],
        pagination: {},
        status: 'idle',
        error: null,
        roles: {},
        // users: {}
    },
    reducers: {
        // roleListing: (state, action) => {
        //     console.log(state)
        //     // state.roles = action.payload
        // },
        // roleListingError: (state, action) => {
        //     console.log(state)
        //     // state.roles = action.payload
        // }
    },
    extraReducers: (builder: any) => {
        // builder
        //     .addCase(getRoleList.pending, (state: any) => {
        //         console.log(state)
        //         state.status = 'loading';
        //     })
        //     .addCase(getRoleList.fulfilled, (state: any, action: any) => {
        //         console.log(state)
        //         state.status = 'succeeded';
        //         state.data = action.payload?.data;
        //         state.pagination = action.payload?.pagination;
        //     })
        //     .addCase(getRoleList.rejected, (state: any, action: any) => {
        //         state.status = 'failed';
        //         state.error = action.error.message;
        //     });
    },
});

export default roleUserSlice.reducer;

// const { roleListing, roleListingError } = roleUserSlice.actions

// export const getRoleListing = { 
export const getRoleListing = (variables: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        // client.query
        // const result = await executeGraphQLQuery(GET_ROLE_LIST, variables)

        executeGraphQLQuery(GET_ROLE_LIST, client, variables)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.getAllRoleList)) {
                        resolve(response.data?.getAllRoleList)
                        // dispatch(roleListing(response.data?.getAllRoleList));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                        // roleListingError(response?.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const saveRoleData = (variables: object) => {
    return new Promise((resolve, reject) => {
        executeGraphQLQuery(SAVE_ROLE, client, variables)

        // client.query({ query: SAVE_ROLE, variables })
            .then(
                (response: any) => {
                    response['data'] = response?.data?.saveRoleData;
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        // dispatch(roleListing(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const getFeaturesData = (variables: object) => {
    return new Promise((resolve, reject) => {
        executeGraphQLQuery(GET_FEATURE_LIST, client, variables)

        // client.query({ query: GET_FEATURE_LIST, variables })
            .then(
                (response: any) => {
                    response['data'] = response?.data?.getAllFeatureList;
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        // dispatch(roleListing(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const updateStatus = (variables: object) => {
    return new Promise((resolve, reject) => {
        executeGraphQLQuery(UPDATE_ROLE_STATUS, client, variables)
        // client.query({ query: UPDATE_ROLE_STATUS, variables })
            .then(
                (response: any) => {
                    response['data'] = response?.data?.update_status;
 
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        // dispatch(roleListing(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}
// }
