import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import { I18nextProvider } from "react-i18next";
// import i18next from "i18next";
import language_id from "./translations/id/language.json";
import language_en from "./translations/en/language.json";
import secureStorage from "./config/encrypt";
import { API_URL } from './config/constant';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
// import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";

import client from './store/apolloClient';



// i18next.init({
// 	interpolation: { escapeValue: false },
// 	lng: secureStorage.getItem("lang") === "id" ? "id" : "en",
// 	//lng: "en",
// 	resources: {
// 		en: {
// 			common: language_en,
// 		},
// 		id: {
// 			common: language_id,
// 		},
// 	},
// });






const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
// root.render(
// 	<React.Fragment>
// 		<I18nextProvider i18n={i18next}>
// 			<Provider store={store}>
// 			   <App />
// 			</Provider>
// 		</I18nextProvider>
// 	</React.Fragment>
// );

root.render(

    <Provider store={store}>
        <ApolloProvider client={client}>
            {/* <I18nextProvider i18n={i18next}> */}
            <App />
            {/* </I18nextProvider> */}
        </ApolloProvider>
    </Provider>

);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();