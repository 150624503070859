import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import { useEffect, useState } from "react";
import Video from 'yet-another-react-lightbox/plugins/video';


export default function FullSreenPreview(props: any) {
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [images, setImages] = useState<any>()
    let [activeIndex, setActiveIndex] = useState<number>(0)

    useEffect(() => {
        if (props) {
            let updatedImages = props.images && props.images.map((img: any) => {
                let splitName = img?.doc_path?.split('?')
                let splitPathName = splitName?.[0]?.split('.')
                let fileType = (splitPathName[splitPathName.length - 1])?.toLowerCase()
                let isVideo = ['mp4', 'mov'].includes(fileType)

                if (isVideo) {
                    return {
                        sources: [
                            {
                                src: img.doc_path,
                                type: fileType === 'mp4' ? "video/mp4" : 'video/quicktime',
                            },
                        ], type: 'video'
                    }
                } else {
                    return {
                        src: img.doc_path
                    }
                }
            })
            setImages(updatedImages)
            setIsOpen(props.open ?? false)
            setActiveIndex(props.index ?? 0)
        }
        //eslint-disable-next-line
    }, [props.images, props.open, props.index])

    return (
        <Lightbox
            open={isOpen}
            close={() => {
                setIsOpen(false)
                props.onClose()
            }}
            plugins={[Video, Captions, Zoom]}
            slides={images}
            index={activeIndex}
            carousel={{
                finite: true
            }}
            video={{
                controls: true,
                playsInline: true,
                autoPlay: false,
                loop: false,
                muted: false,
                disablePictureInPicture: false,
                disableRemotePlayback: false,
                crossOrigin: "",
                preload: "",
            }}
        />
    )
}