import React, { useState } from 'react'
import { Tab, Nav } from 'react-bootstrap';
import imageLogo from "../../webroot/images/login_bg.png";
import EmailUser from "./EmailUser";
import MobileUser from './MobileUser'
import logo from "../../webroot/images/logo.png"


function Login() {
    const [showCaptcha, setShowCaptcha] = useState(0);

    const [showMobileLinks, setShowMobileLinks] = useState(true);
    const [showEmailLinks, setShowEmailLinks] = useState(true);

    const activeTabHandler = () => {
        setShowCaptcha((prev) => prev + 1);
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="login-outer">
                    <div className="login-left-panel">
                        <div className="login-bg">
                            <div className="login-txt">
                                <span>Welcome.</span>
                                <h1> Please sign in to Claim Box</h1>
                            </div>
                            <img src={imageLogo} className="login-bg-img" alt='logo-bg' />
                        </div>
                    </div>
                    <div className="login-right-panel">
                        <div className="logo-img t-centre">
                            <img src={logo} className="" alt='logo' />
                        </div>
                        <div className="login-heading m-md-t">
                            <h2>Sign In</h2>
                        </div>
                        <div className="p-lg-t t-center">
                            <label>Enter your credentials to access your account</label>
                        </div>
                        <Tab.Container id="" defaultActiveKey="first">
                            <div className="login-tab">

                                {/* <Nav variant="pills" className="flex-column" onSelect={() => activeTabHandler()}>
                                    {(showEmailLinks && showMobileLinks) &&

                                        <Nav.Item>
                                            <Nav.Link eventKey="first">By Email</Nav.Link>
                                        </Nav.Item>
                                    }
                                    {(showEmailLinks && showMobileLinks) &&
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">By Mobile</Nav.Link>
                                        </Nav.Item>
                                    }
                                </Nav> */}


                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <EmailUser setShowEmailLinks = {setShowEmailLinks} setShowMobileLinks = {setShowMobileLinks} />

                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="second">
                                        <MobileUser setShowEmailLinks = {setShowEmailLinks} setShowMobileLinks = {setShowMobileLinks}  showCaptcha={showCaptcha} />
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login